import { Card } from '@npm/core/ui/components/molecules/Card';
import { useBreakpoint } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  .card-content {
    padding: ${({ theme }) => theme.spacing.lg}px;
    align-items: flex-start;

    ${({ theme }) => useBreakpoint(theme).mobile`
      padding: ${theme.spacing.md}px;
    `}
  }
`;
