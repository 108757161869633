import React, { useState } from 'react';

import { Button, type ButtonProps } from '@npm/core/ui/components/atoms/Button';
import {
  CbAccountSponsorshipType,
  type CodebookItem,
  type SecondmarketOrderItemShow,
} from '@npm/data-access';

import { RequestMoreInformationButton } from '../../../../components';

import { InvestorL2MarketOrderActions } from './InvestorL2MarketOrderActions';

type Props = {
  orderItem: SecondmarketOrderItemShow;
  sponsorshipType: CodebookItem;
  handleShowOrderDetails: (orderItem: SecondmarketOrderItemShow) => void;
  handleShowNegotiations: (orderItem: SecondmarketOrderItemShow) => void;
  handleStartNegotiation:
    | ((orderItem: SecondmarketOrderItemShow) => void)
    | undefined;
  buttonSize?: ButtonProps['size'];
};

export const InvestorMarketOrderActions = ({
  orderItem,
  sponsorshipType,
  ...props
}: Props) => {
  const [isGoingToDetail, setIsGoingToDetail] = useState(false);

  const handleDetailClick = async () => {
    try {
      setIsGoingToDetail(true);
      await props.handleShowOrderDetails(orderItem);
    } finally {
      setIsGoingToDetail(false);
    }
  };

  if (CbAccountSponsorshipType.isSponsoredAccountLevel2(sponsorshipType)) {
    return (
      <InvestorL2MarketOrderActions
        orderItem={orderItem}
        {...props}
        isGoingToOrderDetail={isGoingToDetail}
        handleShowOrderDetails={handleDetailClick}
      />
    );
  }

  if (orderItem.account) {
    return (
      <Button
        variant="outline"
        block
        onClick={handleDetailClick}
        loading={isGoingToDetail}
      >
        Show Details
      </Button>
    );
  }
  return <RequestMoreInformationButton orderItem={orderItem} />;
};
