import { matchPath, useLocation } from 'react-router';

import { useCurrentRoute } from './useCurrentRoute';

export const usePathMatch = <
  Params extends { [K in keyof Params]?: string },
>() => {
  const location = useLocation();
  const currentRoute = useCurrentRoute();

  return matchPath<Params>(location.pathname, {
    path: currentRoute?.route.path,
    exact: true,
    strict: false,
  });
};
