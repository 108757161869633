import React from 'react';

import { isNil } from 'lodash';

import {
  formatAsCompactNumber,
  formatQuantity,
  formatRange,
  type NumberFormatOptions,
} from '../../../../utils/formatters';
import { TextCurrency, type TextCurrencyProps } from '../TextCurrency';

import { Text } from './Text';
import { EMPTY_VALUE } from './Text.constants';
import { type PriceOrQuantityValue, type Props } from './Text.types';

type QuantityProps = Omit<Props, 'children' | 'valueLength'> & {
  value: PriceOrQuantityValue;
  compact?: boolean;
  colorVariant?: Props['colorVariant'];
  formatOptions?: NumberFormatOptions;
  unit?: string;
  unitMargin?: TextCurrencyProps['marginLeft'];
  showZeroAsEmpty?: boolean;
  zeroColorVariant?: Props['colorVariant'];
};

export const Quantity = ({
  value,
  formatOptions,
  size = 'sm',
  compact,
  colorVariant = 'primary',
  unit,
  unitMargin,
  showZeroAsEmpty = false,
  zeroColorVariant,
  ...props
}: QuantityProps) => {
  const formatValue = (value: QuantityProps['value']) => {
    if (Array.isArray(value))
      return formatRange(value, {
        ...formatOptions,
        ...(compact && {
          subFormatter: val => formatAsCompactNumber(val, formatOptions),
        }),
      });

    if (isNil(value)) return EMPTY_VALUE;

    return compact
      ? formatAsCompactNumber(value, formatOptions)
      : formatQuantity(value, formatOptions);
  };

  const formattedValue = formatValue(value);

  const isZero = value === 0;

  return (
    <Text
      as={'span'}
      size={size}
      colorVariant={
        isZero && zeroColorVariant ? zeroColorVariant : colorVariant
      }
      valueLength={formattedValue.length}
      {...props}
    >
      {showZeroAsEmpty && isZero ? EMPTY_VALUE : formattedValue}
      {unit && formattedValue !== EMPTY_VALUE && (
        <TextCurrency
          color="general"
          colorVariant="secondary"
          style={{ textTransform: 'capitalize' }}
          marginLeft={unitMargin}
        >
          {unit}
        </TextCurrency>
      )}
    </Text>
  );
};
