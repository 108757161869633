import styled from 'styled-components';

import { PriceContainer } from '../../../../../components/PriceBlock/PriceBlock.styles';

const borderRadiusVariant = 'md';

export const Card = styled.div`
  display: flex;
  flex-direction: column;

  border: 1px solid ${({ theme }) => theme.color.general.borders.primary};
  border-radius: ${({ theme }) => theme.borderRadius[borderRadiusVariant]}px;
`;

export const Header = styled.div<{ $isSuccess: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: ${({ theme }) => theme.spacing.sm}px;
  border-top-left-radius: ${({ theme }) =>
    theme.borderRadius[borderRadiusVariant]}px;
  border-top-right-radius: ${({ theme }) =>
    theme.borderRadius[borderRadiusVariant]}px;
  background-color: ${({ theme, $isSuccess }) =>
    $isSuccess ? theme.color.info.backgrounds.secondaryDefault : undefined};
`;

export const Content = styled.div`
  padding: 0 ${({ theme }) => theme.spacing.sm}px;
`;

export const Footer = styled.div<{
  $priceBlockColorVariant?: 'tertiary' | 'muted';
}>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm}px;

  background-color: ${({ theme }) => theme.color.general.layout.two};
  border-top: 1px solid ${({ theme }) => theme.color.general.borders.primary};
  border-bottom-left-radius: ${({ theme }) =>
    theme.borderRadius[borderRadiusVariant]}px;
  border-bottom-right-radius: ${({ theme }) =>
    theme.borderRadius[borderRadiusVariant]}px;

  &&& ${PriceContainer} {
    * {
      color: ${({ theme, $priceBlockColorVariant }) =>
        $priceBlockColorVariant
          ? theme.color.general.typography[$priceBlockColorVariant]
          : undefined};
    }
  }
`;

export const TryAgainButton = styled.button`
  color: ${({ theme }) => theme.color.error};
  padding: ${({ theme }) => theme.spacing.xs}px 0;
`;
