import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { Margin } from '@npm/core/ui/components/atoms/common';
import { Divider } from '@npm/core/ui/components/atoms/Divider';
import { ErrorSkeleton } from '@npm/core/ui/components/atoms/ErrorSkeleton';
import { Form } from '@npm/core/ui/components/atoms/Form';
import { FormItem } from '@npm/core/ui/components/atoms/FormItem';
import { InlineInputNumber } from '@npm/core/ui/components/atoms/Input';
import { DrawerSection } from '@npm/core/ui/components/molecules/DrawerSection';
import {
  CbVisibility,
  type Holding,
  type IssuerEntityAggregate,
  type SecondmarketOrderItemSimple,
} from '@npm/data-access';
import { type FormInstance, type FormProps } from 'antd';
import { useTheme } from 'styled-components';

import { HoldingDrawer, useHoldingDrawer } from '../../../../../../holdings';
import { HoldingCard } from '../../../../../drawers/components/HoldingCard';
import { HoldingMultiSelect } from '../../../../../drawers/components/HoldingMultiSelect';
import { OrderEntrySection } from '../../../../../drawers/OrderEntryDrawer/OrderPlacementDrawer/OrderPlacementForm';
import { useHoldingQuantities } from '../../../../../hooks/useHoldingsQuantities';
import { getCalculatedValues } from '../../EnterSellIndication.utils';
import { MarketIntelligenceV2 } from '../MarketIntelligenceV2';
import type { SellFormValues } from '../SellIndicationDrawer.types';

type Props = {
  form: FormInstance<SellFormValues>;
  values: SellFormValues | undefined;
  initialHolding: Holding | undefined;
  selectedHoldings: Holding[] | undefined;
  setSelectedHoldings: (holdings: Holding[]) => void;
  issuerEntity?: IssuerEntityAggregate;
  accountId?: number;
  isLoading?: boolean;
  order?: SecondmarketOrderItemSimple;
} & Pick<FormProps, 'id' | 'onFinish' | 'onValuesChange'>;

export const SellIndicationForm = ({
  form,
  values,
  initialHolding,
  selectedHoldings,
  setSelectedHoldings,
  issuerEntity,
  accountId,
  isLoading,
  order,
  ...formProps
}: Props) => {
  const theme = useTheme();

  const holdingGuids = Form.useWatch('holdingGuids', form);

  const [{ openAddHoldingDrawer }, holdingDrawerProps] = useHoldingDrawer({
    preselectedIssuerEntityId: issuerEntity?.id,
    preselectedAccountId: accountId,
  });

  const formValues = Form.useWatch([], form) ?? ({} as SellFormValues);
  const { estimatedGrossProceeds } = getCalculatedValues(
    formValues?.quantity,
    formValues?.pricePerShare
  );

  const initialValues = {
    quantity: order?.quantity,
    minimumQuantity: order?.minimum_quantity,
    pricePerShare: order?.price,
    holdingGuids: initialHolding ? [initialHolding.npm_guid] : [],
  };

  useEffect(() => {
    // Set previously submitted values when user goes back to the form
    if (values) {
      form.setFieldsValue(values);
    }
    // Set initial values
    else {
      form.setFieldsValue({
        quantity: order?.quantity,
        minimumQuantity: order?.minimum_quantity,
        pricePerShare: order?.price,
        holdingGuids: initialHolding ? [initialHolding.npm_guid] : [],
      });
    }
  }, [form, values, initialHolding, order, setSelectedHoldings]);

  useEffect(() => {
    if (!holdingGuids?.length && !!selectedHoldings?.length) {
      form.setFieldValue(
        'holdingGuids',
        selectedHoldings.map(h => h.npm_guid)
      );
    }
  }, [form, holdingGuids, selectedHoldings]);

  const quantities = useHoldingQuantities(selectedHoldings);

  return (
    <div>
      <Form<SellFormValues>
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={initialValues}
        {...formProps}
      >
        <>
          <Margin bottom="md">
            <DrawerSection
              {...(order
                ? {
                    iconName: 'info-circle',
                    title: 'Your Holding',
                    content: (
                      <HoldingCard holding={initialHolding} header={true} />
                    ),
                  }
                : {
                    iconName: 'clipboard-check',
                    title: 'What do you want to sell?',
                    content: (
                      <FormItem name={'holdingGuids'}>
                        <HoldingMultiSelect
                          issuerEntityId={issuerEntity?.id}
                          accountId={accountId}
                          secondmarket={true}
                          quantities={quantities}
                          dropdownStyle={{
                            zIndex: theme.zIndex.aboveDrawer,
                          }}
                          onHoldingsSelect={setSelectedHoldings}
                          onAddHoldingClick={openAddHoldingDrawer}
                        />
                      </FormItem>
                    ),
                  })}
            />
          </Margin>
          <Margin bottom="lg" top="md">
            <OrderEntrySection
              activeAction={'sell'}
              orderSizeType={'Shares'}
              company={issuerEntity}
              visibility={CbVisibility.items.internal}
              showMinimumQuantity={false}
              showSizeExtraRow={false}
              remainingHoldingQuantity={
                order
                  ? initialHolding?.remaining_quantity + (order?.quantity ?? 0)
                  : quantities?.remainingQuantity
              }
            />
          </Margin>
          <InlineInputNumber
            label="Your Est. Gross Proceeds"
            placeholder="0.00"
            value={estimatedGrossProceeds}
            currency={'USD'}
            disabled
            readOnly
          />
          <Divider marginTop={theme.spacing.lg} />
          <ErrorBoundary FallbackComponent={ErrorSkeleton}>
            <MarketIntelligenceV2
              issuerEntity={issuerEntity}
              isLoading={isLoading}
              pricePerShare={formValues?.pricePerShare}
            />
          </ErrorBoundary>
        </>
      </Form>

      <HoldingDrawer
        {...holdingDrawerProps}
        onSuccess={holding => {
          setSelectedHoldings([...selectedHoldings, holding]);
          form.setFieldsValue({
            holdingGuids: [...formValues.holdingGuids, holding.npm_guid],
          });
        }}
        hideIssuerEntitySelect
        disabledAccountSelect
        showAccountSectionTitle
        showCompanyLogoInTitle
      />
    </div>
  );
};
