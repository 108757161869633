import { useState } from 'react';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Margin } from '@npm/core/ui/components/atoms/common';
import {
  InputFormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { Input } from '@npm/core/ui/components/atoms/Input';
import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { Tabs } from '@npm/core/ui/components/atoms/Tabs';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { useConfirmOnLeave } from '@npm/core/ui/components/molecules/Link';
import { DiscardModal } from '@npm/core/ui/components/organisms/DiscardModal';
import { Drawer } from '@npm/core/ui/components/organisms/Drawer';
import { handleValidationError } from '@npm/core/ui/utils/form';
import { useForm } from 'antd/lib/form/Form';

import { useCurrentRole } from '../../auth/user/role/hooks/useCurrentRole';
import { CreateUserWithRolesForm } from '../../users/CreateNewUser';

import * as S from './NewAccountDrawer.styles';
import { NewOrganizationAccountForm } from './Form';
import type { NewAccountFormValues } from './NewAccountDrawer.types';
import { accountIdValidator } from './NewAccountDrawer.utils';
import {
  type AccountCreate,
  useCreateOrganizationAccount,
} from './NewOrganizationAccountDrawer.hooks';
import { useCreatePersonAccount } from './useCreatePersonAccount.hooks';
import { usePersonFormInitialValues } from './usePersonFormInitialValues.hooks';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const NewAccountDrawerWithTabs = ({ isOpen, onClose }: Props) => {
  const [personForm] = useForm();
  const [organizationForm] = useForm();

  const { withShowApiErrorMiddleware } = useAlerts();
  const [activeAction, setActiveAction] = useState('organization');
  const role = useCurrentRole();

  const { createPersonAccount, isCreatePersonAccLoading, isSendingEmail } =
    useCreatePersonAccount();

  const { execute: createOrgAcc, isLoading: isCreateOrgAccLoading } =
    useCreateOrganizationAccount(role.subject?.id);

  const { setHasUnsavedChanges, onCloseAttempt, discardModalProps } =
    useConfirmOnLeave();

  const handleCloseConfirm = () => {
    personForm.resetFields();
    organizationForm.resetFields();
    setHasUnsavedChanges(false);
    onClose();
  };

  const handleOrgAccSubmit = async (values: AccountCreate) => {
    try {
      const res = await withShowApiErrorMiddleware(createOrgAcc)(values);
      handleCloseConfirm();

      Notification.success({
        message: `You have successfully created the account ${res?.external_id}`,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handlePersonAccSubmit = async () => {
    let fields: NewAccountFormValues = null;

    try {
      fields = await personForm.validateFields();
    } catch (error) {
      handleValidationError(personForm, error);
      return;
    }
    await createPersonAccount(fields);
    handleCloseConfirm();
  };

  usePersonFormInitialValues(organizationForm);

  const isCreatingAccount =
    isCreateOrgAccLoading || isCreatePersonAccLoading || isSendingEmail;

  return (
    <>
      <Drawer
        open={isOpen}
        title={`Create New Account for ${role.subject?.name}`}
        submitButtonProps={{
          onClick:
            activeAction === 'organization'
              ? organizationForm.submit
              : personForm.submit,
          children: 'Submit',
          loading: isCreatingAccount,
        }}
        onClose={() => onCloseAttempt(handleCloseConfirm)}
        destroyOnClose
      >
        <Tabs
          variant="secondary"
          activeKey={activeAction}
          onChange={e => setActiveAction(e)}
          hideMoreButton
        >
          <Tabs.TabPane key={'organization'} tab={'Entity'}>
            <NewOrganizationAccountForm
              form={organizationForm}
              onFinish={handleOrgAccSubmit}
              onFinishFailed={e => handleValidationError(organizationForm, e)}
              onValuesChange={() => setHasUnsavedChanges(true)}
            />
          </Tabs.TabPane>
          <Tabs.TabPane key={'person'} tab={'Individual'}>
            <Margin bottom="lg">
              <Alert message="Enter the Individual’s legal name and assign a unique ID. Once you click “Continue”, a new Individual account will be created in your brokerage firm and you can proceed to permission additional users to the account." />
            </Margin>
            <S.Form
              form={personForm}
              onFinish={handlePersonAccSubmit}
              onValuesChange={() => setHasUnsavedChanges(true)}
            >
              <CreateUserWithRolesForm
                mode="new"
                brokerageFirmId={role.subject?.id}
                form={organizationForm}
                addon={
                  <InputFormItem
                    name="id"
                    label="Account ID"
                    extra="Must be alphanumeric value"
                    rules={[
                      VALIDATION_RULES.required('Account ID'),
                      accountIdValidator,
                    ]}
                  >
                    <Input
                      prefix={`${role.subject?.name} -`}
                      placeholder="000000"
                    />
                  </InputFormItem>
                }
              />
            </S.Form>
          </Tabs.TabPane>
        </Tabs>
      </Drawer>
      <DiscardModal {...discardModalProps} />
    </>
  );
};
