import React from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Icon } from '@npm/core/ui/components/atoms/Icon';

import { OptInMode, OptInStep } from '../../OptIn.enums';

import * as S from './OptInFooter.styles';

interface Props {
  id: string;
  optInMode: OptInMode;
  setOptInStep: (step: OptInStep) => void;
}

export const OptInFooter = ({ id, optInMode, setOptInStep }: Props) => {
  return (
    <S.ButtonsContainer>
      <Button
        variant="text"
        onClick={() => setOptInStep(OptInStep.ACCOUNT_TYPE_SELECTION)}
        blockOnMobile
        icon={<Icon name="chevron-left" size="xs" />}
      >
        Back
      </Button>
      <Button
        form={id}
        htmlType="submit"
        blockOnMobile
        data-dd-action-name={'Submit OptIn'}
      >
        {optInMode === OptInMode.COPY_ACCOUNT
          ? 'Link account'
          : 'Create New Account'}
      </Button>
    </S.ButtonsContainer>
  );
};
