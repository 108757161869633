import React, { type ComponentProps } from 'react';

import { useHistory } from '@npm/core/ui/components/molecules/Link';
import { useUserRoleIndexLazy } from '@npm/data-access';

import { ZendeskContactButton } from '../../../../../../app/zendesk';
import { USER_ROLE_INDEX_BASE_PARAMS } from '../../../../../../auth/user/context';
import { type OboDefinition } from '../../../../../../auth/user/role/userRole.types';
import { ACTIVE_STEP_QUERY_PARAM_KEY } from '../../../../../../postOnboardingNew';
import { NoDataArea } from '../OrderPlacementForm.styles';

type Props = {
  selectedAccountId: number;
  isInvestor: boolean;
  obo: OboDefinition | null;
} & ComponentProps<typeof NoDataArea>;

export const AccountNotAccredited = ({
  selectedAccountId,
  isInvestor,
  obo,
  ...rest
}: Props) => {
  const { push } = useHistory();

  const [fetchAvailableAccounts] = useUserRoleIndexLazy({
    variables: USER_ROLE_INDEX_BASE_PARAMS,
  });

  const handleOnClick = async () => {
    if (!isInvestor || obo) return;

    try {
      const userData = await fetchAvailableAccounts();

      const accountRoleId = userData?.user_roles?.find(
        role => role.subject?.id === selectedAccountId
      )?.id;

      push(
        `/post-onboarding?allAccounts=0&checklistOpen=0&pageState=3&roleId=${accountRoleId}&${ACTIVE_STEP_QUERY_PARAM_KEY}=accredited_investor_state`
      );
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <NoDataArea
      outlined
      iconColor="error"
      heading="Account is Not Accredited"
      title={
        isInvestor && !obo ? (
          'Click below to complete 506(b) accreditation questionnaire'
        ) : (
          <>
            <p>
              Only accounts that have been verified as accredited investors can
              place buy orders. Please ask the authorized signatory to complete
              the accreditation step in their investor workstation.
            </p>
            If you have any issues, please{' '}
            <ZendeskContactButton zendeskTicketGroupId="order_entry_accreditation">
              contact NPM Operations
            </ZendeskContactButton>
            .
          </>
        )
      }
      {...(isInvestor &&
        !obo && {
          onClickText: 'Complete Accreditation',
          onClick: handleOnClick,
        })}
      {...rest}
    />
  );
};
