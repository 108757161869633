import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { useHistory } from 'react-router-dom';

import { type OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';

import { oktaAuth } from '../instance';

type Props = {
  children: React.ReactNode;
};

export const SecurityWrapper = ({ children }: Props) => {
  const history = useHistory(); // example from react-router

  const restoreOriginalUri = async (_: OktaAuth, originalUri: string) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  const triggerLogin = async () => {
    await oktaAuth.signInWithRedirect({ originalUri: window.location.href });
  };

  const onAuthRequired = async () => {
    await triggerLogin();
  };

  return (
    <Security
      restoreOriginalUri={restoreOriginalUri}
      oktaAuth={oktaAuth}
      onAuthRequired={onAuthRequired}
    >
      {children}
    </Security>
  );
};

// @TODO: Implement automatical renewal of keys if user is logged in and app is running in background.
/**
 authClient.session.refresh()

 authClient.tokenManager.on('expired', function (key, expiredToken) {
 console.log('Token with key', key, ' has expired:');
 console.log(expiredToken);
 });
 */
