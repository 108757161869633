import { CbLabel } from '@npm/core/ui/components/atoms/Label';
import { type ColumnType } from '@npm/core/ui/components/molecules/Table';
import { type UnpackArrayType } from '@npm/core/ui/utils/unpack.type';
import {
  type BrokerageFirmAccountIndexResponseType,
  CbAccreditationState,
  CbBackgroundCheckState,
  type RepresentativeAggregate,
} from '@npm/data-access';

import { AccountName } from '../../../../../../../account/components/AccountName';
import {
  type NegotiationActionFlags,
  type OboModalState,
} from '../../../OboModal.types';
import { RepresentativeCellItem } from '../../SelectAccount/RepresentativeCellItem';
import { ActionLabel } from '../ActionLabel';
import { InvestorOrStakeholderLabel } from '../InvestorOrStakeholderLabel';

export const getColumns = (
  selectedAccount: OboModalState['account'],
  representative: RepresentativeAggregate,
  needToChooseInvestorOrStakeholder: boolean,
  isInvestor: boolean,
  { userCanActAsInvestor, userCanActAsStakeholder }: NegotiationActionFlags,
  goToRepresentativeStep: () => void,
  showAction: boolean
): ColumnType<
  UnpackArrayType<BrokerageFirmAccountIndexResponseType, 'accounts'>
>[] => [
  {
    title: needToChooseInvestorOrStakeholder ? (
      <InvestorOrStakeholderLabel isInvestor={isInvestor} />
    ) : (
      'Account'
    ),
    key: 'account',
    width: '30%',
    render: account => <AccountName account={account} isActive={false} />,
  },
  {
    title: 'Account Representative',
    key: 'account',
    render: account => {
      const isRowSelected = selectedAccount?.id === account.id;

      return (
        <RepresentativeCellItem
          account={account}
          isActive={isRowSelected}
          handleSwitchRepresentativeClick={goToRepresentativeStep}
        />
      );
    },
  },
  {
    title: 'AML/KYC',
    key: 'background_check_state',
    width: 200,
    render: account =>
      CbBackgroundCheckState.getLabel(CbLabel, account?.background_check_state),
  },
  {
    title: 'Accreditation',
    key: 'accreditation_state',
    width: 120,
    render: account =>
      CbAccreditationState.getLabel(CbLabel, account?.accreditation_state),
  },

  ...(needToChooseInvestorOrStakeholder && showAction
    ? [
        {
          title: 'Action',
          key: 'action',
          width: 120,
          render: () => (
            <ActionLabel
              canTakeAction={
                isInvestor ? userCanActAsInvestor : userCanActAsStakeholder
              }
            />
          ),
        },
      ]
    : []),
];
