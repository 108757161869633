import React from 'react';

import { Flex } from '../../atoms/common';

import { ListItem, type ListItemProps } from './ListItem';

type Props = {
  listItems: string[];
  gap?: ListItemProps['gap'];
  textProps?: ListItemProps['textProps'];
} & (
  | {
      fluid?: never;
      cols?: number;
    }
  | {
      fluid: true;
      cols?: never;
    }
);

export const CheckList = ({
  listItems,
  cols = 1,
  fluid,
  gap = 'md',
  textProps,
}: Props) => {
  return (
    <Flex $wrap>
      {listItems.map((listItem, index) => (
        <ListItem
          title={listItem}
          gap={gap}
          textProps={textProps}
          style={fluid ? {} : { flexBasis: `${100 / cols}%` }}
          key={`${index}-${listItem}`}
        />
      ))}
    </Flex>
  );
};
