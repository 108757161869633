import React from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import { useDarkModeContext } from '@npm/core/ui/context/DarkModeContext';

import {
  type OptInEntityColorVariant,
  type OptInEntityDatadogAction,
  type OptInIcon,
} from '../../OptIn.enums';

import * as S from './EntitySelectionCard.styles';

interface Props {
  icon: OptInIcon;
  colorVariant: OptInEntityColorVariant;
  title: string;
  introText: string;
  datadogAction: OptInEntityDatadogAction;
  buttonText: string;
  onClick: () => void;
}

export const EntitySelectionCard = ({
  icon,
  colorVariant,
  title,
  introText,
  datadogAction,
  buttonText,
  onClick,
}: Props) => {
  const { isDarkMode } = useDarkModeContext();

  return (
    <S.Card $backgroundVariant={colorVariant} $isDarkMode={isDarkMode}>
      <Heading variant="h0" as="span" color={colorVariant} marginBottom="md">
        {title}
      </Heading>
      <Heading variant="h3" as="span" marginBottom="md">
        {introText}
      </Heading>
      <Flex justify="center">
        <Button
          title={buttonText}
          onClick={onClick}
          variant="default"
          color={colorVariant}
          size="lg"
          icon={<Icon name={icon} size="sm" />}
          data-dd-action-name={datadogAction}
        >
          {buttonText}
        </Button>
      </Flex>
      <Margin bottom="sm" />
      <Text size="xs" colorVariant="tertiary">
        Click here to opt-in for access
      </Text>
    </S.Card>
  );
};
