import React, { useMemo } from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Select } from '@npm/core/ui/components/atoms/Select';
import { onPopupScroll } from '@npm/core/ui/components/atoms/Select/Select.utils';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { CypressDataIds } from '@npm/core/ui/constants';
import { CbHoldingState, type Holding } from '@npm/data-access';

import { useUserContextStore } from '../../../../auth/user/context';
import {
  type HoldingSelectHookVariables,
  useHoldingSelectData,
} from '../../../../filters';

import * as S from './HoldingMultiSelect.styles';
import { HoldingOption } from './HoldingOption';
import { HoldingTag } from './HoldingTag';

type Props = HoldingSelectHookVariables & {
  quantities?: {
    quantity: number;
    remainingQuantity: number;
  };
  value?: string[];
  filterOptions?: (holding: Holding) => boolean;
  onChange?: (values: string[]) => void;
  onHoldingsSelect?: (holding: Holding[]) => void;
  onAddHoldingClick?: () => void;
  dropdownStyle?: React.CSSProperties;
};

export const HoldingMultiSelect = ({
  issuerEntityId,
  accountId,
  secondmarket,
  includePortfolioHoldings,
  includeSpvs,
  assetType,
  quantities,
  value,
  onChange,
  onHoldingsSelect,
  onAddHoldingClick,
  filterOptions,
  dropdownStyle,
}: Props) => {
  const {
    availableHoldings,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    error,
  } = useHoldingSelectData({
    accountId,
    issuerEntityId,
    secondmarket,
    includePortfolioHoldings,
    includeSpvs,
    assetType,
    filterOptions,
  });

  const isEntityNpmsInvestor = useUserContextStore(
    store => store.isEntityNpmsInvestor
  );

  const options = useMemo(() => {
    if (!availableHoldings) return [];

    const filteredHoldings = filterOptions
      ? availableHoldings.filter(filterOptions)
      : availableHoldings;

    const transformedHoldings = filteredHoldings.map((holding: Holding) => {
      if (
        isEntityNpmsInvestor &&
        holding.state.code === CbHoldingState.items.pre_verified
      ) {
        return {
          ...holding,
          state: {
            book: CbHoldingState.code,
            code: CbHoldingState.items.pending,
            name: 'Pending',
          },
        };
      }
      return holding;
    });

    return transformedHoldings
      .filter(holding => holding.remaining_quantity > 0)
      .map((item: Holding) => ({
        label: (
          <HoldingOption
            holding={item}
            isSelected={value?.includes(item.npm_guid)}
          />
        ),
        value: item.npm_guid,
        holding: item,
      }));
  }, [value, availableHoldings, filterOptions, isEntityNpmsInvestor]);

  const holdingsMap = useMemo(() => {
    return (
      availableHoldings?.reduce((acc, holding) => {
        acc[holding.npm_guid] = holding;
        return acc;
      }, {}) ?? {}
    );
  }, [availableHoldings]);

  return (
    <Flex direction={'column'} gap={'sm'}>
      <S.Select
        mode={'multiple'}
        value={value}
        error={error}
        loading={isLoading}
        infiniteLoading={isFetchingNextPage}
        onPopupScroll={e =>
          onPopupScroll(e, hasNextPage && !isFetchingNextPage && fetchNextPage)
        }
        placeholder={'Select Holding'}
        showSearch={false}
        maxTagCount={999}
        menuItemSelectedIcon={null}
        dropdownExtraProps={
          onAddHoldingClick
            ? {
                title: 'Add New Holding',
                onClick: onAddHoldingClick,
                icon: 'external-link',
                placement: 'top',
                'data-cy': CypressDataIds.Holdings.Search.AddNewHolding,
              }
            : undefined
        }
        dropdownStyle={dropdownStyle}
        tagRender={({ value: tagValue, onClose }) => {
          const option = options.find(option => option.value === tagValue);

          if (!option) return null;

          const { holding } = option;

          const isLast =
            value.findIndex(v => v === tagValue) === value.length - 1;

          const holdingRender = (
            <HoldingTag
              holding={holding}
              onClose={() => {
                onClose();
              }}
            />
          );

          if (isLast) {
            return (
              <Flex align={'center'} gap={'sm'}>
                {holdingRender}
                <Text color={'info'} size={'sm'} weight={'bold'}>
                  + Sell Multiple Holdings
                </Text>
              </Flex>
            );
          }

          return holdingRender;
        }}
        onChange={(values: string[]) => {
          onChange(values);

          const holdings = values.map(value => holdingsMap[value]);
          onHoldingsSelect?.(holdings);
        }}
      >
        {options.map(option => (
          <Select.Option
            key={option.value}
            value={option.value}
            item={option.holding}
          >
            {option.label}
          </Select.Option>
        ))}
      </S.Select>
      {quantities && (
        <Flex align={'center'} gap={'sm'}>
          <S.QuantityLabel>
            Holding QTY:&nbsp;
            <Text.Quantity
              value={quantities.quantity}
              colorVariant={'secondary'}
            />
          </S.QuantityLabel>
          <S.QuantityLabel>
            Remaining QTY:&nbsp;
            <Text.Quantity
              value={quantities.remainingQuantity}
              colorVariant={'secondary'}
            />
          </S.QuantityLabel>
        </Flex>
      )}
    </Flex>
  );
};
