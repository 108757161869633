import React, { type ComponentProps, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { type ModalProps } from 'antd/lib/modal';

import { CypressDataIds } from '../../../constants';
import { Button, type ButtonProps } from '../../atoms/Button';
import { ErrorSkeleton } from '../../atoms/ErrorSkeleton';
import { Icon } from '../../atoms/Icon';
import { Notification } from '../../atoms/Notification';
import { type Drawer } from '../../organisms/Drawer';
import { LAYOUT_WRAPPER_ID, useLayoutConfig } from '../../organisms/Layout';
import { AlertContainer } from '../AlertContainer';

import * as S from './Modal.styles';

type ModalSize = 'md' | 'lg' | 'xl';

type Props = {
  children?: React.ReactNode;
  $maxHeight?: number;
  okButtonProps?: Omit<ButtonProps, 'onClick'>;
  cancelButtonProps?: Omit<ButtonProps, 'onClick'>;
  size?: ModalSize;
  isFullScreen?: boolean;
  fullScreenProps?: ComponentProps<typeof Drawer>;
  disableCancelWhenLoading?: boolean;
  'data-cy'?: string;
  onCancel?: (e: React.MouseEvent<HTMLElement> | null) => void;
} & Omit<ModalProps, 'onCancel'>;

const SIZE_MAP: Record<ModalSize, number> = {
  md: 594,
  lg: 798,
  xl: 1180,
};

export const Modal = ({
  children,
  okText = 'Confirm',
  cancelText,
  width,
  size = 'lg',
  okButtonProps,
  cancelButtonProps,
  isFullScreen = false,
  fullScreenProps,
  disableCancelWhenLoading = true,
  style,
  ...props
}: Props) => {
  const layoutConfig = useLayoutConfig();
  const [hasError, setHasError] = useState(false);

  const handleCancel = () => {
    if (disableCancelWhenLoading && okButtonProps?.loading) {
      Notification.error({
        message: 'Window cannot be closed, some operation is in progress',
      });
    } else {
      props.onCancel?.(null);
    }
  };

  if (isFullScreen) {
    return (
      <S.Drawer
        width="100%"
        titleHeadingProps={{ marginBottom: 'md' }}
        noHeader
        $layoutConfig={layoutConfig}
        {...(!props.footer &&
          props.onCancel && {
            onSubmit: handleCancel,
            buttonText: 'Close',
          })}
        {...props}
        {...fullScreenProps}
      >
        {children}
      </S.Drawer>
    );
  }

  return (
    <S.Modal
      data-cy={props['data-cy']}
      width={width || SIZE_MAP[size]}
      closeIcon={<Icon name="x-close" size="xs" />}
      centered={!style?.top}
      destroyOnClose
      footer={
        !props.footer
          ? [
              cancelText && (
                <Button
                  key="cancel-button"
                  variant="text"
                  onClick={handleCancel}
                  {...cancelButtonProps}
                  disabled={
                    cancelButtonProps?.disabled || !!okButtonProps?.loading
                  }
                >
                  {cancelText}
                </Button>
              ),
              <Button
                key="submit-button"
                onClick={props.onOk}
                {...okButtonProps}
                disabled={okButtonProps?.disabled || hasError}
                data-cy={CypressDataIds.Modal.Submit}
              >
                {okText}
              </Button>,
            ]
          : props.footer
      }
      style={style}
      // antd's default is `body`, which causes the page to scroll to the top when modal opens
      // leave `body` as fallback for cases where `LAYOUT_WRAPPER_ID` is not available
      getContainer={() =>
        document.getElementById(LAYOUT_WRAPPER_ID) || document.body
      }
      {...props}
      onCancel={handleCancel}
    >
      <ErrorBoundary
        FallbackComponent={ErrorSkeleton}
        onError={() => setHasError(true)}
        onReset={() => setHasError(false)}
      >
        <AlertContainer />
        {children}
      </ErrorBoundary>
    </S.Modal>
  );
};
