import { Text } from '@npm/core/ui/components/atoms/Typography';
import { TABLE_BREAKPOINTS } from '@npm/core/ui/components/molecules/Table';

import { normalizeSize, type OrderSizeType } from '../../../../../../order';
import { OrderStatusFirm } from '../../../../components';
import type { SellFormValues } from '../SellIndicationDrawer.types';

type Props = {
  values: SellFormValues;
  orderSizeType: OrderSizeType;
  estimatedGrossProceeds: number;
};

export const useReviewSectionConfig = ({
  values,
  orderSizeType,
  estimatedGrossProceeds,
}: Props) => {
  const normalizedSize = normalizeSize({
    ...values,
    sizeType: orderSizeType,
  });

  return [
    {
      key: 'order_type',
      title: 'Order Type',
      render: () => <OrderStatusFirm />,
      responsive: TABLE_BREAKPOINTS.DESKTOP,
    },
    {
      title: 'Price Per Share',
      key: 'pricePerShare',
      render: item => (
        <Text.Price value={item.pricePerShare} formatOptions={{ long: true }} />
      ),
    },
    {
      title: 'Size (Shares)',
      key: 'sizeShares',
      render: () => (
        <Text.Quantity
          value={normalizedSize.Shares}
          formatOptions={{ maximumFractionDigits: 0 }}
        />
      ),
    },
    {
      title: 'Estimated Gross Proceeds',
      key: 'estimated_gross_proceeds',
      render: () => <Text.Price value={estimatedGrossProceeds} />,
    },
    {
      title: 'Execution Fee',
      key: 'execution_fee',
      render: () => <Text.Quantity value={1} unit="%" />,
    },
  ];
};
