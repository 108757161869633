import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { EMPTY_VALUE, Text } from '@npm/core/ui/components/atoms/Typography';
import { CompanyLogo } from '@npm/core/ui/components/molecules/CompanyLogo';
import { truncate } from '@npm/core/ui/utils/formatters';
import { type IssuerEntityForMarketFeedAggregate } from '@npm/data-access';
import { useTheme } from 'styled-components';

import { CompanyCardWrapper } from '../CompanyCarousel.styles';

type Props = {
  company: IssuerEntityForMarketFeedAggregate;
  onCompanySelect: (company: IssuerEntityForMarketFeedAggregate) => void;
};

export const CompanyCard = ({ company, onCompanySelect }: Props) => {
  const theme = useTheme();
  const totalBids =
    (company?.live_bids_count ?? 0) + (company?.hidden_bids_count ?? 0);
  const totalAsks =
    (company?.live_offers_count ?? 0) + (company?.hidden_offers_count ?? 0);

  return (
    <CompanyCardWrapper
      key={company.id}
      onClick={() => onCompanySelect(company)}
    >
      <Margin right={'sm'}>
        <CompanyLogo url={company.logo_url} />
      </Margin>

      <div>
        <Text size="xs" colorVariant="primary">
          {company.name ? truncate(company.name, 12) : EMPTY_VALUE}
        </Text>
        <Flex align="center">
          <Text size="xs" style={{ whiteSpace: 'nowrap' }}>
            Bids{' '}
            <span
              style={{
                color: theme.color.success.typography.primary,
                fontWeight: 700,
              }}
            >
              {totalBids}
            </span>
          </Text>
          <Margin left={'xs'} right="xs">
            <Text size="xxs">•</Text>
          </Margin>
          <Text size="xs" style={{ whiteSpace: 'nowrap' }}>
            Asks{' '}
            <span
              style={{
                color: theme?.color?.error?.typography?.primary,
                fontWeight: 700,
              }}
            >
              {totalAsks}
            </span>
          </Text>
        </Flex>
      </div>
    </CompanyCardWrapper>
  );
};
