import React, { useEffect } from 'react';

import { Margin } from '@npm/core/ui/components/atoms/common';
import { Divider } from '@npm/core/ui/components/atoms/Divider';
import { Form } from '@npm/core/ui/components/atoms/Form';
import {
  FormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { WatchlistDataIds } from '@npm/core/ui/constants';
import { Codebooks, type UserRoleAggregate } from '@npm/data-access';
import {
  CbAssetType,
  useCodebook,
  useIssuerEntityShow,
  type Watchlist,
} from '@npm/data-access';
import { type FormInstance, type FormProps } from 'antd/es/form/Form';
import { NumberParam, useQueryParam } from 'use-query-params';

import { YourRoleDrawerSection } from '../../../../auth/user/context/YourRoleDrawerSection';
import {
  useCurrentAccount,
  useCurrentBrokerageFirm,
} from '../../../../auth/user/role';
import { AccountFilter, CompanySearch } from '../../../../filters';
import { useAllowedAssetTypeOptions } from '../../../../order';

import { ActionsTabs } from './ActionsTabs';
import { makeAccountAutoselect } from './UpsertWatchlistForm.utils';
import { WatchlistEntrySection } from './WatchlistEntrySection';

// Custom types because API types differ
export type FormValues = {
  issuer_entity_id: number | string;
  account_id: number;
  order_buy_sell: string;
  asset_type: string;
  minimum_size?: number;
  note?: string;
};

type Props = {
  form: FormInstance<FormValues>;
  watchlist?: Watchlist;
  accountId?: number;
  disableAccountSelect?: boolean;
  filterAccounts?: (role: UserRoleAggregate) => boolean;
  onCreateAccount?: () => void;
  onValuesChange?: (
    changedValues: Partial<FormValues>,
    values: FormValues
  ) => void;
} & FormProps;

export const UpsertWatchlistForm = ({
  form,
  watchlist,
  accountId,
  disableAccountSelect = !!watchlist || !!accountId,
  filterAccounts,
  onCreateAccount,
  onValuesChange,
  ...formProps
}: Props) => {
  const [preSelectedIssuerEntityId] = useQueryParam('companyId', NumberParam);
  const { accountId: accountCentricAccountId } = useCurrentAccount();
  const { brokerageFirmId } = useCurrentBrokerageFirm();

  const initialValues: Partial<FormValues> = {
    account_id: accountId || accountCentricAccountId,
    asset_type: CbAssetType.items.AnyShareClass,
  };

  const { data: selectedIssuerEntity } = useIssuerEntityShow(
    {
      id: preSelectedIssuerEntityId?.toString(),
    },
    {
      queryConfig: {
        enabled: !!preSelectedIssuerEntityId,
      },
    }
  );

  const { data: assetTypeData } = useCodebook({
    type: Codebooks.ASSET_TYPE,
  });

  const allowedAssetTypeOptions = useAllowedAssetTypeOptions(
    assetTypeData?.codebooks ?? []
  );

  useEffect(() => {
    if (preSelectedIssuerEntityId) {
      form.setFieldsValue({ issuer_entity_id: [preSelectedIssuerEntityId] });
    }

    if (watchlist) {
      const existingValues: Partial<FormValues> = {
        issuer_entity_id: watchlist?.issuer_entity_id,
        account_id: watchlist?.account_id,
        order_buy_sell: watchlist?.order_buy_sell?.code,
        asset_type:
          watchlist?.asset_type?.code ?? CbAssetType.items.AnyShareClass,
        minimum_size: watchlist?.minimum_size,
        note: watchlist?.note?.contents,
      };

      form.setFieldsValue(existingValues);
    }
  }, [form, watchlist, preSelectedIssuerEntityId]);

  return (
    <Form<FormValues>
      form={form}
      layout="vertical"
      requiredMark={false}
      initialValues={initialValues}
      onValuesChange={onValuesChange}
      {...formProps}
    >
      <Margin top="sm">
        <FormItem
          name="issuer_entity_id"
          label="Company"
          rules={[VALIDATION_RULES.required()]}
          marginBottom="lg"
        >
          <CompanySearch
            placeholder="Search"
            disabled={!!watchlist}
            variables={{ unverifiedHoldings: true }}
            onSelect={val => form.setFieldsValue({ issuer_entity_id: val })}
            {...(preSelectedIssuerEntityId && {
              value: preSelectedIssuerEntityId,
              defaultEntity: selectedIssuerEntity,
            })}
          />
        </FormItem>
      </Margin>
      {accountCentricAccountId ? (
        <YourRoleDrawerSection showTitle={false} />
      ) : (
        <FormItem
          data-cy={WatchlistDataIds.Drawer.Form.AccountSearch}
          name="account_id"
          label="Account"
          rules={[VALIDATION_RULES.required()]}
          marginBottom="md"
        >
          <AccountFilter
            brokerageFirmId={brokerageFirmId}
            disabled={disableAccountSelect}
            filterOptions={filterAccounts}
            onCreateAccount={onCreateAccount}
            onFetchComplete={makeAccountAutoselect(
              form,
              accountCentricAccountId,
              filterAccounts
            )}
          />
        </FormItem>
      )}
      <Divider marginTop={24} marginBottom={24} colorVariant="secondary" />
      <ActionsTabs form={form} />
      <Margin bottom="lg" top="md">
        <WatchlistEntrySection
          form={form}
          assetTypes={allowedAssetTypeOptions}
        />
      </Margin>
    </Form>
  );
};
