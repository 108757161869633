import React, { type ComponentProps } from 'react';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { CompanyLogo } from '@npm/core/ui/components/molecules/CompanyLogo';
import { useModal } from '@npm/core/ui/components/molecules/Modal';
import { Drawer } from '@npm/core/ui/components/organisms/Drawer';
import { CbWorkstationType, type Holding } from '@npm/data-access';

import { useCurrentWorkstation } from '../../../auth/user/role';
import {
  DocumentViewerModal,
  useDocumentViewer,
} from '../../../documents/DocumentViewer';
import { HoldingCard } from '../../Card';
import { CorrectionInstructionsModal } from '../../CorrectionInstructionsModal';
import { HoldingDrawer, useHoldingDrawer } from '../../Drawer';
import {
  HoldingStatusChangeModal,
  useHoldingStatusChangeModal,
} from '../../StatusChangeModal';
import { StatusChangeButtons } from '../StatusChangeButtons';

type Props = {
  holding: Holding | undefined;
} & Pick<ComponentProps<typeof Drawer>, 'open' | 'onClose' | 'isLoading'>;

export const HoldingDetailDrawer = ({
  holding,
  open,
  onClose,
  isLoading,
}: Props) => {
  const [openModal, modalProps] = useModal();
  const { documentViewerModalProps, showNpmDocument } = useDocumentViewer();
  const currentWorkstation = useCurrentWorkstation();
  const isInvestor =
    currentWorkstation.type.code === CbWorkstationType.items.investor;

  const [openHoldingStatusChangeModal, holdingStatusChangeModalProps] =
    useHoldingStatusChangeModal();

  const [{ openEditHoldingDrawer }, holdingDrawerProps] = useHoldingDrawer({});

  const canBeVerified = holding?.can_be_verified;
  const showAlert = !canBeVerified && !isInvestor && !isLoading;

  return (
    /* this drawer is used as a part of the match asset component that is used in tables
     * and clicking through the drawer would propagate to the table column */
    <div onClick={e => e.stopPropagation()}>
      <Drawer
        title={
          <Flex
            justify="space-between"
            align="center"
            style={{ width: '100%' }}
          >
            Preview Holding
            <CompanyLogo
              url={holding?.issuer_entity?.logo_url}
              title={holding?.issuer_entity?.logo_name}
              name={holding?.issuer_entity?.name}
              size="sm"
            />
          </Flex>
        }
        onClose={onClose}
        open={open}
        footer={
          holding ? (
            <StatusChangeButtons
              holding={holding}
              onClick={props => openHoldingStatusChangeModal([props])}
              onEditClick={openEditHoldingDrawer}
              variant="drawer"
            />
          ) : null
        }
      >
        {showAlert && (
          <Margin bottom="md">
            <Alert
              type="error"
              message='This holding can be verified once the required information is collected. Please edit the fields or return to the shareholder by clicking "Reject".'
            />
          </Margin>
        )}
        <HoldingCard
          config={{
            showIssuance: true,
            numberOfRowsToShow: 20,
            showAccount: true,
            accountWithoutLink: true,
          }}
          openModal={openModal}
          onDocumentClick={showNpmDocument}
          holding={holding}
          isLoading={isLoading}
        />
      </Drawer>
      <HoldingDrawer {...holdingDrawerProps} />
      <HoldingStatusChangeModal {...holdingStatusChangeModalProps} />
      <CorrectionInstructionsModal holding={holding} {...modalProps} />
      <DocumentViewerModal {...documentViewerModalProps} />
    </div>
  );
};
