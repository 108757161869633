import React, { type ComponentProps } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import {
  TABLE_ACTIONS_COLUMN_WIDTH,
  TABLE_BREAKPOINTS,
  TableActionsContainer,
} from '@npm/core/ui/components/molecules/Table';
import { CypressDataIds } from '@npm/core/ui/constants';
import { composeDataId } from '@npm/core/ui/utils/cypress';
import { getDisabledActionTooltipText } from '@npm/core/ui/utils/getDisabledActionTooltipText';
import {
  type EventAggregate,
  type Holding,
  type SubmissionItemsInner,
} from '@npm/data-access';

import { OfferButton, StatusChangeButtons } from '../../components';
import {
  getDisabledDeleteActionTooltip,
  getDisabledHoldingActionsTooltip,
  getHoldingItem,
  getIsHoldingAttributeVisible,
} from '../../Holdings.utils';

import { type HoldingColumnType } from './HoldingsTable.columns';

export const EXTRA_EXPANDED_ROWS: HoldingColumnType[] = [
  {
    ...getHoldingItem('grant_type')(),
    responsive: TABLE_BREAKPOINTS.NONE,
    hidden: ({ asset }) =>
      !getIsHoldingAttributeVisible(asset?.type?.code, 'grant_type'),
  },
  {
    ...getHoldingItem('grant_date')(),
    responsive: TABLE_BREAKPOINTS.NONE,
    hidden: ({ asset }) =>
      !getIsHoldingAttributeVisible(asset?.type?.code, 'grant_date'),
  },
  {
    ...getHoldingItem('plan')(),
    responsive: TABLE_BREAKPOINTS.NONE,
    hidden: ({ asset }) =>
      !getIsHoldingAttributeVisible(asset?.type?.code, 'plan'),
  },
  {
    ...getHoldingItem('class')(),
    responsive: TABLE_BREAKPOINTS.NONE,
    hidden: ({ asset }) =>
      !getIsHoldingAttributeVisible(asset?.type?.code, 'class'),
  },
  {
    ...getHoldingItem('series')(),
    responsive: TABLE_BREAKPOINTS.NONE,
    hidden: ({ asset }) =>
      !getIsHoldingAttributeVisible(asset?.type?.code, 'series'),
  },
  {
    ...getHoldingItem('strike_price')(),
    responsive: TABLE_BREAKPOINTS.NONE,
    hidden: ({ asset }) =>
      !getIsHoldingAttributeVisible(asset?.type?.code, 'strike_price'),
  },
  {
    ...getHoldingItem('expiration_date')(),
    responsive: TABLE_BREAKPOINTS.NONE,
    hidden: ({ asset }) =>
      !getIsHoldingAttributeVisible(asset?.type?.code, 'expiration_date'),
  },
  {
    ...getHoldingItem('acquisition_date')(),
    responsive: TABLE_BREAKPOINTS.NONE,
    hidden: ({ asset }) =>
      !getIsHoldingAttributeVisible(asset?.type?.code, 'acquisition_date'),
  },
  {
    ...getHoldingItem('cost_basis')(),
    responsive: TABLE_BREAKPOINTS.NONE,
    hidden: ({ asset }) =>
      !getIsHoldingAttributeVisible(asset?.type?.code, 'cost_basis'),
  },
];

export const getEditButtons = (
  onEditClick?: (id: number) => void,
  onDeleteClick?: (holding: Holding) => void,
  onEnterIOIClick?: (holding: Holding) => void,
  notAuthorized?: boolean
): HoldingColumnType => ({
  key: 'actions',
  align: 'right',
  responsive: TABLE_BREAKPOINTS.MOBILE,
  width: TABLE_ACTIONS_COLUMN_WIDTH,
  hidden: !onEditClick || !onDeleteClick,
  render: holding => {
    const disabledTooltip =
      getDisabledActionTooltipText(notAuthorized) ||
      getDisabledHoldingActionsTooltip(holding);

    return (
      <TableActionsContainer>
        <Tooltip
          title={disabledTooltip}
          getPopupContainer={() => document.body}
        >
          <Button
            variant={'outline'}
            disabled={!!disabledTooltip}
            data-cy={composeDataId(
              CypressDataIds.Holdings.Button.Edit,
              holding.id
            )}
            onClick={() => onEditClick(holding.id)}
          >
            Edit
          </Button>
        </Tooltip>
        {!!onEnterIOIClick && (
          <Button
            variant="outline"
            onClick={() => onEnterIOIClick(holding)}
            color="error"
          >
            Sell
          </Button>
        )}
        <Tooltip
          title={disabledTooltip || getDisabledDeleteActionTooltip(holding)}
          getPopupContainer={() => document.body}
        >
          <Button
            color="error"
            variant="text"
            icon={<Icon name="trash" />}
            disabled={
              !!disabledTooltip || !!getDisabledDeleteActionTooltip(holding)
            }
            data-cy={composeDataId(
              CypressDataIds.Holdings.Button.Delete,
              holding.id
            )}
            onClick={() => onDeleteClick(holding)}
          />
        </Tooltip>
      </TableActionsContainer>
    );
  },
});

export const getStatusChangeButtons = (
  onClick: ComponentProps<typeof StatusChangeButtons>['onClick'],
  onEditClick: (id: number) => void,
  onDeleteClick?: (holding: Holding) => void
): HoldingColumnType => ({
  key: 'status_change_buttons',
  align: 'right',
  responsive: TABLE_BREAKPOINTS.MOBILE,
  render: holding => (
    <StatusChangeButtons
      holding={holding}
      onClick={onClick}
      onEditClick={onEditClick}
      onDeleteClick={onDeleteClick}
    />
  ),
  hidden: !onClick,
});

export const getOfferButton = ({
  onOfferClick,
  event,
  orders,
}: {
  onOfferClick?: (holding: Holding) => void;
  event?: EventAggregate;
  orders?: SubmissionItemsInner[];
} = {}): HoldingColumnType => ({
  key: 'offer_button',
  align: 'right',
  width: 1,
  responsive: TABLE_BREAKPOINTS.MOBILE,
  render: holding => {
    if (!onOfferClick) return null;
    return (
      <Flex justify="flex-end">
        <OfferButton
          event={event}
          holding={holding}
          orders={orders?.filter(one => one.holding?.id === holding.id)}
          onHoldingClick={onOfferClick}
        />
      </Flex>
    );
  },
});
