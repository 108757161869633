import { useRef } from 'react';

import { useModal } from '@npm/core/ui/components/molecules/Modal';
import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import {
  type WizardStore,
  WizardStoreActionTypes,
} from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';
import { type OnboardingStatus } from '@npm/data-access';
import { every, some } from 'lodash';
import { StringParam, useQueryParam } from 'use-query-params';

import { AMLKYCCompletedModal } from '../modals';
import { OnboardingCompletedModal } from '../modals/OnboardingCompletedModal';
import {
  type OnboardingSectionConfig,
  type PostOnboardingContextType,
} from '../PostOnboarding.types';
import { calculateSectionProgress } from '../PostOnboarding.utils';

export const useCompletedOnboardingSectionModals = () => {
  const initialSectionsProgress =
    useRef<PostOnboardingContextType['sectionsProgress']>(null);
  const [openAMLKYCCompletedModal, AMLKYCCompletedModalProps] = useModal();
  const [openOnboardingCompletedModal, onboardingCompletedModalProps] =
    useModal();
  const [signingResultQueryParam, setSigningResultQueryParam] = useQueryParam(
    'signing_result',
    StringParam
  );

  const dispatch = useWizardStore(
    (s: WizardStore<PostOnboardingContextType>) => s.dispatch
  );

  const handleOnboardingStatusUpdate = (
    sectionsConfig: OnboardingSectionConfig[],
    onboardingStatus: OnboardingStatus
  ) => {
    const sectionsProgress = sectionsConfig.reduce(
      (acc, section) => {
        return {
          ...acc,
          [section.key]: calculateSectionProgress(
            section.items || [],
            onboardingStatus
          ),
        };
      },
      {} as PostOnboardingContextType['sectionsProgress']
    );

    if (!initialSectionsProgress.current) {
      initialSectionsProgress.current = sectionsProgress;
    }
    dispatch({
      type: WizardStoreActionTypes.UPDATE_DATA,
      payload: {
        sectionsProgress,
      },
    });

    const areAllStepsCompleted = every(sectionsProgress, val => val === 100);

    if (
      areAllStepsCompleted &&
      (some(initialSectionsProgress.current, val => val < 100) ||
        // handle onboarding agreement being the last incomplete step
        // user getting redirected back to the app from docusign - `signing_result` query param is present in the url
        signingResultQueryParam !== undefined)
    ) {
      openOnboardingCompletedModal();
      // update the `initialSectionsProgress` ref to avoid showing the modal again
      // if the user edits the info in one of the steps
      initialSectionsProgress.current = {
        aml_kyc: 100,
        identity_verification: 100,
        agreements: 100,
        other_requirements: 100,
      };
      setSigningResultQueryParam(undefined);
      return;
    }

    // do not show the AML/KYC completed modal for users who went through Persona:
    if (sectionsProgress.identity_verification !== undefined) {
      return;
    }

    if (
      sectionsProgress.aml_kyc === 100 &&
      initialSectionsProgress.current.aml_kyc !== 100
    ) {
      openAMLKYCCompletedModal();
      // update the `initialSectionsProgress` ref to avoid showing the modal again
      // if the user edits the info in one of the steps
      initialSectionsProgress.current = {
        ...initialSectionsProgress.current,
        identity_verification: 100,
      };
    }
  };

  return {
    handleOnboardingStatusUpdate,
    renderModals: (accountId: number) => (
      <>
        <AMLKYCCompletedModal
          accountId={accountId}
          modalProps={AMLKYCCompletedModalProps}
        />
        <OnboardingCompletedModal
          accountId={accountId}
          modalProps={onboardingCompletedModalProps}
        />
      </>
    ),
  };
};
