import { Heading3Styles } from '@npm/core/ui/components/atoms/Typography';
import { Modal as BaseModal } from '@npm/core/ui/components/molecules/Modal';
import { Wizard } from '@npm/core/ui/components/organisms/Wizard';
import { useBreakpoint } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

export const Modal = styled(BaseModal)`
  ol {
    padding-inline-start: ${({ theme }) => theme.spacing.lg}px;
  }

  .ant-modal-header .ant-modal-title {
    ${Heading3Styles};
    font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
  }
`;

export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;

  ${({ theme }) => useBreakpoint(theme).mobile`
      flex-direction: column;
      align-items: stretch;

      button:not(:last-child) {
        margin-bottom: ${theme.spacing.sm}px;
      }
  `}
`;

export const AcceptButton = styled(Wizard.NextButton)`
  width: auto;

  ${({ theme }) => useBreakpoint(theme).mobile`
      width: 100%;
      max-width: unset;
      margin-left: 0;
      margin-bottom: ${({ theme }) => theme.spacing.sm}px;
  `}
`;

export const BackArrowBtn = styled(Wizard.BackButton)`
  svg,
  span {
    color: ${({ theme }) => theme.color.info.typography.primary};
  }

  ${({ theme }) => useBreakpoint(theme).mobile`
      width: 100%;
      max-width: unset;
      margin-left: 0;
  `}
`;
