import React, { useEffect, useMemo, useRef, useState } from 'react';

import { CardBase } from '@npm/core/ui/components/atoms/CardBase';
import {
  showStepTooltip,
  useChartOptions,
} from '@npm/core/ui/components/atoms/Charts';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { useDarkModeContext } from '@npm/core/ui/context/DarkModeContext';
import { Chart } from 'chart.js';

import { useCompanyOverviewChartFilter } from '../../../CompanyOverview.hooks';
import type { CompanyOverviewChartSeries } from '../../../CompanyOverview.types';
import {
  getCompanyOverviewXScaleConfig,
  getCompanyOverviewYScaleConfig,
} from '../../../CompanyOverview.utils';
import { CompanyOverviewChartContainer } from '../../../components/CompanyOverviewChartContainer';
import { CompanyOverviewChartFilters } from '../../../components/CompanyOverviewChartFilters/CompanyOverviewChartFilters';
import { sortPrimaryRoundsData } from '../PrimaryRoundsTable/PrimaryRounds.utils';

import { PrimaryRoundsChartTooltip } from './PrimaryRoundsChartTooltip/PrimaryRoundsChartTooltip';

import {
  usePrimaryRoundDataset,
  usePrimaryRoundDateLimits,
} from './PrimaryRoundsChart.hooks';
import {
  type PrimaryRoundsChartDataPoint,
  type PrimaryRoundValue,
} from './PrimaryRoundsChart.types';
import {
  getPrimaryRoundsChartTooltip,
  PRIMARY_ROUNDS_CHART_HTML_ID,
} from './PrimaryRoundsChart.utils';

type Props = {
  data: PrimaryRoundValue[];
};

export const PrimaryRoundsChart = ({ data }: Props) => {
  const [activeTooltipIndex, setActiveTooltipIndex] = useState<
    number | undefined
  >(undefined);

  // Dark-mode context used to fix issues with switching between dark and light mode
  const { isDarkMode } = useDarkModeContext();

  const sortedData = useMemo(() => {
    return sortPrimaryRoundsData(data);
  }, [data]);

  const [series, setSeries] = useState<CompanyOverviewChartSeries>('PPS');

  const [minDate, maxDate] = usePrimaryRoundDateLimits(data);

  const {
    setCurrentPage,
    currentMinDate,
    currentMaxDate,
    currentPage,
    pageCount,
    range,
    setRange,
  } = useCompanyOverviewChartFilter(minDate, maxDate);

  const labels = useMemo(() => {
    return sortedData.map(({ investment_date }) => investment_date);
  }, [sortedData]);

  const dataset = usePrimaryRoundDataset(sortedData, {
    series,
    currentMinDate,
    currentMaxDate,
  });

  const options = useChartOptions();

  const canvasRef = useRef<HTMLCanvasElement>();

  const chartRef =
    useRef<Chart<'line', PrimaryRoundsChartDataPoint[], string>>();

  const destroyChart = () => {
    if (chartRef.current) {
      chartRef.current.destroy();
      chartRef.current = null;
    }
  };

  const resetTooltip = () => {
    if (!chartRef.current) return;

    getPrimaryRoundsChartTooltip(chartRef.current?.canvas).style.opacity = '0';
    chartRef.current.update('none');
  };

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');

    chartRef.current = new Chart<'line', PrimaryRoundsChartDataPoint[], string>(
      ctx,
      {
        type: 'line',
        data: {
          labels,
          datasets: [dataset],
        },
        options: options({
          scales: {
            x: getCompanyOverviewXScaleConfig(currentMinDate, currentMaxDate),
            y: getCompanyOverviewYScaleConfig(),
          },
          interaction: {
            mode: 'segment',
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
              position: 'followCursor',
              callbacks: {
                title: () => ' '.repeat(75),
                label: () => '',
                beforeBody: () => '\n\n\n',
                afterBody: () => ' ',
                footer: () => '',
              },
              external: function (context) {
                showStepTooltip({
                  context,
                  canvas: canvasRef.current,
                  getTooltipElement: getPrimaryRoundsChartTooltip,
                  onIndexChanged: setActiveTooltipIndex,
                });
              },
            },
          },
        }),
      }
    );

    chartRef.current.update('none');

    return () => destroyChart();
  }, [labels, dataset, isDarkMode, options, currentMinDate, currentMaxDate]);

  const hasValuation = useMemo(() => {
    return data?.some(trade => !!trade.valuation);
  }, [data]);

  return (
    <CardBase noContentPadding={true}>
      <Flex direction="column" gap="xs">
        <CompanyOverviewChartFilters
          series={series}
          onChangeSeries={setSeries}
          canChangeSeries={hasValuation}
          range={range}
          onChangeRange={range => {
            setRange(range);
            resetTooltip();
          }}
          pagination={{
            page: currentPage,
            totalPages: pageCount,
            onPageChange: val => {
              setCurrentPage(val);
              resetTooltip();
            },
          }}
        />
        <CompanyOverviewChartContainer>
          <canvas ref={canvasRef} />
          <div
            id={PRIMARY_ROUNDS_CHART_HTML_ID}
            className="html-chart-tooltip"
            style={{
              opacity: activeTooltipIndex !== undefined ? 0.9 : 0,
            }}
          >
            <PrimaryRoundsChartTooltip
              value={dataset.data[activeTooltipIndex]?.raw}
            />
          </div>
        </CompanyOverviewChartContainer>
      </Flex>
    </CardBase>
  );
};
