import { useMemo } from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { ATSPoolLabel } from '@npm/core/ui/components/atoms/Label';
import { Radio, RadioGroup } from '@npm/core/ui/components/atoms/Radio';
import {
  CollapsibleFormSection,
  useCollapsibleFormSection,
} from '@npm/core/ui/components/molecules/CollapsibleFormSection';
import {
  type AccountForSecondmarketOrderItemBrokerageFirm,
  type ATSPoolCode,
  CbAffiliateDisclosure,
  CbATSPool,
  CbVisibility,
  Codebooks,
  type CodebookShow,
  type SecondmarketOrderItemShow,
  useCodebook,
  type VisibilityCode,
} from '@npm/data-access';

const formSectionName = 'atsPool';

type Props = {
  value?: string | undefined;
  onChange?: (value: string) => void;
  visibility: VisibilityCode | undefined;
  brokerageFirm: AccountForSecondmarketOrderItemBrokerageFirm | undefined;
  order?: SecondmarketOrderItemShow;
};

export const ATSPoolSelect = ({
  value,
  onChange,
  visibility,
  brokerageFirm,
  order,
}: Props) => {
  const { activeKeys, handleItemChange, handleSectionChange } =
    useCollapsibleFormSection({});

  const { data: atsPoolData } = useCodebook({
    type: Codebooks.ATS_POOL,
  });

  const optionsByCode = useMemo(
    () =>
      (atsPoolData?.codebooks ?? []).reduce(
        (acc, option) => ({ ...acc, [option.code as ATSPoolCode]: option }),
        {} as Record<ATSPoolCode, CodebookShow>
      ),
    [atsPoolData]
  );

  const isAffiliateDisclosureLimited =
    order?.affiliate_disclosure?.code == CbAffiliateDisclosure.items.yes ||
    order?.affiliate_disclosure?.code ==
      CbAffiliateDisclosure.items.complete_later;

  return (
    <>
      {!isAffiliateDisclosureLimited && (
        <CollapsibleFormSection
          activeKey={activeKeys}
          onChange={handleSectionChange}
        >
          <CollapsibleFormSection.Item
            label="ATS Pool"
            preview={
              <ATSPoolLabel
                brokerageFirm={brokerageFirm}
                codebookItem={optionsByCode[value]}
              />
            }
            name={formSectionName}
            key={formSectionName}
          >
            <RadioGroup
              value={value}
              onChange={({ target }) => {
                onChange?.(target.value);
                handleItemChange(formSectionName);
              }}
            >
              <Flex direction="column" gap="md">
                {visibility === CbVisibility.items.internal && (
                  <Radio
                    value={CbATSPool.items.internal}
                    label={
                      <ATSPoolLabel
                        brokerageFirm={brokerageFirm}
                        codebookItem={optionsByCode[CbATSPool.items.internal]}
                      />
                    }
                  />
                )}
                {visibility === CbVisibility.items.external &&
                  !isAffiliateDisclosureLimited && (
                    <Radio
                      value={CbATSPool.items.ats_sm}
                      label={
                        <ATSPoolLabel
                          brokerageFirm={brokerageFirm}
                          codebookItem={optionsByCode[CbATSPool.items.ats_sm]}
                        />
                      }
                    />
                  )}
                {visibility === CbVisibility.items.external &&
                  brokerageFirm?.access_ats_i && (
                    <Radio
                      value={CbATSPool.items.ats_i}
                      label={
                        <ATSPoolLabel
                          brokerageFirm={brokerageFirm}
                          codebookItem={optionsByCode[CbATSPool.items.ats_i]}
                        />
                      }
                    />
                  )}
              </Flex>
            </RadioGroup>
          </CollapsibleFormSection.Item>
        </CollapsibleFormSection>
      )}

      {isAffiliateDisclosureLimited && (
        <ATSPoolLabel
          brokerageFirm={brokerageFirm}
          codebookItem={optionsByCode[CbATSPool.items.ats_i]}
        />
      )}
    </>
  );
};
