import React from 'react';

import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Divider } from '@npm/core/ui/components/atoms/Divider';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import { useTheme } from 'styled-components';

type Props = {
  title: React.ReactNode;
  isSubstep: boolean;
  substepIndex: number;
  totalStepSubsteps: number;
  tooltip?: React.ReactNode;
};

export const StepHeading = ({
  title,
  isSubstep,
  substepIndex,
  totalStepSubsteps,
  tooltip,
}: Props) => {
  const theme = useTheme();

  return (
    <>
      {isSubstep && (
        <Text size="sm" color="info" marginBottom={theme.spacing.xs}>
          {substepIndex + 1} of {totalStepSubsteps}
        </Text>
      )}
      <Margin bottom={theme.spacing.xl}>
        <Flex align="center">
          <Heading variant="h1" as="h2">
            {title}
          </Heading>
          {tooltip && (
            <Tooltip size="sm" title={tooltip}>
              <Margin left={theme.spacing.sm} top={theme.spacing.sm} as="span">
                <Icon
                  size="xs"
                  name="info-circle"
                  color={theme.color.info.typography.primary}
                />
              </Margin>
            </Tooltip>
          )}
        </Flex>
      </Margin>
      <Divider marginBottom={theme.spacing.xl} />
    </>
  );
};
