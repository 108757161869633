import { useCallback, useState } from 'react';

import { useDocumentShow } from '@npm/data-access';

import { Header } from '../../auth/user/context/store/role/role.store';
import { useObo } from '../../auth/user/role';

import { type Document } from './DocumentViewer.types';

const getNpmDocumentId = (doc: Document) =>
  doc?.type === 'with-npm-id' ? doc.id : null;

export const useDocumentViewer = ({
  includeOboHeaders = true,
}: {
  includeOboHeaders?: boolean;
} = {}) => {
  const { isObo } = useObo();
  const [isPreviewModalOpen, setPreviewModalOpen] = useState(false);
  const [previewDocument, setPreviewDocument] = useState<Document>(null);
  const [documentMetadata, setDocumentMetadata] = useState<{
    displayName: string | null | undefined;
    downloadable: boolean | null | undefined;
  }>();

  useDocumentShow(
    {
      id: getNpmDocumentId(previewDocument),
    },
    {
      onComplete: data => {
        setDocumentMetadata({
          downloadable: data?.downloadable,
          displayName: data?.display_name,
        });
      },
      queryConfig: {
        enabled: !!getNpmDocumentId(previewDocument),
      },
      ...(isObo &&
        !includeOboHeaders && {
          axiosConfig: {
            headers: {
              [Header['OboAccountId']]: undefined,
              [Header['OboUserId']]: undefined,
            },
          },
        }),
    }
  );

  const showDocumentPreview = useCallback(
    (document: Document | null | undefined) => {
      if (document) {
        setPreviewModalOpen(true);
        setPreviewDocument(document);
      }
    },
    [setPreviewModalOpen, setPreviewDocument]
  );

  const showNpmDocument = useCallback(
    async (
      idOrDocumentOrFile?:
        | number
        | File
        | {
            id?: number;
          }
    ) => {
      // if file is in memory
      if (idOrDocumentOrFile instanceof File) {
        const file = idOrDocumentOrFile as File;
        const arrayBuffer = await file.arrayBuffer();
        const uint8Array = new Uint8Array(arrayBuffer);
        showDocumentPreview({
          type: 'with-binary-data',
          data: uint8Array,
          contentType: file.type,
          name: file.name,
        });
        return;
      }

      const documentId =
        typeof idOrDocumentOrFile === 'number'
          ? idOrDocumentOrFile
          : idOrDocumentOrFile?.id;

      if (documentId) {
        showDocumentPreview({
          type: 'with-npm-id',
          id: documentId,
        });
      }
    },
    [showDocumentPreview]
  );

  const onClose = useCallback(() => {
    setPreviewModalOpen(false);
    setPreviewDocument(null);
    setDocumentMetadata(null);
  }, [setPreviewModalOpen, setPreviewDocument]);

  return {
    showDocumentPreview,
    showNpmDocument,
    documentViewerModalProps: {
      handleCancel: onClose,
      document: previewDocument,
      documentMetadata,
      isOpen: isPreviewModalOpen,
    },
  };
};
