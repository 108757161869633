import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { type AccountIndex, CbAccountType } from '@npm/data-access';
import { useUserContextStore } from '@npm/features/auth/user/context';

import { EntitySelectionCard } from '../components/EntitySelectionCard/EntitySelectionCard';
import {
  OptInEntityColorVariant,
  OptInEntityDatadogAction,
  OptInIcon,
  OptInStep,
} from '../OptIn.enums';

import * as S from './OptInEntitySelection.styles';

const entityType = CbAccountType.items.OrganizationAccount;
const individualType = CbAccountType.items.PersonAccount;

type Props = {
  value?: string | undefined;
  onChange?: (value: string | undefined) => void;
  eligibleEntities: AccountIndex;
  setOptInStep: (step: OptInStep) => void;
};

export const OptInEntitySelection = ({
  onChange,
  eligibleEntities,
  setOptInStep,
}: Props) => {
  const { isMobile, isTablet } = useBreakpoints();

  const fullLegalName = useUserContextStore(store => store.getFullName());

  const hasEligibleIndividualEntities = eligibleEntities?.accounts?.some(
    account => account.type.code === individualType
  );
  const hasEligibleInstitutionalEntities = eligibleEntities?.accounts?.some(
    account => account.type.code === entityType
  );

  return (
    <>
      <S.TitleContainer>
        <Heading variant="h1" textAlign="center">
          Join SecondMarket. NPM’s Marketplace for Private Companies
        </Heading>
        <Text colorVariant="tertiary">Create your account to gain access.</Text>
      </S.TitleContainer>
      <Margin bottom={'sm'} />

      <Flex direction={isMobile || isTablet ? 'column' : 'row'} gap={'lg'}>
        <EntitySelectionCard
          icon={OptInIcon.INDIVIDUAL}
          colorVariant={OptInEntityColorVariant.INDIVIDUAL}
          datadogAction={OptInEntityDatadogAction.INDIVIDUAL}
          title="I’m an Individual or Employee"
          introText="Acting on behalf of yourself? Join other individuals who use SecondMarket. Sell your shares for 1.0% flat fee."
          buttonText={
            hasEligibleIndividualEntities
              ? `Link as [${fullLegalName}]`
              : 'Create Individual Account'
          }
          onClick={() => {
            onChange?.(individualType);
            setOptInStep(OptInStep.SIGNATURE);
          }}
        />
        <EntitySelectionCard
          icon={OptInIcon.INSTITUTION}
          colorVariant={OptInEntityColorVariant.INSTITUTION}
          datadogAction={OptInEntityDatadogAction.INSTITUTION}
          title="I’m an Entity or Institution"
          introText="Acting on behalf of an entity? (e.g. Institution, Fund, Trust, LLC?) Available today for entity sellers and buyers."
          buttonText={
            hasEligibleInstitutionalEntities
              ? 'Link Entity Account'
              : 'Create Entity Account'
          }
          onClick={() => {
            onChange?.(entityType);
            setOptInStep(OptInStep.SIGNATURE);
          }}
        />
      </Flex>
    </>
  );
};
