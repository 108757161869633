import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { Text, type TextSize } from '@npm/core/ui/components/atoms/Typography';
import { shorten } from '@npm/core/ui/utils/formatters';
import { type Holding, useHoldingShow } from '@npm/data-access';
import { useTheme } from 'styled-components';

import { getUniqueHoldingIdName } from '../../Holdings.utils';
import { HoldingDetailDrawer } from '../HoldingDetailDrawer';
import { type HoldingForHoldingLabel } from '../HoldingLabel';

import * as S from './HoldingUniqueIdWithDetailDrawer.styles';

type Props = {
  holding: HoldingForHoldingLabel;
  isUniqueIdClickable?: boolean;
  holdingUniqueIdMaxLength?: number;
  withIcon?: boolean;
  needsCorrection?: boolean;
  textSize?: TextSize;
};

export const HoldingUniqueIdWithDetailDrawer = ({
  holding,
  isUniqueIdClickable = false,
  holdingUniqueIdMaxLength = 20,
  withIcon = true,
  needsCorrection = false,
  textSize = 'sm',
}: Props) => {
  const holdingId = holding?.id;
  const holdingUniqueId = holding?.certificate_number;

  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const { data: drawerData, isLoading } = useHoldingShow(
    {
      id: holdingId,
    },
    { queryConfig: { enabled: !!holdingId && drawerOpen } }
  );

  // if holding id is not present, it means that the user is not the owner and cannot display the holding details
  const holdingUniqueIdFallback = holdingId
    ? `Missing ${getUniqueHoldingIdName(holding as Holding, true)}`
    : null;

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isUniqueIdClickable) {
      e.stopPropagation();
      setDrawerOpen(true);
    }
  };

  const holdingUniqueIdShortened = (
    <Text
      size={textSize}
      onClick={isUniqueIdClickable ? handleClick : undefined}
      colorVariant="primary"
    >
      {shorten(holdingUniqueId, holdingUniqueIdMaxLength) ||
        holdingUniqueIdFallback}
    </Text>
  );

  if (!isUniqueIdClickable) {
    return holdingUniqueIdShortened;
  }

  return (
    <>
      {needsCorrection ? (
        <Tooltip
          title={
            'The company has indicated that the holding needs to be corrected.'
          }
        >
          <S.ErrorWrapper onClick={handleClick}>
            <S.IconWrapper>
              <Icon name={'warning'} color={'error'} size={'xs'} />
            </S.IconWrapper>
            {holdingUniqueId ? (
              holdingUniqueIdShortened
            ) : (
              <>Needs&nbsp;Correction</>
            )}
          </S.ErrorWrapper>
        </Tooltip>
      ) : (
        <Flex gap={theme.spacing.xs / 2} align="flex-start" justify="center">
          {withIcon && (
            <S.IconWrapper>
              <Icon name="eye" size="xs" color="info" />
            </S.IconWrapper>
          )}
          {holdingUniqueIdShortened}
        </Flex>
      )}
      <HoldingDetailDrawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        holding={drawerData}
        isLoading={isLoading}
      />
    </>
  );
};
