import React from 'react';

import {
  FormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { Select } from '@npm/core/ui/components/atoms/Select';
import { type AccountIndex, CbAccountType } from '@npm/data-access';
import { NPM_SETTLEMENT_NAME } from '@npm/features/auth/user/context';
import { type FormInstance } from 'antd';

import { type OptInFormValues } from '../../OptInEntitySelection/OptInEntitySelection.types';

import * as S from './EntitySelect.styles';

type Props = {
  eligibleEntities: AccountIndex;
  form: FormInstance<OptInFormValues>;
  isLoadingEntities?: boolean;
};

export const EntitySelect = ({
  eligibleEntities,
  form,
  isLoadingEntities,
}: Props) => {
  const handleChange = (option?: { value: number; label: string }) => {
    if (!option) {
      form.setFieldValue('selectedEntity', undefined);
      return;
    }
    form.setFieldValue('selectedEntity', option.value);
  };

  const npmSettlementEntityAccounts = eligibleEntities?.accounts?.filter(
    acc =>
      acc?.type?.code === CbAccountType.items.OrganizationAccount &&
      acc?.brokerage_firm?.name === NPM_SETTLEMENT_NAME
  );

  return (
    <FormItem name={'selectedEntity'} rules={[VALIDATION_RULES.required()]}>
      <Select
        allowClear
        onChange={handleChange}
        labelInValue={true}
        loading={isLoadingEntities}
      >
        {!isLoadingEntities &&
          npmSettlementEntityAccounts?.map(entity => (
            <S.SelectOption
              value={entity.id}
              key={entity.id}
              label={entity.name}
            >
              {entity.name}
            </S.SelectOption>
          ))}
      </Select>
    </FormItem>
  );
};
