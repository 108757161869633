import React, { useState } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Form } from '@npm/core/ui/components/atoms/Form';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { ProgressBar } from '@npm/core/ui/components/atoms/ProgressBar';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { CbAccreditationLevel } from '@npm/data-access';
import { useTheme } from 'styled-components';

import { SignatureFormContent } from '../../components/SignatureModal';

import { usePurchaserQuestionnaireForm } from './PurchaserQuestionnaireForm.hooks';
import { PurchaserQuestionnaireFormContent } from './PurchaserQuestionnaireFormContent';

const QUALIFIED_PURCHASER_FORM_ID = 'qualified-purchaser-form';

enum ModalSteps {
  QUESTION = 'qualified_purchaser_question',
  SIGNATURE = 'signature',
}

const purchaserModalSteps: ModalSteps[] = [
  ModalSteps.QUESTION,
  ModalSteps.SIGNATURE,
];

export type Props = {
  accreditationId: number;
  accountId: number;
  isOpen: boolean;
  closeModal: () => void;
};

export const QualifiedPurchaserModal: React.FC<Props> = ({
  closeModal,
  isOpen,
  accreditationId,
  accountId,
}) => {
  const [activeStep, setActiveStep] = useState(ModalSteps.QUESTION);

  const { isMobile } = useBreakpoints();
  const theme = useTheme();

  const { form, data, handleSubmit, isUpdating } =
    usePurchaserQuestionnaireForm(accreditationId, accountId, () => {
      closeModal();
      Notification.success({
        message: 'You have updated your Sophistication Level',
      });
    });

  const qualifiedPurchaserAnswer = Form.useWatch(
    CbAccreditationLevel.items.qualified_purchaser,
    form
  );

  return (
    <Modal
      size="md"
      title="Qualified Purchaser Questionnaire"
      open={isOpen}
      onCancel={closeModal}
      isFullScreen={isMobile}
      fullScreenProps={{
        isFooterFixed: true,
        noHeader: false,
        onClose: closeModal,
        icon: <Icon name="x-close" />,
        titleHeadingProps: {
          style: { marginBottom: 0 },
        },
      }}
      footer={
        <Flex
          key={activeStep}
          justify="space-between"
          gap={isMobile ? 'sm' : 'md'}
          style={isMobile ? { padding: theme.spacing.md } : undefined}
          direction={isMobile ? 'column-reverse' : 'row'}
        >
          {activeStep === ModalSteps.QUESTION ? (
            <>
              <Button
                variant="text"
                icon={<Icon name="x-close" />}
                onClick={closeModal}
              >
                {activeStep === ModalSteps.QUESTION
                  ? 'Back to Accreditation'
                  : 'Previous Question'}
              </Button>
              <Button
                onClick={() => setActiveStep(ModalSteps.SIGNATURE)}
                disabled={!qualifiedPurchaserAnswer}
                loading={isUpdating}
              >
                Submit & Sign electronically
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="text"
                icon={<Icon name="x-close" />}
                onClick={() => setActiveStep(ModalSteps.QUESTION)}
              >
                Previous Question
              </Button>
              <Button
                form={QUALIFIED_PURCHASER_FORM_ID}
                htmlType="submit"
                loading={isUpdating}
              >
                Sign off Accreditation Statement
              </Button>
            </>
          )}
        </Flex>
      }
    >
      <Form
        form={form}
        id={QUALIFIED_PURCHASER_FORM_ID}
        onFinish={handleSubmit}
      >
        <ProgressBar
          title={
            activeStep === ModalSteps.SIGNATURE
              ? 'Electronic Signature'
              : 'Describe your organization'
          }
          currentStep={purchaserModalSteps.indexOf(activeStep) + 1}
          totalSteps={purchaserModalSteps.length}
        />
        {activeStep === ModalSteps.QUESTION && (
          <PurchaserQuestionnaireFormContent
            qualifiedPurchaserCodebooks={data?.codebooks}
          />
        )}
        {activeStep === ModalSteps.SIGNATURE && (
          <SignatureFormContent variant="entity" />
        )}
      </Form>
    </Modal>
  );
};
