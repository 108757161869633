import React, { useMemo } from 'react';

import {
  Select,
  SELECT_PAGE_SIZE_BIG,
  useSelectAsync,
} from '@npm/core/ui/components/atoms/Select';
import { onPopupScroll } from '@npm/core/ui/components/atoms/Select/Select.utils';
import {
  type AccountForBrokerage,
  useAccountShow,
  useBrokerageFirmAccountIndexInfinite,
} from '@npm/data-access';
import { uniqBy } from 'lodash';

import { renderAccountOption, SelectedAccount } from '../components';

type Props = {
  brokerageFirmId: number;
  onSelect?: (val?: string) => void;
  onClear?: (val?: string) => void;
  onChange?: (val: string) => void;
  defaultAccount?: AccountForBrokerage;
  value?: string | number;
  disabled?: boolean;
  onItemChange?: (val: AccountForBrokerage) => void;
  onCreateAccount?: () => void;
};

export const BrokerageAccountSearch = ({
  brokerageFirmId,
  defaultAccount,
  onItemChange,
  onChange,
  value,
  onCreateAccount,
  ...restProps
}: Props) => {
  const [{ searchTerm }, selectAsyncProps] = useSelectAsync();

  const {
    data,
    isLoading,
    error,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useBrokerageFirmAccountIndexInfinite(
    {
      brokerageFirmId,
      ...(searchTerm ? { search: searchTerm } : {}),
      page: 1,
      size: SELECT_PAGE_SIZE_BIG,
    },
    { onError: Select.onError }
  );

  const { data: selectedAccount } = useAccountShow(
    {
      id: value?.toString(),
    },
    {
      queryConfig: {
        enabled: !!value,
      },
    }
  );

  const options = useMemo(() => {
    if (!data && !defaultAccount) return null;

    const mergedAccounts = data?.pages?.reduce((mergedArray, page) => {
      return mergedArray.concat(page.accounts);
    }, []);

    const accounts =
      defaultAccount && !searchTerm
        ? [defaultAccount, ...(mergedAccounts || [])]
        : mergedAccounts || [];

    return uniqBy(
      [
        ...[
          ...accounts,
          ...(selectedAccount && !searchTerm ? [selectedAccount] : []),
        ].map(account => ({
          label: (
            <SelectedAccount
              accountName={account.name}
              id={account.external_id}
              brokerageFirmName={account.brokerage_firm?.name}
            />
          ),
          value: account.id,
          account,
        })),
      ],
      'value'
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, defaultAccount, searchTerm, value, selectedAccount]);

  const getItem = (id: number) =>
    data.pages[0]?.accounts.find(v => v.id === id);

  return (
    <Select
      onPopupScroll={e =>
        onPopupScroll(e, hasNextPage && !isFetchingNextPage && fetchNextPage)
      }
      loading={isLoading}
      infiniteLoading={isFetchingNextPage}
      variant="search"
      placeholder="Search by ID or Name"
      error={error}
      onChange={v => {
        onChange?.(v);
        onItemChange?.(getItem(v));
      }}
      value={options?.length ? value : null}
      dropdownExtraProps={
        onCreateAccount
          ? {
              title: 'Create New Account',
              onClick: onCreateAccount,
              icon: 'external-link',
              placement: 'top',
            }
          : undefined
      }
      {...restProps}
      {...selectAsyncProps}
    >
      {options?.map(renderAccountOption)}
    </Select>
  );
};
