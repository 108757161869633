import { Redirect } from 'react-router';

import { ErrorSkeleton } from '@npm/core/ui/components/atoms/ErrorSkeleton';
import { LoginCallback as LoginCallbackBase } from '@okta/okta-react';

export const LoginCallback = () => {
  return (
    <LoginCallbackBase
      errorComponent={({ error }) => {
        if (
          error.message ===
          'Unable to retrieve OAuth redirect params from storage'
        ) {
          // If already logged in, the home page will redirect to a workstation.
          // If not logged in, the home page will redirect to the login page.
          return <Redirect to="/" />;
        }
        return (
          <ErrorSkeleton
            title="Authentication Error"
            subTitle={error.message}
            error={error}
            canContactSupport={false} // Can't create a ticket if authentication failed
          />
        );
      }}
    />
  );
};
