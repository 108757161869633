import React, { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { Spin } from 'antd';
import { useTheme } from 'styled-components';

import { eventBus } from '../../../utils/eventBus';
import { useHistory } from '../../molecules/Link';
import { Button } from '../Button';
import { Flex, Margin } from '../common';
import { Icon } from '../Icon';
import { Heading, Text } from '../Typography';

import * as S from './ErrorSkeleton.styles';
import {
  getDefaultSubtitle,
  getDefaultTitle,
  isAuthError,
  isAutoReloadEnabled,
  showStatusCode,
} from './ErrorSkeleton.utils';

export type ErrorObj = {
  message?: string;
  originalStatus?: number;
  status?: number;
  data?: {
    error_message?: string;
  };
  name?: string;
};

type Props = {
  error: ErrorObj;
  statusCode?: number;
  title?: string;
  subTitle?: string;
  resetErrorBoundary?: () => void;
  canContactSupport?: boolean;
  canGoBack?: boolean;
  dense?: boolean;
};

export const ErrorSkeleton = ({
  error,
  resetErrorBoundary,
  statusCode = error?.originalStatus ?? error?.status,
  title = getDefaultTitle(error),
  subTitle = getDefaultSubtitle(error),
  canContactSupport = true,
  canGoBack = true,
  dense,
}: Props) => {
  const theme = useTheme();
  const history = useHistory();

  const queryClient = useQueryClient();

  const handleRefresh = () => {
    queryClient.clear();
    resetErrorBoundary?.();
  };

  const handleGoBack = () => {
    if (history.length < 2) {
      history.replace('/');
    } else {
      history.goBack();
    }
    setTimeout(() => {
      handleRefresh();
    }, 1);
  };

  useEffect(() => {
    if (!isAutoReloadEnabled(error)) {
      return () => undefined;
    }

    const timer = setTimeout(() => {
      handleRefresh();
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <S.Layout $dense={dense}>
      <S.Content $dense={dense}>
        <S.Header $dense={dense}>
          {error?.status === 443 ? (
            <Margin bottom="lg">
              <Spin />
            </Margin>
          ) : (
            <Icon
              name="error-404"
              color={theme.color.general.typography.tertiary}
              size={dense ? theme.icon.size.md : theme.icon.size.lg}
            />
          )}
          <Heading variant={dense ? 'h3' : 'h1'}>{title}</Heading>
        </S.Header>
        <Text size="sm" colorVariant="secondary">
          {subTitle && `${subTitle}. `}
          {isAutoReloadEnabled(error)
            ? 'Automatic reload is going to happen in 5s.'
            : 'Please try again later.'}
          {dense ? ' ' : <br />}
          {canContactSupport && (
            <>
              {!dense && <br />}
              If the error persist, please{' '}
              <Text
                size="sm"
                onClick={() =>
                  eventBus.dispatch('REQUEST_SUPPORT', { key: 'page_error' })
                }
              >
                contact our support.
              </Text>
            </>
          )}
        </Text>
        {statusCode != null && statusCode > 299 && showStatusCode(error) && (
          <Text size="sm" colorVariant="secondary">
            HTTP ERROR {statusCode}
          </Text>
        )}
        <Flex gap={'md'}>
          {canGoBack && <Button onClick={handleGoBack}>Go Back</Button>}
          {isAuthError(error, statusCode) ? (
            <Button href="/logout">Log out</Button>
          ) : (
            <Button
              variant="outline"
              icon={<Icon name="refresh" />}
              onClick={handleRefresh}
            >
              Retry
            </Button>
          )}
        </Flex>
      </S.Content>
    </S.Layout>
  );
};
