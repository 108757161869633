import { Flex } from '@npm/core/ui/components/atoms/common';
import { CompanyLogo } from '@npm/core/ui/components/molecules/CompanyLogo';
import { CbAssetType, type IssuerEntityAggregate } from '@npm/data-access';

import { type OrderPlacementStep } from '../../../../../drawers/OrderEntryDrawer';

type Props = {
  step: OrderPlacementStep;
  assetType: string;
  issuerEntity: IssuerEntityAggregate;
};

export const SellIndicationDrawerTitle = ({
  step,
  assetType,
  issuerEntity,
}: Props) => {
  return (
    <Flex justify="space-between" style={{ width: '100%' }} align="center">
      <Flex>{getTitle(step, assetType)}</Flex>
      <CompanyLogo
        size={'md'}
        name={issuerEntity?.name}
        url={issuerEntity?.logo_url}
      />
    </Flex>
  );
};

const getTitle = (step: OrderPlacementStep, assetType: string) => {
  const assetDisplayName =
    assetType === CbAssetType.items.CommonStock ? 'Shares' : 'Options';

  switch (step) {
    case 'form':
      return `Sell your ${assetDisplayName}`;
    case 'confirmation':
    case 'affiliateDisclosure':
    case 'acknowledgement':
    case 'ordersCreation':
      return 'Preview Offer';
    default:
      return '';
  }
};
