import React, { type ComponentProps } from 'react';

import { Icon } from '../Icon';

import * as S from './Tooltip.styles';
import { Tooltip } from './Tooltip';

type Props = {
  title: ComponentProps<typeof Tooltip>['title'];
  iconProps?: Omit<ComponentProps<typeof Icon>, 'name'>;
  style?: React.CSSProperties;
};

export const TooltipInfoIcon = ({ title, iconProps, style }: Props) => (
  <Tooltip title={title}>
    <S.TooltipInfoIcon style={style}>
      <Icon name="info-circle" size="xs" {...iconProps} />
    </S.TooltipInfoIcon>
  </Tooltip>
);
