import { Tabs as AntTabs } from 'antd';
import styled, {
  createGlobalStyle,
  css,
  type DefaultTheme,
} from 'styled-components';

import {
  centerFlex,
  preserveHorizontalFocusBorder,
  preserveVerticalFocusBorder,
  useBreakpoint,
} from '../../../utils/style';
import { TotalRecordsBadge as TotalRecordsBadgeBase } from '../../molecules/TotalRecordsBadge';
import { getVariant as getButtonVariant } from '../Button/Button.styles';
import { SmallTextStyles } from '../Typography';

import { type TabsVariant } from './Tabs.types';

export const TAB_BTN_HEIGHT = 40;
export const QUATERNARY_TAB_BTN_HEIGHT = 56;

const MORE_TABS_BTN_WIDTH = 30;
const MORE_TABS_BTN_HEIGHT = 40;
const FOCUS_SHADOW_WIDTH = 2;

export const DropdownGlobalStyles = createGlobalStyle`
  .ant-tabs-dropdown-menu {
    background-color: ${({ theme }) => theme.color.general.layout.two};
    padding: ${({ theme }) => theme.spacing.xs}px 0;
    box-shadow: ${({ theme }) => theme.shadow.level1};
  }

  .ant-tabs-dropdown-menu-item {
    padding: ${({ theme }) =>
      `${theme.spacing.xs}px ${theme.spacing.lg / 2}px`};
    align-items: center;
    ${SmallTextStyles};
    color: ${({ theme }) => theme.color.info.typography.primary};

    &:hover {
      font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
      background-color: ${({ theme }) =>
        theme.color.info.backgrounds.secondaryHover};
      color: ${({ theme }) => theme.color.info.typography.primary};
    }
  }

  .ant-tabs-dropdown-menu-item-active {
    ${SmallTextStyles};
    background-color: ${({ theme }) => theme.color.general.layout.one};
    color: ${({ theme }) => theme.color.info.typography.primary};
  }
`;
export const Badge = styled.span`
  margin-left: ${({ theme }) => theme.spacing.sm}px;
  padding: 0 5px; // custom value to reflect Figma accurately
  border: 1px solid ${({ theme }) => theme.color.info.borders.primary};
  border-radius: ${({ theme }) => theme.borderRadius.sm}px;
  line-height: 18px; // custom value to reflect Figma accurately
  color: ${({ theme }) => theme.color.info.typography.primary};
`;

export const TotalRecordsBadge = styled(TotalRecordsBadgeBase)`
  margin-left: ${({ theme }) => theme.spacing.sm}px;
  padding: 0 5px; // custom value to reflect Figma accurately
` as typeof TotalRecordsBadgeBase;
// 'as' is hack to fix issue with Generic component and styled-components https://github.com/styled-components/styled-components/issues/1803

const getVariantStyles = (
  theme: DefaultTheme,
  variant: TabsVariant | undefined,
  isOneTab?: boolean
) => {
  switch (variant) {
    case 'quaternary':
      return css`
        .ant-tabs-top > .ant-tabs-nav {
          margin: 0 0 ${({ theme }) => theme.spacing.md}px;
        }

        .ant-tabs-nav-wrap {
          box-shadow: ${theme.shadow.level1};
          background: ${theme.color.general.layout.one};
          color: ${theme.color.general.typography.primary};
          border-radius: ${theme.borderRadius.md}px;
          padding: ${theme.spacing.sm}px;

          .ant-tabs-nav-list {
            width: 100%;

            .ant-tabs-tab {
              flex-grow: 1;

              .ant-tabs-tab-btn {
                width: 100%;
                justify-content: center;
                background-color: ${theme.color.info.backgrounds.primaryHover};
              }
            }

            .ant-tabs-tab:is(.ant-tabs-tab-active) {
              .ant-tabs-tab-btn {
                background-color: ${theme.color.general.layout.two};
                border-bottom: 2px solid ${theme.color.info.typography.primary};
              }
            }

            .ant-tabs-tab:not(.ant-tabs-tab-active, .ant-tabs-tab-disabled) {
              .ant-tabs-tab-btn:not(:hover) {
                background-color: unset;
                color: ${theme.color.general.typography.secondary};

                ${Badge} {
                  color: ${({ theme }) =>
                    theme.color.general.typography.secondary};
                }
              }
            }

            .ant-tabs-tab:is(.ant-tabs-tab-disabled) {
              .ant-tabs-tab-btn,
              .ant-tabs-tab-btn:hover {
                color: ${theme.color.general.typography.tertiary};
                background-color: unset;
                box-shadow: unset;
              }
            }
          }
        }
      `;
    case 'tertiary':
      return css`
        && .ant-tabs-nav {
          margin: 0;

          .ant-tabs-tab .ant-tabs-tab-btn {
            ${({ theme }) => useBreakpoint(theme).mobile` {
                font-size: ${theme.typography.size.xs}px;
                line-height: ${theme.typography.height.xs}px;
              }
            `}
          }
        }

        .ant-tabs-nav-wrap {
          min-width: 0;

          .ant-tabs-tab-btn {
            box-shadow: none !important;
          }

          .ant-tabs-tab-btn {
            padding: ${theme.spacing.sm}px !important;
            ${({ theme }) => useBreakpoint(theme).mobile` {
                padding: ${theme.spacing.lg / 4}px !important;
              }
            `}
          }

          .ant-tabs-nav-list {
            .ant-tabs-tab {
              // tertiary are not supposed to be used with MoreTabs dots
              // so we don't need far right margin
              &:nth-last-child(2) {
                margin-right: 0;
              }
            }
          }

          .ant-tabs-tab:is(:hover) {
            background-color: transparent !important;
          }

          .ant-tabs-tab:is(.ant-tabs-tab-active) {
            cursor: default;

            .ant-tabs-tab-btn {
              color: ${theme.color.general.typography.primary};
              background-color: transparent !important;
              border-radius: 0;
              border-color: transparent !important;
              border-bottom-width: 1px;
              border-bottom-style: solid;
              text-shadow: none;
            }

            ${!isOneTab &&
            css`
              .ant-tabs-tab-btn::after {
                content: '';
                position: absolute;
                bottom: 8px;
                left: 50%; /* Position the border at 50% from the left (centered) */
                transform: translateX(
                  -50%
                ); /* Center the border by moving it back 50% of its width */
                width: 35%;
                height: 1px;
                background-color: ${({ theme }) =>
                  theme.color.info.borders.primary} !important;
              }
            `}
          }

          .ant-tabs-tab:not(.ant-tabs-tab-active) {
            box-shadow: none !important;

            .ant-tabs-tab-btn:not(:hover) {
              color: ${theme.color.general.typography.secondary};
              background-color: transparent !important;
            }
          }
        }
      `;
    case 'secondary':
      return css`
        .ant-tabs-top > .ant-tabs-nav {
          margin: 0 0 ${({ theme }) => theme.spacing.md}px;
        }

        .ant-tabs-nav-wrap {
          box-shadow: ${theme.shadow.level1};
          background: ${theme.color.general.layout.one};
          color: ${theme.color.general.typography.primary};
          border-radius: ${theme.borderRadius.md}px;
          padding: ${theme.spacing.sm}px;

          .ant-tabs-nav-list {
            width: 100%;

            .ant-tabs-tab {
              flex-grow: 1;

              .ant-tabs-tab-btn {
                width: 100%;
                justify-content: center;
                background-color: ${theme.color.info.backgrounds.primaryHover};
              }
            }

            .ant-tabs-tab:is(.ant-tabs-tab-active) {
              .ant-tabs-tab-btn {
                background-color: ${theme.color.general.layout.zero};
              }
            }

            .ant-tabs-tab:not(.ant-tabs-tab-active, .ant-tabs-tab-disabled) {
              .ant-tabs-tab-btn:not(:hover) {
                background-color: unset;
                color: ${theme.color.general.typography.secondary};

                ${Badge} {
                  border-color: ${theme.color.general.typography.secondary};
                  color: ${({ theme }) =>
                    theme.color.general.typography.secondary};
                }
              }
            }

            .ant-tabs-tab:is(.ant-tabs-tab-disabled) {
              .ant-tabs-tab-btn,
              .ant-tabs-tab-btn:hover {
                color: ${theme.color.general.typography.tertiary};
                background-color: unset;
                box-shadow: unset;
              }
            }
          }
        }
      `;
    case 'primary':
    default:
      return css`
        .ant-tabs-top > .ant-tabs-nav {
          margin: 0 0 ${({ theme }) => theme.spacing.lg}px;
          ${({ theme }) => useBreakpoint(theme).mobile`
            margin: 0 0 ${({ theme }) => theme.spacing.md}px;
          `}
        }

        .ant-tabs-nav-wrap {
          // padding to not cut off border on Tab item focus state
          // negative margin so it doesn't interfere with layout
          padding: 2px 0 2px 2px;
          margin: -2px 0 -2px -2px;
        }
      `;
  }
};

export const TabsContainer = styled.div<{ $isMoreTabsVisible: boolean }>`
  position: relative;
  max-width: 100%;

  ${preserveHorizontalFocusBorder}
  ${preserveVerticalFocusBorder}
`;

export const StyledTabs = styled(AntTabs)<{
  variant?: TabsVariant;
  $isMoreTabsVisible?: boolean;
  $outlined?: boolean;
  $noMargin?: boolean;
  $isOneTab?: boolean;
}>`
  & > .ant-tabs-nav .ant-tabs-nav-operations {
    display: none;
    color: ${({ theme }) => theme.color.info.typography.primary};

    .ant-tabs-nav-more {
      ${centerFlex};
      background-color: ${({ theme }) => theme.color.general.layout.zero};
      color: ${({ theme }) => theme.color.info.typography.primary};
    }
  }

  &.ant-tabs-top > .ant-tabs-nav {
    &::before {
      border-bottom: none;
    }

    .ant-tabs-nav-wrap {
      ${({ theme, variant }) =>
        variant === 'secondary'
          ? `
              border: 1px solid
                ${theme.color.general.borders.primary};
              border-radius: ${theme.borderRadius.lg}px;
            `
          : ''};
      margin-right: ${({ $isMoreTabsVisible }) =>
        $isMoreTabsVisible ? MORE_TABS_BTN_WIDTH - FOCUS_SHADOW_WIDTH : 0}px;
    }

    .ant-tabs-nav-wrap::after {
      background: ${({ theme }) => theme.color.component.tab.wrapGradient};
      width: 10px;
      margin-top: 2px; // so the shadow won't overflow
      margin-bottom: 2px;
    }

    .ant-tabs-tab {
      padding: 0;

      & + .ant-tabs-tab {
        margin-left: ${({ theme }) => theme.spacing.sm}px;
      }

      // last-child: there is always some .ant-tab-ink-bar after last .ant-tabs-tab
      &:nth-last-child(2) {
        margin-right: ${({ theme }) => theme.spacing.sm}px;
      }

      .ant-tabs-tab-btn {
        display: flex;
        align-items: center;
        ${({ theme }) => getButtonVariant('outline', 'info', theme)}
        height:  ${({ variant }) =>
          variant === 'quaternary'
            ? QUATERNARY_TAB_BTN_HEIGHT
            : TAB_BTN_HEIGHT}px;
        border-radius: ${({ theme, variant }) =>
          variant === 'quaternary'
            ? `${theme.borderRadius.md}px ${theme.borderRadius.md}px 0 0`
            : theme.borderRadius.md}px;
        padding: ${({ theme }) =>
          `${theme.spacing.sm}px ${theme.spacing.md}px`};
        ${SmallTextStyles};
        color: ${({ theme }) => theme.color.info.typography.primary};
        background-color: ${({ theme }) => theme.color.general.layout.two};
        border: 1px solid transparent;
        box-shadow: unset;

        &:not(.ant-btn-disabled):focus {
          background-color: ${({ theme }) => theme.color.general.layout.two};
        }

        ${({ theme }) => useBreakpoint(theme).mobile`
          font-size: ${theme.typography.size.sm}px;
          line-height: ${theme.typography.height.sm}px;
        `} &:hover {
          border-color: transparent;
          background-color: ${({ theme }) => theme.color.general.layout.one};
        }
      }

      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          background-color: ${({ theme }) => theme.color.general.layout.one};
          border-color: ${({ theme, variant }) =>
            variant === 'quaternary'
              ? undefined
              : theme.color.info.borders.primary};
        }
      }

      ${({ theme }) => useBreakpoint(theme).mobile`
        font-size: ${theme.typography.size.xs}px;
      `}
    }

    .ant-tabs-ink-bar {
      display: none;
    }
  }

  ${({ theme, variant, $isOneTab }) =>
    getVariantStyles(theme, variant, $isOneTab)}
  ${({ $outlined }) => $outlined && outlinedCss}
  ${({ $noMargin }) => $noMargin && noMarginCss}
`;

export const MoreTabsContainer = styled.div<{ $isDarkMode?: boolean }>`
  position: absolute;
  right: 0;
  top: 0;
  width: ${MORE_TABS_BTN_WIDTH}px;
  height: ${MORE_TABS_BTN_HEIGHT + FOCUS_SHADOW_WIDTH * 2}px;
  background-color: ${({ theme }) => theme.color.general.layout.zero};

  && > .ant-btn {
    margin: ${FOCUS_SHADOW_WIDTH}px 0;
    width: ${MORE_TABS_BTN_WIDTH}px;
    height: ${MORE_TABS_BTN_HEIGHT}px;
    background-color: inherit;
    border-radius: 0;
    border-left: 1px solid
      ${({ theme, $isDarkMode }) =>
        // design exception
        $isDarkMode
          ? theme.color.general.borders.primary
          : theme.color.general.borders.secondary};

    > svg {
      width: 24px;
      height: 24px;
    }
  }
`;

const outlinedCss = css`
  ${({ theme }) => css`
    && .ant-tabs-nav {
      padding-left: 6px; // making 16px total from the border to the first letter
      border-radius: ${theme.borderRadius.lg}px;
      border: 1px solid ${theme.color.general.borders.primary};
      background: ${theme.color.general.layout.one};
      margin-bottom: ${theme.spacing.sm}px;

      ${() => useBreakpoint(theme).mobile`
        padding-left: 0;
      `}
    }
  `}
`;

const noMarginCss = css`
  && .ant-tabs-nav {
    margin: 0;
  }
`;
