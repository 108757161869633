import React from 'react';

import {
  formatAsCompactNumber,
  formatPrice,
  getCurrencySymbol,
  type NumberFormatOptions,
} from '../../../../utils/formatters';
import { TextCurrency } from '../TextCurrency';

import { useTextPriceContext } from './Price.context';
import { Text } from './Text';
import { EMPTY_VALUE } from './Text.constants';
import { type PriceOrQuantityValue, type Props } from './Text.types';

const normalizePriceRange = (
  a?: string | number | null,
  b?: string | number | null
) => {
  if (a == null || b == null || a === b) return a ?? b;
  return [a, b];
};

type PriceProps = Omit<Props, 'children' | 'valueLength'> & {
  value: PriceOrQuantityValue;
  currency?: string | null;
  currencyAsSymbol?: boolean;
  currencyColor?: Props['color'];
  currencyColorVariant?: Props['colorVariant'];
  compact?: boolean;
  showZeroAsEmpty?: boolean;
  zeroColorVariant?: Props['colorVariant'];
  formatOptions?: NumberFormatOptions;
};

export const Price = ({
  value: rawValue,
  currency = 'USD',
  currencyColor = 'general',
  currencyColorVariant = 'secondary',
  currencyAsSymbol: currencyAsSymbolProp,
  compact,
  showZeroAsEmpty = false,
  zeroColorVariant = 'primary',
  formatOptions,
  ...props
}: PriceProps) => {
  const normalizedValue = Array.isArray(rawValue)
    ? normalizePriceRange(...rawValue)
    : rawValue;

  const currencyAsSymbolContext = useTextPriceContext()?.currencyAsSymbol;
  const currencyAsSymbol =
    currencyAsSymbolProp === true || currencyAsSymbolContext === true;
  const currencySymbol =
    currencyAsSymbol && currency ? getCurrencySymbol(currency) : undefined;

  const formatter = (singleValue: string | number) => {
    if (compact) {
      return formatAsCompactNumber(singleValue, formatOptions);
    } else {
      return formatPrice(singleValue, undefined, formatOptions);
    }
  };

  let formattedValue: string | undefined = undefined;
  if (normalizedValue != null) {
    formattedValue = Array.isArray(normalizedValue)
      ? normalizedValue.map(formatter).join(' - ')
      : formatter(normalizedValue);
  }

  const isZero = rawValue === 0;

  return (
    <Text
      as={'span'}
      size="sm"
      colorVariant={isZero ? zeroColorVariant : 'primary'}
      valueLength={(formattedValue as string | undefined)?.length}
      {...props}
    >
      {normalizedValue != null ? (
        <>
          {currencySymbol}
          {showZeroAsEmpty && isZero ? EMPTY_VALUE : formattedValue}
          {!currencySymbol && currency && (
            <TextCurrency
              color={currencyColor}
              colorVariant={currencyColorVariant}
            >
              {currency}
            </TextCurrency>
          )}
        </>
      ) : (
        EMPTY_VALUE
      )}
    </Text>
  );
};
