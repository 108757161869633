export const FILTER_QUERY_KEY = {
  accounts: 'accounts',
  administrators: 'administrators',
  agreements: 'agreements',
  capTable: 'capTable',
  clientOrders: 'clientOrders',
  companySelect: 'companySelect',
  dataRooms: 'dataRooms',
  holdings: 'holdings',
  marketFeed: 'marketFeed',
  marketDepth: 'marketDepth',
  matches: 'matches',
  mutualFunds: 'mutualFunds',
  myOrders: 'myOrders',
  negotiations: 'negotiations',
  persons: 'persons',
  programs: 'programs',
  oboAccounts: 'oboAccounts',
  orders: 'orders',
  submissions: 'submissions',
  watchlist: 'watchlist',
  entityInvestorPortfolio: 'entityInvestorPortfolio',
  companiesOveriview: 'companiesOverview',
  advisorReferrals: 'advisorReferrals',
  flowScreenerCompanies: 'flowScreenerCompanies',
} as const;
