//create react context with hook for simplier usage
import { createContext, useContext, useEffect, useMemo } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useLocation } from 'react-router';

import { FullScreenLoading } from '@npm/core/ui/components/molecules/FullScreenLoading';
import { useCurrentRoute } from '@npm/core/ui/hooks/useCurrentRoute';
import { usePreservableQueryParamsStore } from '@npm/core/ui/services/paths';
import { getApiErrorCode, useWorkstationIndex } from '@npm/data-access';
import { DatadogService } from '@npm/utils';
import {
  BooleanParam,
  NumberParam,
  useQueryParam,
  useQueryParams,
} from 'use-query-params';

import {
  InitialLoadingPart,
  useInitialLoadingStore,
} from '../../../../app/initialLoading';
import { getWorkstationFromPathname, useUserContextStore } from '../../context';
import { type UserRoleContextType } from '../userRole.types';

import { useInitialRoleHandler } from './hooks/useInitialRoleHandler';
import { useLoadInvestorAccounts } from './hooks/useLoadInvestorAccounts';
import { useLoadOboData } from './hooks/useLoadOboData';
import { useLoadRole } from './hooks/useLoadRole';
import { useRoleNotification } from './hooks/useRoleNotification';

import {
  extractContextVariables,
  setAxiosRoleHeaders,
} from './userRole.helpers';

export const UserRoleContext = createContext<UserRoleContextType | null>(null);

type Props = {
  children: React.ReactNode;
};

const isErrorPath = (location: string) => {
  const regex = /^\/([4-5]\d{2})/g;
  return regex.test(location);
};

export const UserRoleProvider = ({ children }: Props) => {
  const finishLoadingPartOfApp = useInitialLoadingStore(
    store => store.finishLoadingPart
  );
  const isNewUser = useUserContextStore(store => store.isNewUser);
  const currentRoute = useCurrentRoute();
  const { pathname } = useLocation();
  const handleError = useErrorHandler();

  const workstationFromPathname = getWorkstationFromPathname(
    currentRoute?.route?.path || '/'
  );

  const setPreservableQueryParams = usePreservableQueryParamsStore(
    s => s.setPreservableQueryParams
  );

  const [qs, setQs] = useQueryParams({
    roleId: NumberParam,
    allAccounts: BooleanParam,
    subjectId: NumberParam,
    oboAccountId: NumberParam,
    oboUserId: NumberParam,
    oboPersonId: NumberParam,
  });

  let queryParams = { ...qs };

  if (currentRoute?.route?.roles?.overrideFn) {
    const overrideFn = currentRoute?.route?.roles?.overrideFn;
    const overrideResult = overrideFn(queryParams);
    if (overrideResult.wasOverridden) {
      queryParams = overrideResult.newRole;
      setImmediate(() => {
        setQs(overrideResult.newRole, 'replaceIn');
      });
    }
  }

  const [permissionsCheckOff] = useQueryParam(
    'permissionsCheckOff',
    BooleanParam
  );

  const needRole =
    !isNewUser &&
    currentRoute?.route?.needLogin !== false &&
    currentRoute?.route?.needUser !== false &&
    !isErrorPath(pathname);

  // The role related values (default once) are passed to prevent this hook from being called everytime the role changes as the result should be always the same
  const { data: workstationsData, isLoading: workstationDataLoading } =
    useWorkstationIndex(
      {},
      {
        roles: {
          oboUserId: undefined,
          roleId: undefined,
          oboAccountId: undefined,
          workstation: undefined,
          acrossAccount: undefined,
        },
        queryConfig: {
          enabled: needRole,
        },
        onError: e => {
          // ignoring 441 because it's handled in initTOSHandling
          if (getApiErrorCode(e) !== 441) {
            handleError(e);
          } else {
            finishLoadingPartOfApp(InitialLoadingPart.UserRoles);
          }
        },
      }
    );

  const { isLoading: areInvWsUserRolesLoading } = useLoadInvestorAccounts(
    workstationsData,
    workstationDataLoading
  );

  useInitialRoleHandler(needRole, workstationsData?.workstations, queryParams);

  const { isLoading: isOboLoading, obo } = useLoadOboData();
  const { isLoading: isRoleLoading, role } = useLoadRole(
    queryParams?.roleId,
    queryParams.oboAccountId && queryParams.oboUserId
      ? 'brokerage'
      : workstationFromPathname,
    needRole,
    { on403: currentRoute?.route?.roles?.on403 }
  );

  const isLoading =
    (needRole &&
      (workstationDataLoading || // ws data loading
        areInvWsUserRolesLoading || // investor accounts loading
        (!queryParams?.roleId && !queryParams?.allAccounts) || // no role is defined
        (!!queryParams.roleId && !!role && role.id !== queryParams.roleId))) || // role is defined, but not loaded yet
    isOboLoading ||
    isRoleLoading;

  const roleContext = useMemo<UserRoleContextType | null>(() => {
    if (isLoading || !needRole || !workstationsData) {
      return null;
    }

    const context = (() => {
      if (!workstationFromPathname) return null;

      const isObo = queryParams.oboAccountId && queryParams.oboUserId;
      if (workstationFromPathname === 'issuer') {
        return {
          workstationType: 'issuer',
          workstation: workstationsData.workstations?.find(
            w => w.type?.code === workstationFromPathname
          ),
          subRole: role,
        } satisfies UserRoleContextType;
      }

      if (workstationFromPathname === 'advisor') {
        return {
          workstationType: 'advisor',
          workstation: workstationsData.workstations?.find(
            w => w.type?.code === workstationFromPathname
          ),
          subRole: role,
        } satisfies UserRoleContextType;
      }

      if (workstationFromPathname === 'brokerage' || isObo) {
        return {
          workstationType: 'brokerage',
          workstation: workstationsData.workstations?.find(
            w =>
              w.type?.code === 'brokerage' &&
              w.user_role?.id === queryParams.roleId
          ),
          subRole: isObo
            ? {
                type: 'obo',
                id: queryParams.roleId,
                ...role,
                ...obo,
              }
            : {
                type: 'regular',
                id: queryParams.roleId,
                ...role,
              },
        } satisfies UserRoleContextType;
      }

      return {
        workstationType: 'investor',
        workstation: workstationsData.workstations?.find(
          w => w.type?.code === workstationFromPathname
        ),
        subRole:
          queryParams.allAccounts === true || !queryParams.roleId
            ? { type: 'all-accounts' }
            : {
                type: 'account-centric',
                id: queryParams.roleId,
                ...role,
              },
      } satisfies UserRoleContextType;
    })();

    // to have http headers correctly set from the first moment
    if (context) {
      setAxiosRoleHeaders(context);
      DatadogService.setRoleType(context.workstationType);
    }
    return context;
    // this list is not exhaustive, but it's reacting only to the changes that are needed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    needRole,
    isLoading,
    workstationsData,
    queryParams.roleId,
    queryParams.allAccounts,
    queryParams.oboAccountId,
    queryParams.oboUserId,
    queryParams.oboPersonId,
  ]);

  useEffect(() => {
    // preservable query params can be changed in useEffect, as they will just cause 1 re-render
    // can be changed in a way, that it will be stored in context as well or taken from URL
    const {
      roleId,
      oboAccountId,
      oboUserId,
      allAccounts,
      oboPersonId,
      subjectId,
    } = extractContextVariables(roleContext);

    setPreservableQueryParams('role', {
      permissionsCheckOff,
      ...(roleContext
        ? {
            oboAccountId,
            oboUserId,
            roleId,
            allAccounts: allAccounts ? 1 : 0,
            oboPersonId,
            subjectId,
          }
        : {}),
    });
  }, [roleContext, permissionsCheckOff]);

  useRoleNotification(needRole, isLoading, roleContext);

  const roleKey = useMemo(() => {
    const { roleId, oboAccountId, oboUserId, allAccounts, oboPersonId } =
      extractContextVariables(roleContext);
    return roleContext
      ? JSON.stringify({
          roleId,
          oboAccountId,
          oboUserId,
          allAccounts,
          oboPersonId,
        })
      : null;
  }, [roleContext]);

  if (isLoading || (needRole && !roleContext)) {
    return <FullScreenLoading loadingTitle={'Loading user role'} />;
  }

  return (
    <UserRoleContext.Provider value={roleContext} key={roleKey}>
      {children}
    </UserRoleContext.Provider>
  );
};

export const withUserRoleContext = <T,>(Component: React.ComponentType) => {
  return (props: T) => (
    <UserRoleProvider>
      <Component {...props} />
    </UserRoleProvider>
  );
};

export const useUserRole = () => {
  const context = useContext(UserRoleContext);
  if (context === undefined) {
    throw new Error('useUserRole must be used within a UserRoleProvider');
  }
  return context;
};
