import { Flex } from '@npm/core/ui/components/atoms/common';
import { CbLabel } from '@npm/core/ui/components/atoms/Label';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import {
  type ExtendedColumnType,
  TABLE_ACTIONS_COLUMN_WIDTH,
  TABLE_BREAKPOINTS,
} from '@npm/core/ui/components/molecules/Table';
import { type TableVerticalRowType } from '@npm/core/ui/components/molecules/TableVertical';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import {
  CbOrderBuySell,
  CbOrderEntryType,
  type SecondmarketSubmissionForClientOrders,
} from '@npm/data-access';

import { type OpenRenewOrderModal } from '../../../client-orders/expiringOrders/RenewOrderModal/RenewOrderModal.types';
import { OrderStatusLabel } from '../../../components';
import { PriceBlock } from '../../../components/PriceBlock';
import { getCalculatedValues } from '../../SellIndication/EnterSellIndication/EnterSellIndication.utils';

import { getHoldingDisplayValue } from './InvestorOrdersTable/InvestorOrdersTable.utils';

import { InvestorOrdersDropdown } from './InvestorOrdersDropdown';
import type { HoldingOrderActions } from './InvestorOrdersList.types';

export type OrdersActionsConfig = {
  showFees?: boolean;
  isSmallerView?: boolean;
  onRenewOrderClick: OpenRenewOrderModal;
} & HoldingOrderActions;

type OrdersConfig = (ExtendedColumnType<SecondmarketSubmissionForClientOrders> &
  TableVerticalRowType<SecondmarketSubmissionForClientOrders>)[];

export const useOrdersConfig = ({
  onHoldingDetailClick,
  onHoldingEditClick,
  onOrderCancelClick,
  onOrderDetailClick,
  onOrderEditClick,
  onRenewOrderClick,
  showFees,
  isSmallerView,
}: OrdersActionsConfig): OrdersConfig => {
  const { isMobile } = useBreakpoints();

  return [
    {
      key: 'order_id',
      title: 'Order ID',
      render: ({ items }) => (
        <Flex align="center">
          <Text size="sm" colorVariant="primary">
            {items?.[0]?.id}
          </Text>
        </Flex>
      ),
      hidden: isMobile,
      width: 90,
    },
    {
      key: 'certificate_number',
      title: 'Holding',
      render: submission =>
        getHoldingDisplayValue(
          submission,
          onHoldingDetailClick,
          onHoldingEditClick
        ),
      responsive: TABLE_BREAKPOINTS.DESKTOP_XL,
      width: '20%',
    },

    {
      key: 'quantity',
      title: 'PPS × Quantity = Estimated Gross Proceeds',
      render: submission => (
        <PriceBlock
          quantity={submission?.items?.[0]?.quantity}
          price={submission?.items?.[0]?.price}
          isBuy={submission?.side?.code === CbOrderBuySell.items.buy}
          onAddClick={() => onOrderEditClick?.(submission)}
          compact
          style={{ width: '95%' }}
        />
      ),
      hidden: isMobile,
    },
    {
      title: 'Execution Fee',
      key: 'execution_fee',
      render: () => <Text.Quantity value={1} unit="%" />,
      responsive: TABLE_BREAKPOINTS.NONE,
      hidden: !showFees,
    },
    {
      key: 'estimated_net_proceeds',
      title: 'Estimated Net Proceeds',
      render: ({ items }) => {
        const { estimatedNetProceeds } = getCalculatedValues(
          items?.[0]?.quantity,
          items?.[0]?.price
        );
        return (
          <Text.Price
            value={estimatedNetProceeds}
            formatOptions={{
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }}
          />
        );
      },
      ...(isSmallerView
        ? { responsive: TABLE_BREAKPOINTS.NONE }
        : { responsive: TABLE_BREAKPOINTS.DESKTOP_XL }),
    },
    {
      key: 'order_type',
      title: 'Order Type',
      render: ({ order_type }) =>
        CbOrderEntryType.getLabel(CbLabel, order_type),
      hidden: isMobile,
      responsive: TABLE_BREAKPOINTS.TABLET,
    },
    {
      key: 'order_status',
      title: 'Order Status',
      align: 'right',
      render: ({ items }) => (
        <OrderStatusLabel state={items?.[0]?.state} variant="individual" />
      ),
      responsive: TABLE_BREAKPOINTS.DESKTOP,
      hidden: isMobile,
    },
    {
      key: 'action_buttons',
      width: TABLE_ACTIONS_COLUMN_WIDTH,
      render: submission => (
        <InvestorOrdersDropdown
          submission={submission}
          {...{
            onOrderCancelClick,
            onOrderDetailClick,
            onOrderEditClick,
            onRenewOrderClick,
          }}
        />
      ),

      hidden:
        !(onOrderDetailClick && onOrderEditClick && onOrderCancelClick) ||
        isMobile,
    },
  ];
};
