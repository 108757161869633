import { useBreakpoint } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  ${({ theme }) => useBreakpoint(theme).smTablet`
    flex-direction: column-reverse;
    padding: ${theme.spacing.md}px;
  `}
`;
