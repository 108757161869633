import React, { useEffect, useMemo } from 'react';

import { useSelectAsync } from '@npm/core/ui/components/atoms/Select';
import { onPopupScroll } from '@npm/core/ui/components/atoms/Select/Select.utils';
import { CypressDataIds } from '@npm/core/ui/constants';
import { type Holding, type SecondmarketOrderItemShow } from '@npm/data-access';
import { uniqBy } from 'lodash';

import { type OboDefinition } from '../../../auth/user/role/userRole.types';

import * as S from './HoldingSelect.styles';
import { renderHoldingOption } from './HoldingOption.utils';
import { useHoldingSelectData } from './HoldingSelect.hooks';
import { SelectedHoldingOption } from './SelectedHoldingOption';

type Props = {
  secondmarket: boolean | undefined;
  includeSpvs?: boolean;
  includePortfolioHoldings?: boolean;
  assetType?: string[];
  issuerEntityId: number | undefined;
  accountId: number | undefined;
  labelBasePropName?: 'code' | 'name';
  onSelect?: (val?: string) => void;
  onClear?: (val?: string) => void;
  onChange?: (val: string) => void;
  value?: string;
  onItemSelected?: (item: Holding) => void;
  onAddHoldingClick?: () => void;
  order?: SecondmarketOrderItemShow;
  filterOptions?: (value: Holding, index: number, array: Holding[]) => boolean;
  disabled?: boolean;
  oboOverride?: OboDefinition;
  showStatus?: boolean;
  valueField?: 'id' | 'npm_guid';
};

export const HoldingSelect = ({
  onChange,
  onItemSelected,
  onAddHoldingClick,
  issuerEntityId,
  accountId,
  labelBasePropName = 'code',
  value,
  order,
  filterOptions,
  secondmarket,
  includeSpvs,
  includePortfolioHoldings,
  assetType,
  oboOverride,
  showStatus,
  valueField = 'id',
  ...rest
}: Props) => {
  const [{ searchTerm }, selectAsyncProps] = useSelectAsync();

  const {
    availableHoldings,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    error,
  } = useHoldingSelectData({
    accountId,
    issuerEntityId,
    order,
    search: searchTerm,
    filterOptions,
    secondmarket,
    includeSpvs,
    includePortfolioHoldings,
    assetType,
    oboOverride,
  });

  const options = useMemo(() => {
    if (!availableHoldings?.length) return null;

    const selectedHolding = availableHoldings.find(holding => {
      const holdingValue = holding[valueField];

      if (Array.isArray(value)) {
        return value.includes(holdingValue);
      }
      return value === holdingValue;
    });

    return uniqBy(
      [
        ...[
          ...availableHoldings,
          ...(selectedHolding ? [selectedHolding] : []),
        ].map(holding => ({
          label: (
            <SelectedHoldingOption
              holding={holding}
              labelBasePropName={labelBasePropName}
            />
          ),
          value: holding[valueField],
          holding,
        })),
      ],
      'value'
    );
  }, [availableHoldings, labelBasePropName, value, valueField]);

  // we use effect so onItemSelected is called also if value changed programmatically using form.setFieldsValue
  useEffect(() => {
    if (value != null && options) {
      const selectedOption = options.find(option => option.value === value);
      if (selectedOption) {
        onItemSelected?.(selectedOption.holding);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, options]);

  return (
    <S.Select
      loading={isLoading}
      infiniteLoading={isFetchingNextPage}
      onPopupScroll={e =>
        onPopupScroll(e, hasNextPage && !isFetchingNextPage && fetchNextPage)
      }
      onChange={v => {
        onChange?.(v);
      }}
      notFoundContent="There are no holdings available"
      dropdownExtraProps={{
        title: 'Add New Holding',
        onClick: onAddHoldingClick,
        icon: 'external-link',
        placement: 'top',
        'data-cy': CypressDataIds.Holdings.Search.AddNewHolding,
      }}
      error={error}
      value={options?.length ? value : null}
      data-cy={CypressDataIds.Holdings.Search.Name}
      {...rest}
      {...selectAsyncProps}
    >
      {options?.map(o => renderHoldingOption(o, labelBasePropName, showStatus))}
    </S.Select>
  );
};
