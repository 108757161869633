import React, { type ComponentProps, useRef, useState } from 'react';

import { type FormInstance } from 'antd';
import { type FormProps } from 'antd/es/form/Form';
import { isEmpty, isNil, isNumber, omitBy } from 'lodash';
import { type Store } from 'rc-field-form/lib/interface';

import { useResizeEffect } from '../../../hooks/useResizeEffect';
import { Form } from '../../atoms/Form';
import { Wizard } from '../Wizard';

import { QuestionCard } from './QuestionCard/QuestionCard';

import * as S from './AutoScrollingForm.styles';

type Props<T> = {
  questions: ({ name: string; gap?: number; subTitle?: string } & Pick<
    ComponentProps<typeof QuestionCard>,
    'title' | 'children'
  >)[];
  form: FormInstance<T>;
  initialValues?: Store;
  scrollEnabled?: boolean;
  scrollableParentId?: string;
  extraTopPadding?: number;
  validateTrigger?: FormProps['validateTrigger'];
  onValuesChange?: FormProps['onValuesChange'];
  questionMinHeight?: number | string;
  testId?: string;
};

const getScrollableParent = (
  ref: React.RefObject<HTMLDivElement>,
  id: string
) => {
  if (!ref.current) {
    return null;
  }

  let element = ref.current.parentElement;
  while (element) {
    if (element.id === id) {
      return element;
    }

    element = element.parentElement;
  }

  return null;
};

export const AutoScrollingForm = <T,>({
  form,
  questions,
  scrollEnabled = false,
  initialValues,
  testId,
  scrollableParentId = Wizard.WIZARD_CONTENT_ID,
  extraTopPadding = 0,
  validateTrigger,
  onValuesChange,
  questionMinHeight,
}: Props<T>) => {
  const formRef = useRef<HTMLDivElement>(null);

  const questionRefs = useRef<HTMLDivElement[]>([]);
  const [height, setHeight] = useState<number>();
  const [answeredCount, setAnsweredCount] = useState(0);

  const addToQuestionRefs = (question: HTMLDivElement) => {
    if (question && !questionRefs.current.includes(question)) {
      questionRefs.current.push(question);
    }
  };

  useResizeEffect(() => {
    if (height) return;

    setHeight(getScrollableParent(formRef, scrollableParentId)?.clientHeight);
  }, [height]);

  const handleChange = questionIndex => {
    if (
      formRef.current &&
      formRef.current.childElementCount - 1 > questionIndex
    ) {
      const scrollableParent =
        getScrollableParent(formRef, scrollableParentId) || formRef.current;

      let scrollTop = 0;
      const childCount = formRef.current.childElementCount;
      for (let i = 0; i < childCount; i++) {
        if (i === questionIndex + 1) {
          break;
        }
        scrollTop +=
          formRef.current.children[i]?.getBoundingClientRect().height ?? 0;
      }

      setTimeout(() => {
        scrollableParent.scrollTo({
          top: scrollTop + extraTopPadding,
          behavior: 'smooth',
        });
      }, 200);
    }
  };

  const handleValuesChange = async (changedVals, vals) => {
    const answeredQuestionsCount = Object.keys(
      omitBy(vals, val => isNil(val) || (isEmpty(val) && !isNumber(val)))
    ).length;

    // calculate initial height
    let height =
      Math.max(
        getScrollableParent(formRef, scrollableParentId)?.clientHeight ?? 0,
        questionRefs.current[0]?.clientHeight ?? 0
      ) + extraTopPadding;

    for (let i = 0; i < answeredQuestionsCount + 1; i++) {
      const currentQuestionHeight = questionRefs.current[i]?.clientHeight;
      if (!currentQuestionHeight) continue;

      // skip for 0, as we already added inital height
      if (i !== 0) height += currentQuestionHeight;
    }

    const allQuestionsHeight = questionRefs.current.reduce(
      (acc, ref) => acc + ref.clientHeight,
      0
    );

    setAnsweredCount(answeredQuestionsCount);

    // don't overscroll last question
    setHeight(Math.min(height, allQuestionsHeight + extraTopPadding));

    onValuesChange?.(changedVals, vals);
  };

  const calculateQuestionMinHeight = (index: number) => {
    if (questionMinHeight != null) return questionMinHeight;
    if (index >= questions.length) return 0;
    const scrollableParent = getScrollableParent(formRef, scrollableParentId);
    if (!scrollableParent) return 0;
    return scrollableParent.clientHeight;
  };

  return (
    <S.ScrollWrapper
      $height={scrollEnabled || height == null ? 'auto' : height}
    >
      <S.ContentWrapper>
        <Form
          form={form}
          onValuesChange={handleValuesChange}
          initialValues={initialValues}
          data-cy={testId}
          validateTrigger={validateTrigger}
        >
          <div ref={formRef}>
            {questions.map(
              ({ title, name, children, subTitle, ...rest }, index) => (
                <QuestionCard
                  key={name}
                  isDisabled={scrollEnabled ? false : index > answeredCount}
                  title={title}
                  number={{ index, total: questions.length, show: true }}
                  form={form}
                  subTitle={subTitle}
                  {...rest}
                  onQuestionAnswered={() => handleChange(index)}
                  addToRefsArray={addToQuestionRefs}
                  minHeight={calculateQuestionMinHeight(index)}
                >
                  {children}
                </QuestionCard>
              )
            )}
          </div>
        </Form>
      </S.ContentWrapper>
    </S.ScrollWrapper>
  );
};
