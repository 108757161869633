import { Card as CardBase } from '@npm/core/ui/components/molecules/Card';
import { useBreakpoint } from '@npm/core/ui/utils/style';
import styled, { type DefaultTheme } from 'styled-components';

import { type StatusProps } from './ProgramCard.types';

export { DataPair } from '@npm/core/ui/components/atoms/common';

const getVariant = (variant: StatusProps['status'], theme: DefaultTheme) => {
  return {
    live: `
      background: ${theme.color.success.backgrounds.secondaryDefault};
      color: ${theme.color.success.typography.primary};
    `,
    preview: `
      background: ${theme.color.info.backgrounds.secondaryDefault};
      color: ${theme.color.info.typography.primary};
    `,
    review: `
      background: ${theme.color.general.layout.zero};
      color: ${theme.color.general.typography.secondary};
    `,
  }[variant];
};

export const Card = styled(CardBase)`
  .card-header {
    padding: 0;
  }

  .ant-skeleton-paragraph {
    margin-bottom: 0;
  }
`;

export const Header = styled.div`
  background: ${({ theme }) => theme.color.general.layout.two};
  padding: ${({ theme }) => `${theme.spacing.lg / 2}px ${theme.spacing.md}px`};
  display: flex;
  align-items: center;

  ${({ theme }) => useBreakpoint(theme).tablet`
    padding: ${theme.spacing.lg / 2}px ${theme.spacing.sm}px;
  `}
  & > * + * {
    margin-left: ${({ theme }) => theme.spacing.sm}px;
  }
`;

export const Status = styled.div<Partial<StatusProps>>`
  ${({ status = 'preview', theme }) => getVariant(status, theme)};
  padding: ${({ theme }) => `${theme.spacing.lg / 4}px ${theme.spacing.md}px`};
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > * {
    color: inherit;
    background: inherit;
  }

  & > * + * {
    margin-left: ${({ theme }) => theme.spacing.sm}px;
  }

  ${({ theme }) => useBreakpoint(theme).tablet`
    padding: ${theme.spacing.lg / 4}px ${theme.spacing.sm}px;
  `}
`;

export const Content = styled.div`
  padding: ${({ theme }) =>
    `${theme.spacing.sm}px ${theme.spacing.md}px ${theme.spacing.md}px`};
  display: flex;
  flex-direction: column;
  flex: 1;

  ${({ theme }) => useBreakpoint(theme).tablet`
    padding: ${theme.spacing.sm}px ${theme.spacing.sm}px ${theme.spacing.sm}px;
  `}
`;

export const DataWrapper = styled.div`
  flex-grow: 1;
`;

export const Action = styled.div`
  margin-top: auto;
  padding-top: ${({ theme }) => theme.spacing.md}px;
`;

export const CommonSkeletonStyle = {
  rows: 1,
  style: {
    height: 22,
    display: 'flex',
    alignItems: 'center',
  },
};

export const FullWidthSkeletonStyle = {
  width: '100%',
  ...CommonSkeletonStyle,
};

export const ButtonSkeletonStyle = {
  width: '100%',
  height: 40,
  borderRadius: 4,
};

export const OrdersData = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: ${({ theme }) => theme.spacing.sm}px;
`;

export const NdaContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xs}px;
`;
