import React, { type ComponentProps } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Dropdown } from '@npm/core/ui/components/atoms/Dropdown';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Switch } from '@npm/core/ui/components/atoms/Switch';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { CbCompanyActive, Codebooks, useCodebook } from '@npm/data-access';
import { useTheme } from 'styled-components';

import { type FilterItemProps } from '../../../../../filters';

import { DropdownSelectContainer } from './styles';

type Props = FilterItemProps & {
  value?: string;
  onChange?: (val: string) => void;
};

const DEFAULT_VALUE = CbCompanyActive.items.all_active;

// we are not showing all codebook values coming from BE
const OPTIONS = [
  CbCompanyActive.items.all_active,
  CbCompanyActive.items.today,
  CbCompanyActive.items.last_7_days,
  CbCompanyActive.items.last_30_days,
];

// we override name of all_active code
const ALL_ACTIVE_NAME = 'All';

export const ActiveCompaniesSwitchFilter = ({
  name,
  onFilterSubmit,
  onChange,
  value,
  ...rest
}: Props) => {
  const theme = useTheme();

  const { data, isLoading } = useCodebook({
    type: Codebooks.COMPANY_ACTIVE,
  });

  const getCbNameByCode = (code: string) =>
    code === CbCompanyActive.items.all_active
      ? ALL_ACTIVE_NAME
      : data?.codebooks?.find(cb => cb.code === code).name;

  const dropdownItems: ComponentProps<typeof Dropdown>['items'] = OPTIONS.map(
    code => ({
      key: code,
      label: getCbNameByCode(code),
      selected: code === value,
      onClick: () => {
        onChange(code);
        onFilterSubmit(name);
      },
    })
  );

  return (
    <DropdownSelectContainer>
      <Tooltip
        title={
          'Active Companies are Companies with live or recently transacted orders on the NPM ATS.'
        }
        overlayInnerStyle={{
          paddingLeft: theme.spacing.sm,
          paddingRight: theme.spacing.sm,
        }}
      >
        <div>
          <Switch
            titleAfter
            title="Active companies"
            checked={!!value}
            onChange={checked => {
              if (checked) {
                onChange(DEFAULT_VALUE);
              } else {
                onChange(undefined);
              }
              onFilterSubmit(name);
            }}
            {...rest}
          />
        </div>
      </Tooltip>

      {value && (
        <Dropdown
          fixedPosition
          menuProps={{
            selectable: true,
            defaultSelectedKeys: [DEFAULT_VALUE],
          }}
          placement="topLeft"
          isLoading={isLoading}
          items={dropdownItems}
        >
          <Button
            size="sm"
            variant="text"
            icon={<Icon name={'chevron-down'} />}
          >
            {getCbNameByCode(value)}
          </Button>
        </Dropdown>
      )}
    </DropdownSelectContainer>
  );
};
