import React from 'react';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import { type AssetAggregate } from '@npm/data-access';
import { useTheme } from 'styled-components';

import { HoldingUniqueIdWithDetailDrawer } from '../HoldingUniqueIdWithDetailDrawer';

import * as S from './HoldingLabel.styles';

export type HoldingForHoldingLabel = {
  id?: number;
  certificate_number?: string;
  asset?: AssetAggregate;
};

type Props = {
  holding: HoldingForHoldingLabel;
  isUniqueIdClickable?: boolean;
  holdingUniqueIdMaxLength?: number;
};

export const HoldingLabel = ({
  holding,
  isUniqueIdClickable = true,
  holdingUniqueIdMaxLength,
}: Props) => {
  const theme = useTheme();

  if (!holding) {
    return null;
  }

  return (
    <S.Wrapper align="center" gap="xs">
      <S.Icon
        size="xs"
        name="file-text"
        color={theme.color.general.typography.muted}
      />

      {holding.asset?.type?.code && (
        <>
          <Text size="sm" colorVariant="secondary">
            {holding.asset?.type?.name}
          </Text>
          {holding.id && <S.Seperator>&middot;</S.Seperator>}
        </>
      )}
      <HoldingUniqueIdWithDetailDrawer
        holding={holding}
        isUniqueIdClickable={isUniqueIdClickable}
        holdingUniqueIdMaxLength={holdingUniqueIdMaxLength}
        withIcon={false}
      />
    </S.Wrapper>
  );
};
