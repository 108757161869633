// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  SectorsApi,
  SectorIndexErrorCodes,
  SectorIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new SectorsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type SectorIndexMethod = SectorsApi['sectorIndex'];
export type SectorIndexResponseType = MethodResult<SectorIndexMethod>;

export const useSectorIndex = (
  variables: Parameters<SectorIndexMethod>[0],
  config?: UseQueryConfig<
    SectorIndexMethod,
    SectorIndexErrorTypes
  >
) => {
  return useQuery<
    SectorIndexMethod,
    SectorIndexErrorTypes
  >(
    (...args) => api.sectorIndex(...args),
    SectorIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SectorIndex'],
        ...apiQueryConfig.SectorIndex,
        ...config?.queryConfig
      },
    },
    'SectorIndex',
  );
};

export const useSectorIndexInfinite = (
  variables: Parameters<SectorIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    SectorIndexMethod,
    SectorIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    SectorIndexMethod,
    SectorIndexErrorTypes
  >(
    (...args) => api.sectorIndex(...args),
    SectorIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SectorIndex', 'SectorIndexInfinite'],
        ...apiQueryConfig.SectorIndex,
        ...config?.queryConfig,
      },
    },
    'SectorIndex',
  );
};

export const useSectorIndexLazy = (baseOptions?: {
  variables?: Parameters<SectorIndexMethod>[0],
  config?: UseQueryConfig<
    SectorIndexMethod,
    SectorIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    SectorIndexMethod,
    SectorIndexErrorTypes
  >(
    (...args) => api.sectorIndex(...args),
    SectorIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['SectorIndex'],
        ...apiQueryConfig.SectorIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'SectorIndex',
  );
};

