import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Margin } from '@npm/core/ui/components/atoms/common';
import { hasOnlySpecificProperties } from '@npm/core/ui/components/atoms/Form';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Label } from '@npm/core/ui/components/atoms/Label';
import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { RuleAlert } from '@npm/core/ui/components/atoms/RuleAlert';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { CompanyLogo } from '@npm/core/ui/components/molecules/CompanyLogo';
import { type useConfirmOnLeave } from '@npm/core/ui/components/molecules/Link';
import { DiscardModal } from '@npm/core/ui/components/organisms/DiscardModal';
import {
  Drawer,
  DrawerConfirmDialog,
  useDrawerConfirmDialog,
} from '@npm/core/ui/components/organisms/Drawer';
import { CypressDataIds } from '@npm/core/ui/constants';
import { handleValidationError } from '@npm/core/ui/utils/form';
import {
  type AffiliateDisclosureType,
  type AttestationRuleType,
  CbATSPool,
  CbAttestationRule,
  CbOrderSource,
  CbVisibility,
  CbWorkstationType,
  Codebooks,
  type Holding,
  type HoldingsDistributionCalculationShow,
  type OrderSourceCode,
  useAttestationShow,
  useCodebook,
  useHoldingsDistributionCalculationCreate,
  useIssuerEntityShow,
  useSecondmarketSubmissionCreate,
} from '@npm/data-access';
import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { uniqBy } from 'lodash';
import pluralize from 'pluralize';

import { useUserContextStore } from '../../../../auth/user/context';
import { YourRoleDrawerSection } from '../../../../auth/user/context/YourRoleDrawerSection';
import { useCurrentWorkstation } from '../../../../auth/user/role';
import { type OboDefinition } from '../../../../auth/user/role/userRole.types';
import { useHoldingSelectData } from '../../../../filters';
import { HoldingDrawer, useHoldingDrawer } from '../../../../holdings';
import {
  sizeInShares,
  useAllowedAssetTypeOptions,
  useOrderSizeToggle,
} from '../../../../order';
import type { HoldingsDistribution } from '../../../individual-investor-landing/SellIndication/EnterSellIndication/SellIndicationDrawer';
import { SellIndicationOrdersCreation } from '../../../individual-investor-landing/SellIndication/EnterSellIndication/SellIndicationDrawer/DrawerSections';
import { AcknowledgementModal } from '../../components/AcknowledgementModal';
import { AffiliateDisclosureWithSignatureModal } from '../../components/AffiliateDisclosureWithSignatureModal';
import { validateDateFields } from '../../components/inputs';
import { ReviewTermsModal } from '../../components/ReviewTermsModal';
import { getMinimumNotionalValueErrorMessage } from '../../utils/minNotionalValue';
import { isAccountGuestRole, isAccountNotAccredited } from '../../utils/order';

import * as S from './OrderPlacementDrawer.styles';
import { OrderPlacementConfirmation } from './OrderPlacementConfirmation';
import {
  DrawerFooter,
  DrawerTitleContainer,
} from './OrderPlacementDrawer.styles';
import type {
  OrderPlacementFormValues,
  OrderPlacementStep,
} from './OrderPlacementDrawer.types';
import { validateMinimumNotionalValue } from './OrderPlacementDrawer.utils';
import {
  getSubmitPayload,
  useAccount,
  useDrawerStorage,
  useIssuerEntity,
} from './OrderPlacementDrawer.utils';
import { OrderPlacementForm } from './OrderPlacementForm';

const ORDER_PLACEMENT_FORM_ID = 'order-placement-form';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  confirmOnLeaveProps: ReturnType<typeof useConfirmOnLeave>;
  isInvestor: boolean;
  onAccountSwitch?: () => void;
  onSuccess?: () => void;
  source?: OrderSourceCode;
  initialValues?: {
    buySell?: 'buy' | 'sell';
    issuerEntityId?: number;
  };
  disabledFields?: React.ComponentProps<
    typeof OrderPlacementForm
  >['disabledFields'];
  obo: OboDefinition | null;
  setOboOverride?: React.Dispatch<React.SetStateAction<OboDefinition>>;
};

export const OrderPlacementDrawer = ({
  isOpen,
  onClose,
  source,
  initialValues,
  disabledFields,
  onAccountSwitch,
  isInvestor,
  obo,
  setOboOverride,
  confirmOnLeaveProps: {
    onCloseAttempt,
    setHasUnsavedChanges,
    discardModalProps,
  },
}: Props) => {
  const { sizeType, updateSizeType, toggleSizeType } = useOrderSizeToggle(
    initialValues?.buySell === 'sell' ? 'Shares' : 'USD'
  );
  const workstation = useCurrentWorkstation();
  const redoActionRef = useRef(false);
  const [signature, setSignature] = useState<string>(null);

  const [selectedHoldings, setSelectedHoldings] = useState<Holding[]>([]);
  const [selectedHolding, setSelectedHolding] = useState<Holding>();
  const [holdingsDistribution, setHoldingsDistribution] =
    useState<HoldingsDistribution>();
  const [activeStep, setActiveStep] = useState<OrderPlacementStep>('form');

  const { isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog } =
    useDrawerConfirmDialog();
  const [showReviewModalTerms, setShowReviewModalTerms] = useState(false);

  const [form] = useForm<OrderPlacementFormValues>();
  const [previewValues, setPreviewValues] =
    useState<OrderPlacementFormValues>(); // Available only after the form is validated

  // Specify explicitly because there are many drawers & modals in this component
  const alertContainerId = `order-placement-drawer-${source ?? 'new'}`;
  const { withShowApiErrorMiddleware } = useAlerts();

  const activeAction =
    Form.useWatch('buySell', form) ??
    previewValues?.buySell ??
    initialValues?.buySell;

  const {
    preselectedIssuerEntityId,
    issuerEntityId,
    issuerEntity: selectedIssuerEntity,
    isLoading: isSelectedIssuerEntityLoading,
  } = useIssuerEntity({ form, previewValues, isOpen });

  const {
    accountId,
    account: selectedAccount,
    isLoading: isAccountLoading,
  } = useAccount({ form, previewValues, obo });

  const user = useUserContextStore(store => store.user);

  const isFinishedOrdersCreation = useMemo(() => {
    if (!holdingsDistribution) return false;

    return holdingsDistribution.every(
      ({ variant }) => variant === 'success' || variant === 'failed'
    );
  }, [holdingsDistribution]);

  const isPostingOrders =
    activeStep === 'ordersCreation' && !isFinishedOrdersCreation;

  const { execute: createSubmission, isLoading: isCreateSubmissionLoading } =
    useSecondmarketSubmissionCreate();

  const {
    execute: calculateHoldingsDistribution,
    isLoading: isCalculatingHoldingsDistribution,
  } = useHoldingsDistributionCalculationCreate();

  useEffect(() => {
    form.resetFields(['holdingIds']);
    setSelectedHoldings([]);
  }, [form, activeAction, issuerEntityId, accountId]);

  const {
    getFormData,
    getFormSizeType,
    setFormData,
    setFormSizeType,
    clearSavedData,
  } = useDrawerStorage();

  useEffect(() => {
    if (!isOpen) return;
    const savedFormData = getFormData();
    if (savedFormData) {
      form.setFieldsValue(savedFormData);
      updateSizeType(getFormSizeType());
      clearSavedData();
    } else if (initialValues) {
      form.setFieldsValue(initialValues);
      if (initialValues.buySell) {
        updateSizeType(initialValues.buySell === 'sell' ? 'Shares' : 'USD');
      }
    } else {
      form.setFieldValue('buySell', 'buy');
    }
  }, [isOpen]);

  const handleStateReset = (
    preserveAccount?: boolean,
    preserveIssuerEntity?: boolean
  ) => {
    form.resetFields();

    if (preserveAccount) {
      form.setFieldValue('accountId', previewValues?.accountId);
    } else {
      setOboOverride?.(null);
    }

    if (preserveIssuerEntity) {
      form.setFieldValue('issuerEntityId', previewValues?.issuerEntityId);
    }

    setHasUnsavedChanges(false);
    setSignature(null);
    clearSavedData();
    form.setFieldsValue(initialValues);
    setPreviewValues(undefined);
    setActiveStep('form');
    setSelectedHoldings([]);
    setSelectedHolding(undefined);
    setHoldingsDistribution(undefined);
    redoActionRef.current = false;
  };

  const handlePreview = async () => {
    try {
      const formValues = await form.validateFields();
      if (validateDateFields(formValues).length) return;
      setPreviewValues(formValues);

      if (selectedHoldings.length > 1) {
        const totalQuantity = sizeInShares(
          sizeType,
          formValues.quantity,
          formValues.pricePerShare
        );

        const result: HoldingsDistributionCalculationShow =
          await calculateHoldingsDistribution({
            holdingsDistributionCalculationCreateRequestContract: {
              account_npm_guid: selectedAccount?.npm_guid,
              issuer_npm_guid: selectedIssuerEntity?.npm_guid,
              holding_guids: selectedHoldings.map(holding => holding.npm_guid),
              total_quantity: totalQuantity,
            },
          });

        const holdingsDistribution = result.distributions.map(
          holdingDistribution => {
            const holding = selectedHoldings.find(
              holding =>
                holding.npm_guid ===
                // remove once NPM-11886 is resolved
                (holdingDistribution.holding as { npm_guid: string }).npm_guid
            );

            if (!holding) {
              throw new Error('Holding not found');
            }

            return {
              allocation: holdingDistribution.allocation,
              holding: holding,
              variant: 'detail' as const,
            };
          }
        );

        setHoldingsDistribution(holdingsDistribution);
      } else {
        setSelectedHolding(selectedHoldings[0]);
      }

      setActiveStep('confirmation');
    } catch (err) {
      handleValidationError(form, err);
    }
  };

  const hasValidMinimumNotionalValue = useMemo(() => {
    if (activeStep !== 'confirmation') return undefined;
    return validateMinimumNotionalValue({
      sizeType,
      size: previewValues?.quantity,
      minimumSize: previewValues?.minimumQuantity,
      pricePerShare: previewValues?.pricePerShare,
    });
  }, [previewValues, activeStep, sizeType]);

  const handleSubmit = async () => {
    if (!hasValidMinimumNotionalValue) return;

    if (
      !!obo &&
      previewValues?.visibility === CbVisibility.items.external &&
      previewValues?.atsPool === CbATSPool.items.ats_sm &&
      source !== CbOrderSource.items.historical &&
      !signature
    ) {
      setActiveStep('acknowledgement');
    } else if (isInvestor) {
      setActiveStep('affiliateDisclosure');
    } else {
      void handleOrders();
    }
  };

  const placeOrder = async (
    holding: Holding | undefined,
    quantity: number,
    options?: {
      signature?: string;
      affiliateDisclosure?: AffiliateDisclosureType;
    }
  ) => {
    const secondmarketSubmissionCreateRequestContract = getSubmitPayload(
      previewValues,
      quantity,
      !!obo,
      sizeType,
      source,
      holding,
      options?.signature ?? signature,
      options?.affiliateDisclosure
    );

    try {
      const order = await withShowApiErrorMiddleware(createSubmission, {
        containerId: alertContainerId,
      })({
        secondmarketSubmissionCreateRequestContract,
        ...(obo && {
          xOboAccountId: obo.account?.id?.toString(),
          xOboUserId: obo.representative?.user_id?.toString(),
        }),
      });

      holdingsDistribution &&
        setHoldingsDistribution(holdingsDistribution =>
          holdingsDistribution.map(hd =>
            hd.holding.npm_guid === holding.npm_guid
              ? { ...hd, variant: 'success' }
              : hd
          )
        );
      return order;
    } catch (e) {
      holdingsDistribution &&
        setHoldingsDistribution(holdingsDistribution =>
          holdingsDistribution.map(hd =>
            hd.holding.npm_guid === holding.npm_guid
              ? { ...hd, variant: 'failed', error: e.message }
              : hd
          )
        );
      console.error(e);
      return false;
    }
  };

  const handleOrders = async (options?: {
    onComplete?: () => void;
    signature?: string;
    affiliateDisclosure?: AffiliateDisclosureType;
  }) => {
    if (activeStep === 'acknowledgement') {
      setActiveStep(holdingsDistribution ? 'ordersCreation' : 'confirmation');
      setSignature(options?.signature);
    }

    let allSuccessful = true;

    // handle single order
    if (selectedHolding || previewValues.buySell === 'buy') {
      const order = await placeOrder(
        selectedHolding,
        previewValues.quantity,
        options
      );
      if (!order) {
        allSuccessful = false;
      }
    } else if (holdingsDistribution) {
      setActiveStep('ordersCreation');
      setHoldingsDistribution(holdingsDistribution =>
        holdingsDistribution.map(hd => ({ ...hd, variant: 'posting' }))
      );

      for (const holdingDistribution of holdingsDistribution) {
        const order = await placeOrder(
          holdingDistribution.holding,
          holdingDistribution.allocation,
          {
            signature: options?.signature,
            affiliateDisclosure: options?.affiliateDisclosure,
          }
        );

        if (!order) {
          allSuccessful = false;
        }
      }
    }

    if (allSuccessful) {
      if (options?.onComplete) options.onComplete();
      Notification.success({
        key: 'order-placed',
        message: (
          <S.NotificationContainer>
            {pluralize('Order', holdingsDistribution?.length ?? 1)} successfully
            placed in {selectedIssuerEntity?.name}
          </S.NotificationContainer>
        ),
      });
      if (!redoActionRef.current) {
        setHasUnsavedChanges(false);
        onClose();
      }
      handleStateReset(redoActionRef.current, !!preselectedIssuerEntityId);
    }
  };

  const handleCloseConfirm = () => {
    handleStateReset();
    onClose();
  };

  const handleCloseAttempt = (_?: unknown, trigger?: 'mask' | 'button') => {
    if (activeStep === 'form') {
      onCloseAttempt(handleCloseConfirm);
    } else if (activeStep === 'confirmation') {
      if (trigger === 'mask') {
        onCloseAttempt(handleCloseConfirm);
      } else {
        setActiveStep('form');
        setHoldingsDistribution(undefined);
        setSelectedHolding(undefined);
      }
    } else if (activeStep === 'acknowledgement') {
      setActiveStep('confirmation');
    } else if (activeStep === 'affiliateDisclosure') {
      setActiveStep('confirmation');
    } else if (activeStep === 'ordersCreation' && !isPostingOrders) {
      handleCloseConfirm();
    }
  };

  const { data: assetTypeData } = useCodebook({
    type: Codebooks.ASSET_TYPE,
  });

  const allowedAssetTypeOptions = useAllowedAssetTypeOptions(
    assetTypeData?.codebooks ?? []
  );
  const { data: orderEntryTypeData } = useCodebook({
    type: 'ORDER_ENTRY_TYPE',
  });

  const { data: issuerEntity } = useIssuerEntityShow(
    {
      id: previewValues?.issuerEntityId?.toString(),
    },
    {
      queryConfig: {
        enabled: !!previewValues?.issuerEntityId,
      },
    }
  );

  const handleSetSelectedHoldings = (holdings: Holding[]) => {
    setSelectedHoldings(holdings);
    if (form.isFieldTouched('quantity')) {
      form.validateFields(['quantity']);
    }
  };

  const [{ openAddHoldingDrawer }, holdingDrawerProps] = useHoldingDrawer({
    preselectedIssuerEntityId: issuerEntityId,
    preselectedAccountId: accountId,
  });

  // check available holdings (remaining_quantity > 0)
  const { availableHoldingsCount, isLoading: areHoldingsLoading } =
    useHoldingSelectData({
      secondmarket: true,
      includeSpvs: true,
      includePortfolioHoldings: true,
      accountId,
      issuerEntityId,
      oboOverride: obo,
    });

  const orderTypeValue =
    Form.useWatch('orderType', form) ?? previewValues?.orderType;

  const attestationRule = obo
    ? CbAttestationRule.items.obo_create_order
    : (orderTypeValue as AttestationRuleType);

  const { data: attestationData } = useAttestationShow(
    {
      attestationRule,
    },
    {
      queryConfig: {
        enabled: activeStep === 'confirmation' && !!attestationRule,
      },
    }
  );
  const noHoldingsFound = !availableHoldingsCount;
  const submitLoading = isCreateSubmissionLoading && !redoActionRef.current;
  const submitAndCreateLoading =
    isCreateSubmissionLoading && redoActionRef.current;

  const isLoading =
    isAccountLoading || isSelectedIssuerEntityLoading || areHoldingsLoading;

  const drawerTitleText = useMemo(() => {
    if (activeStep === 'confirmation') {
      return 'Confirm Order';
    }

    if (activeStep === 'ordersCreation') {
      return 'Preview Order';
    }

    if (obo) {
      if (source === CbOrderSource.items.historical) {
        return 'Enter Historical Order';
      }
      return 'Order Entry';
    }

    return 'Enter Indication of Interest';
  }, [activeStep, obo, source]);

  const drawerTitle = (
    <DrawerTitleContainer>
      {drawerTitleText}
      {activeStep === 'form' && (
        <Label variant={activeAction === 'buy' ? 'success' : 'error'} round>
          {activeAction === 'buy' ? 'Buy' : 'Sell'}
        </Label>
      )}
      {activeStep === 'ordersCreation' && issuerEntity && (
        <CompanyLogo
          url={issuerEntity?.logo_url}
          name={issuerEntity?.name}
          size="sm"
        />
      )}
    </DrawerTitleContainer>
  );

  const drawerFooter = (() => {
    const isMissingRequiredIds = !accountId || !issuerEntityId;
    const isSellWithNoHoldings = activeAction === 'sell' && noHoldingsFound;
    const isGuestRoleAndNotObo =
      !obo && isAccountGuestRole(selectedAccount, user?.email);
    const isBuyNotAccreditedAndNotHistorical =
      activeAction === 'buy' &&
      source !== CbOrderSource.items.historical &&
      isAccountNotAccredited(selectedAccount);

    if (
      activeStep === 'form' &&
      (isMissingRequiredIds ||
        isSellWithNoHoldings ||
        isGuestRoleAndNotObo ||
        isBuyNotAccreditedAndNotHistorical)
    ) {
      return null;
    }

    return (
      <DrawerFooter>
        {activeStep === 'form' && (
          <Button
            form={ORDER_PLACEMENT_FORM_ID}
            htmlType="submit"
            data-cy={CypressDataIds.OrderEntryDrawer.Preview}
            disabled={isCalculatingHoldingsDistribution}
            loading={isCalculatingHoldingsDistribution}
          >
            Preview {activeAction === 'buy' ? 'Bid' : 'Offer'}
          </Button>
        )}
        {activeStep === 'confirmation' && (
          <>
            {isConfirmDialogOpen && !isInvestor && (
              <DrawerConfirmDialog
                message="I reviewed the order details and I am ready to submit it"
                handleConfirm={() => {
                  handleSubmit();
                  closeConfirmDialog();
                }}
                cancelText="Review Terms"
                handleCancel={() => {
                  setShowReviewModalTerms(true);
                }}
                handleClickOutsideConfirm={() => {
                  if (showReviewModalTerms) return;
                  closeConfirmDialog();
                }}
                offsetBottom={160}
              />
            )}
            <Button
              data-cy={CypressDataIds.OrderEntryDrawer.Submit}
              onClick={() =>
                isInvestor ? handleSubmit() : openConfirmDialog()
              }
              loading={submitLoading}
              disabled={!hasValidMinimumNotionalValue || submitAndCreateLoading}
            >
              Submit Order
            </Button>
            <Button
              color="info"
              variant="outline"
              onClick={() => {
                redoActionRef.current = true;
                isInvestor ? handleSubmit() : openConfirmDialog();
              }}
              loading={submitAndCreateLoading}
              disabled={!hasValidMinimumNotionalValue || submitLoading}
            >
              Submit & Create Another Order
            </Button>
            <Button
              onClick={handleCloseAttempt}
              variant="text"
              icon={<Icon name="arrow-narrow-left" />}
            >
              Back
            </Button>
          </>
        )}

        {activeStep === 'ordersCreation' && (
          <Button disabled={isPostingOrders} onClick={handleCloseConfirm}>
            Close
          </Button>
        )}
      </DrawerFooter>
    );
  })();

  useEffect(() => {
    // sets the visility field to internal when selected issuer does not enable trading
    if (selectedIssuerEntity?.secondmarket_trading === false) {
      form.setFieldsValue({
        visibility: CbVisibility.items.internal,
      });
    }
  }, [form, selectedIssuerEntity]);

  const useSingleHoldingSelect =
    workstation?.type?.code === CbWorkstationType.items.brokerage;

  return (
    <>
      <Drawer
        open={isOpen}
        title={drawerTitle}
        data-cy={CypressDataIds.OrderEntryDrawer.Drawer}
        onClose={handleCloseAttempt}
        alertContainerId={alertContainerId}
        {...(activeStep === 'confirmation' && {
          isFooterFixed: true,
          footerHeight: 200,
        })}
        footer={drawerFooter}
      >
        {activeStep === 'form' && (
          <>
            {!!obo && selectedIssuerEntity?.secondmarket_trading === false && (
              <Margin bottom="md">
                <RuleAlert
                  icon={
                    <Icon name="info-circle" size="xs" colorVariant="muted" />
                  }
                  message={
                    <Text size="sm">
                      NPM has been informed by {selectedIssuerEntity?.name} that
                      all share transfers are being restricted for the time
                      being. Thank you for your understanding and cooperation.
                      We will let you know if anything changes.
                    </Text>
                  }
                />
              </Margin>
            )}
            {!!obo && (
              <Margin bottom={'md'} top={'sm'}>
                <YourRoleDrawerSection
                  showTitle={false}
                  onAccountSwitch={
                    disabledFields?.includes('accountId')
                      ? undefined
                      : () => {
                          setFormData(
                            activeStep === 'form'
                              ? form.getFieldsValue()
                              : previewValues
                          );
                          setFormSizeType(sizeType);
                          onAccountSwitch();
                        }
                  }
                  accountId={accountId}
                  isObo
                />
              </Margin>
            )}
            <OrderPlacementForm
              formId={ORDER_PLACEMENT_FORM_ID}
              form={form}
              disabledFields={disabledFields}
              onValuesChange={(_, vals) => {
                if (
                  hasOnlySpecificProperties(vals, [
                    'issuerEntityId',
                    'accountId',
                    'buySell',
                  ])
                ) {
                  return;
                }
                setHasUnsavedChanges(true);
              }}
              company={selectedIssuerEntity}
              activeAction={activeAction}
              source={source}
              selectedHoldings={selectedHoldings}
              setSelectedHoldings={handleSetSelectedHoldings}
              openAddHoldingDrawer={openAddHoldingDrawer}
              allowedAssetTypeOptions={allowedAssetTypeOptions}
              noHoldingsFound={noHoldingsFound}
              holdingsLoading={isLoading}
              orderSizeType={sizeType}
              updateSizeType={updateSizeType}
              toggleSizeType={toggleSizeType}
              onAccountSwitch={onAccountSwitch}
              brokerageFirm={selectedAccount?.brokerage_firm}
              isInvestor={isInvestor}
              obo={obo}
              selectedAccount={selectedAccount}
              handleSubmit={handlePreview}
              useSingleHoldingSelect={useSingleHoldingSelect}
            />
          </>
        )}
        {activeStep === 'confirmation' && (
          <OrderPlacementConfirmation
            values={previewValues}
            errorMessage={
              hasValidMinimumNotionalValue === false
                ? getMinimumNotionalValueErrorMessage()
                : undefined
            }
            activeAction={activeAction}
            holding={selectedHolding}
            holdingsDistribution={holdingsDistribution}
            orderEntryTypes={orderEntryTypeData?.codebooks || []}
            orderSizeType={sizeType}
            brokerageFirm={selectedAccount?.brokerage_firm}
            source={source}
            showNextStepsSection={isInvestor}
          />
        )}

        {activeStep === 'ordersCreation' && holdingsDistribution && (
          <SellIndicationOrdersCreation
            pricePerShare={previewValues?.pricePerShare}
            holdingsDistribution={holdingsDistribution}
            accountId={accountId}
          />
        )}
      </Drawer>
      <HoldingDrawer
        {...holdingDrawerProps}
        initialIssuerEntity={selectedIssuerEntity}
        disabledIssuerEntitySelect
        disabledAccountSelect
        backToRoute="Order Entry"
        onSuccess={holding => {
          if (holding) {
            const holdingIds = form.getFieldValue('holdingIds') ?? [];
            const holdingIdsArray = Array.isArray(holdingIds)
              ? holdingIds
              : [holdingIds];

            if (useSingleHoldingSelect) {
              form.setFieldsValue({
                holdingIds: [holding.npm_guid],
              });
              setSelectedHoldings([holding]);
            } else {
              form.setFieldsValue({
                holdingIds: [...holdingIdsArray, holding.npm_guid],
              });
              setSelectedHoldings(selectedHoldings =>
                uniqBy(
                  [...selectedHoldings.filter(Boolean), holding],
                  holding => holding.npm_guid
                )
              );
            }
          }
        }}
        oboOverride={obo}
      />
      {!!obo && (
        <AcknowledgementModal
          isOpen={activeStep === 'acknowledgement'}
          onSubmit={handleOrders}
          onCancel={handleCloseAttempt}
          isLoading={isCreateSubmissionLoading}
        />
      )}
      {isInvestor && (
        <AffiliateDisclosureWithSignatureModal
          isOpen={activeStep === 'affiliateDisclosure'}
          onSubmit={handleOrders}
          onCancel={handleCloseAttempt}
          reviewTerms={attestationData?.message}
          issuerEntity={selectedIssuerEntity}
        />
      )}
      <DiscardModal cancelText={'Keep editing'} {...discardModalProps} />
      <ReviewTermsModal
        isOpen={showReviewModalTerms}
        message={attestationData?.message}
        onSubmit={() => {
          setShowReviewModalTerms(false);
        }}
      />
    </>
  );
};
