import React from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import type { ButtonVariant } from '@npm/core/ui/components/atoms/Button/Button.types';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { useHistory } from '@npm/core/ui/components/molecules/Link';
import { NumberParam, useQueryParams } from 'use-query-params';

type Props = { variant?: ButtonVariant; text?: string };

export const SellIndicationButton = ({
  variant = 'default',
  text = 'Submit a Sell Indication',
}: Props) => {
  const history = useHistory();

  const [queryParams] = useQueryParams({
    companyId: NumberParam,
  });

  return (
    <Button
      variant={variant}
      color="error"
      icon={<Icon name="arrows-opposite" />}
      onClick={() =>
        history.push(
          `/second-market/investor/sell-indication?companyId=${queryParams.companyId}`
        )
      }
    >
      {text}
    </Button>
  );
};
