import React from 'react';

import { Form } from '@npm/core/ui/components/atoms/Form';
import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import {
  useConfirmOnLeave,
  useHistory,
} from '@npm/core/ui/components/molecules/Link';
import { DiscardModal } from '@npm/core/ui/components/organisms/DiscardModal';
import { Drawer } from '@npm/core/ui/components/organisms/Drawer';
import { CypressDataIds } from '@npm/core/ui/constants';
import { handleValidationError } from '@npm/core/ui/utils/form';
import {
  type BrokerageFirmsAccountCreateRequestContract,
  CbAccountType,
} from '@npm/data-access';
import { useForm } from 'antd/lib/form/Form';

import { useCurrentRole } from '../../auth/user/role/hooks/useCurrentRole';

import { NewOrganizationAccountForm } from './Form';
import { useCreateOrganizationAccount } from './NewOrganizationAccountDrawer.hooks';
import { usePersonFormInitialValues } from './usePersonFormInitialValues.hooks';

const NEW_ENTITY_ACCOUNT_FORM_ID = 'new-entity-account-form';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

type AccountCreate = BrokerageFirmsAccountCreateRequestContract & {
  anonymous: boolean;
};

export const NewOrganizationAccountDrawer = ({ isOpen, onClose }: Props) => {
  const { withShowApiErrorMiddleware } = useAlerts();
  const role = useCurrentRole();

  const { execute, isLoading } = useCreateOrganizationAccount(role.subject?.id);

  const [form] = useForm<AccountCreate>();
  const isAnonymous = Form.useWatch('anonymous', form);
  const history = useHistory();

  const { setHasUnsavedChanges, onCloseAttempt, discardModalProps } =
    useConfirmOnLeave();

  const handleCloseConfirm = () => {
    form.resetFields();
    setHasUnsavedChanges(false);
    onClose();
  };

  const handleFinish = async (values: AccountCreate) => {
    try {
      const res = await withShowApiErrorMiddleware(execute)(values);
      handleCloseConfirm();

      if (res.type?.code !== CbAccountType.items.Anonymous) {
        history.push(
          `/brokerage-workstation/entities/${res.id}?tab=onboarding`
        );
      } else {
        history.push(`/brokerage-workstation/entities`);
      }

      Notification.success({
        message: `You have successfully created the account ${res?.external_id}`,
      });
    } catch (e) {
      console.error(e);
    }
  };

  usePersonFormInitialValues(form);

  return (
    <>
      <Drawer
        open={isOpen}
        title={`Create New Entity Account for ${role.subject?.name}`}
        onClose={() => onCloseAttempt(handleCloseConfirm)}
        destroyOnClose
        submitButtonProps={{
          form: NEW_ENTITY_ACCOUNT_FORM_ID,
          children: `Save & Continue${!isAnonymous ? ' to Onboarding' : ''}`,
          'data-cy': CypressDataIds.Entities.submitButton,
          htmlType: 'submit',
          loading: isLoading,
        }}
      >
        <NewOrganizationAccountForm
          id={NEW_ENTITY_ACCOUNT_FORM_ID}
          form={form}
          onFinish={handleFinish}
          onFinishFailed={e => handleValidationError(form, e)}
          onValuesChange={() => setHasUnsavedChanges(true)}
        />
      </Drawer>
      <DiscardModal {...discardModalProps} />
    </>
  );
};
