import type React from 'react';
import { type FC } from 'react';

import { type AlertProps, Alert as AntAlert } from 'antd';
import styled, { css } from 'styled-components';

import { centerFlex, useBreakpoint } from '../../../utils/style';
import { SmallTextStyles, Text } from '../Typography';

import { type Alert } from './Alert';

export const ALERT_SPACING = {
  sm: 4,
  md: 10,
};

// [Antd Typings] Ant'd internal types are not exported, need to re-type.
export const StyledAlert = styled<FC<AlertProps>>(AntAlert)<{
  hasDescription: boolean;
  $centered?: boolean;
}>`
  &,
  .ant-alert {
    width: 100%;
    padding: ${({ theme }) => theme.spacing.sm}px;
    border-radius: ${({ theme }) => theme.borderRadius.md}px;
    align-items: ${({ hasDescription }) =>
      hasDescription ? 'flex-start' : 'center'};

    ${({ $centered }) =>
      $centered &&
      css`
        justify-content: center;

        .ant-alert-content {
          flex: none;
        }
      `}

    .ant-alert-message,
    .ant-alert-description {
      margin: 0;
      ${SmallTextStyles}
    }

    .ant-alert-description {
      margin-top: ${`${ALERT_SPACING.md}px`};
      color: ${({ theme }) => theme.color.general.typography.secondary};
    }

    .ant-alert-close-icon {
      ${centerFlex};
      align-self: flex-start;
      margin: 0 ${({ theme }) => theme.spacing.sm}px 0 0;
      ${({ theme, hasDescription }) =>
        !hasDescription &&
        `
        margin: 0 ${theme.spacing.sm}px 0 ${theme.spacing.md}px;
      `}
      height: ${({ theme }) => theme.typography.height.sm}px;

      ${({ theme }) => useBreakpoint(theme).mobile`
        /* The height for this icon must be equal to the line height of the message */
        height: ${({ theme }) => theme.typography.height.xs}px;
      `}
      svg {
        color: ${({ theme }) => theme.color.general.typography.primary};
      }
    }

    &.ant-alert-with-description .ant-alert-icon {
      margin-right: ${`${ALERT_SPACING.md}px`};
    }

    .ant-alert-action {
      align-self: start;
      margin: 0 ${({ theme }) => theme.spacing.md}px;
      line-height: ${({ theme }) => theme.typography.height.xs}px;
    }

    .ant-alert-message {
      color: ${({ theme }) => theme.color.general.typography.primary};
    }

    &.ant-alert-info {
      background: ${({ theme }) => theme.color.general.layout.two};
      border-color: ${({ theme }) => theme.color.info.borders.secondary};

      .ant-alert-action button {
        color: ${({ theme }) => theme.color.info.typography.primary};
      }

      .icon-wrap svg {
        color: ${({ theme }) => theme.color.info.typography.primary};
      }
    }

    &.ant-alert-success {
      background: ${({ theme }) =>
        theme.color.success.backgrounds.secondaryDefault};
      border-color: ${({ theme }) => theme.color.success.borders.primary};

      .ant-alert-action button {
        color: ${({ theme }) => theme.color.success.typography.primary};
      }

      .icon-wrap svg {
        color: ${({ theme }) => theme.color.success.typography.primary};
      }
    }

    &.ant-alert-warning {
      background: ${({ theme }) =>
        theme.color.warning.backgrounds.secondaryDefault};
      border-color: ${({ theme }) => theme.color.warning.borders.secondary};

      .ant-alert-action button {
        color: ${({ theme }) => theme.color.warning.typography.primary};
      }

      .icon-wrap svg {
        color: ${({ theme }) => theme.color.warning.typography.primary};
      }
    }

    &.ant-alert-error {
      background: ${({ theme }) =>
        theme.color.error.backgrounds.secondaryDefault};
      border-color: ${({ theme }) => theme.color.error.borders.secondary};

      .ant-alert-action button {
        color: ${({ theme }) => theme.color.error.typography.primary};
      }

      .icon-wrap svg {
        color: ${({ theme }) => theme.color.error.typography.primary};
      }
    }
  }
`;

export const IconWrap = styled.div<{ $centerIcon: boolean }>`
  ${centerFlex};
  align-self: ${({ $centerIcon }) => ($centerIcon ? 'center' : 'flex-start')};
  padding: 1px;
  margin-right: ${`${ALERT_SPACING.md}px`};

  svg {
    color: ${({ theme }) => theme.color.info.borders.primary};
  }
`;

export const ReadMoreButton = styled(Text)<{
  $variant: React.ComponentProps<typeof Alert>['type'];
}>`
  white-space: nowrap;
  align-self: flex-start;
  padding-left: ${({ theme }) => theme.spacing.lg}px;
  margin-right: ${({ theme }) => theme.spacing.sm}px;
  color: ${({ theme, $variant = 'info' }) =>
    theme.color[$variant].typography.primary} !important;
`;
