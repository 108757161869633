import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon as BaseIcon } from '@npm/core/ui/components/atoms/Icon';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.color.general.borders.primary};
  background: ${({ theme }) => theme.color.general.layout.one};
  padding: ${({ theme }) => theme.spacing.xs}px;
  border-radius: ${({ theme }) => theme.borderRadius.sm}px;
`;

export const Seperator = styled.div`
  color: ${({ theme }) => theme.color.general.typography.tertiary};
`;

export const Icon = styled(BaseIcon)`
  flex-shrink: 0;
`;
