import { createContext, useContext, useEffect, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useWizard } from 'react-use-wizard';

import { useHistory } from '@npm/core/ui/components/molecules/Link';
import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import {
  type WizardStore,
  WizardStoreActionTypes,
} from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';
import {
  type AccountOnboardingStatusShowErrorTypes,
  type AccountShowAggregate,
  type AccountShowErrorTypes,
  CbAccountType,
  type CbBackgroundCheckState,
  CbWorkstationType,
  getApiErrorCode,
  useAccountOnboardingStatusShow,
  useAccountShow,
  usePersonShowLazy,
  type Workstation,
} from '@npm/data-access';

import { useFeatureFlags } from '../../app/featureFlags/featureFlags.context';
import { isNPMS } from '../../auth/user/context';
import { useCurrentWorkstation, useObo } from '../../auth/user/role';
import { getEntityOnboardingSections } from '../EntityOnboarding.config';
import { getIndividualOnboardingSections } from '../IndividualOnboarding.config';
import {
  type OnboardingSectionConfig,
  type PostOnboardingContextType,
} from '../PostOnboarding.types';
import {
  findNextIncompleteOnboardingStep,
  getFlattenedOnboardingWizardSteps,
} from '../PostOnboarding.utils';

import { useCompletedOnboardingSectionModals } from './useCompletedOnboardingSectionModals';
import { useGetPostOnboardingPersonId } from './useGetPostOnboardingPersonId';
import { useIsInvitedToLivePrograms } from './useIsInvitedToLivePrograms';

// include `user_id` in the OnboardingStatusShow request
// only in OBO mode or in BRO's individual account onboarding section (entity onboarding can include multiple reps)
const getUserIdForOnboardingStatus = (
  workstation: Workstation | undefined,
  oboUserId: number | null,
  account: AccountShowAggregate | undefined
) => {
  let userId = null;

  if (oboUserId) {
    userId = oboUserId;
  } else if (
    account?.type?.code === CbAccountType.items.PersonAccount &&
    workstation?.type?.code === CbWorkstationType.items.brokerage
  ) {
    userId = account.user?.id;
  }

  return userId;
};

export const usePostOnboardingContextValue = (accountId: number) => {
  const { isEnabled } = useFeatureFlags();
  const { activeStep } = useWizard();
  const { dispatch, steps } = useWizardStore(
    (s: WizardStore<PostOnboardingContextType>) => ({
      dispatch: s.dispatch,
      steps: s.steps,
    })
  );
  const workstation = useCurrentWorkstation();
  const history = useHistory();
  const handleError = useErrorHandler();
  const getPersonId = useGetPostOnboardingPersonId();
  const { oboUserId } = useObo();
  const [sectionsConfig, setSectionsConfig] = useState<
    OnboardingSectionConfig[]
  >([]);
  const [firstIncompleteStep, setFirstIncompleteStep] = useState<number>(null);

  const getIsInvitedToLivePrograms = useIsInvitedToLivePrograms();

  const onError = (
    err: AccountOnboardingStatusShowErrorTypes | AccountShowErrorTypes
  ) => {
    if (getApiErrorCode(err) === 404) {
      history.replace('/404');
    } else {
      handleError(err);
    }
  };

  const { data: account, refetch: refetchAccountStatus } = useAccountShow(
    {
      id: accountId?.toString(),
    },
    {
      onComplete: data => {
        dispatch({
          type: WizardStoreActionTypes.UPDATE_DATA,
          payload: {
            account: data,
          },
        });
      },
      onError,
      queryConfig: {
        enabled: !!accountId,
      },
    }
  );

  const [fetchPerson] = usePersonShowLazy();

  const { handleOnboardingStatusUpdate, renderModals } =
    useCompletedOnboardingSectionModals();

  const {
    refetch: refetchOnboardingStatus,
    data,
    isLoading: isContextLoading,
  } = useAccountOnboardingStatusShow(
    {
      id: accountId,
      userId: getUserIdForOnboardingStatus(workstation, oboUserId, account),
    },
    {
      onError,
      queryConfig: {
        enabled: !!account,
      },
    }
  );

  const isPersonaFeatureFlagEnabled = isEnabled({
    flag: 'PERSONA',
    type: 'allow-if-enabled',
  });

  useEffect(() => {
    if (!data || !account?.type?.code) return;
    (async () => {
      let canEditSpouse = false;
      const isIndividual =
        account?.type?.code === CbAccountType.items.PersonAccount;
      const isInvitedToLivePrograms = await getIsInvitedToLivePrograms(account);
      const npms = isNPMS(account.user_role?.subject);

      try {
        const personData = await fetchPerson({
          variables: { id: getPersonId(account) },
        });
        canEditSpouse = personData?.can_change_spouse;
      } catch (err) {
        console.error(err);
      }

      type BackgroundCheckStateType = keyof typeof CbBackgroundCheckState.items;

      const onboardingSectionsConfig = isIndividual
        ? getIndividualOnboardingSections({
            personId: getPersonId(account),
            onboardingStatus: data,
            canEditSpouse,
            backgroundCheckStatus: account.background_check_state
              ?.code as BackgroundCheckStateType,
            shouldUsePersona: npms && isPersonaFeatureFlagEnabled,
            hasAccessToMarketplace: npms,
          })
        : getEntityOnboardingSections({
            personId: getPersonId(account),
            onboardingStatus: data,
            backgroundCheckStatus: account.background_check_state
              ?.code as BackgroundCheckStateType,
            shouldUsePersona: npms && isPersonaFeatureFlagEnabled,
            hasAccessToMarketplace: npms,
          });

      dispatch({
        type: WizardStoreActionTypes.UPDATE_DATA,
        payload: {
          onboardingStatus: data,
          isInvitedToLivePrograms,
        },
      });

      const steps = getFlattenedOnboardingWizardSteps(
        onboardingSectionsConfig,
        isIndividual && npms
      );

      dispatch({
        type: WizardStoreActionTypes.UPDATE_STEPS,
        payload: steps,
      });

      setSectionsConfig(onboardingSectionsConfig);

      const firstIncompleteStepIndex = findNextIncompleteOnboardingStep({
        steps,
        onboardingStatus: data,
        startIndex: -1,
      });

      const isPersonaFlowIncomplete =
        npms &&
        isPersonaFeatureFlagEnabled &&
        onboardingSectionsConfig[0].items.length === 1;

      setFirstIncompleteStep(
        isPersonaFlowIncomplete ? 0 : firstIncompleteStepIndex
      );
      handleOnboardingStatusUpdate(onboardingSectionsConfig, data);
    })();
  }, [
    data,
    account?.type?.code,
    account?.background_check_state?.code,
    isPersonaFeatureFlagEnabled,
  ]);

  useEffect(() => {
    if ((steps && sectionsConfig.length === 0) || activeStep === undefined) {
      return;
    }

    const activeStepKey = steps[activeStep || 0].key;
    const { activeSection, isSubstep, substepsTotal, substepIndex } =
      sectionsConfig.reduce(
        (acc, section) => {
          const foundItem = section.items?.find(({ key, substeps }) => {
            const substep = substeps?.find(({ key }) => key === activeStepKey);
            return key === activeStepKey || substep;
          });

          if (foundItem) {
            acc.activeSection = section;
            acc.isSubstep = !!foundItem.substeps;
            acc.substepsTotal = foundItem.substeps
              ? foundItem.substeps.length
              : 0;
            if (acc.isSubstep) {
              acc.substepIndex = foundItem.substeps.findIndex(
                ({ key }) => key === activeStepKey
              );
            }
            return acc;
          }
          return acc;
        },
        {
          activeSection: null,
          isSubstep: false,
          substepsTotal: 0,
          substepIndex: 0,
        }
      );

    if (!activeSection) return;

    const flattenedSectionSteps = getFlattenedOnboardingWizardSteps([
      activeSection,
    ]);

    dispatch({
      type: WizardStoreActionTypes.UPDATE_DATA,
      payload: {
        activeSectionProps: {
          activeSection,
          stepIndex:
            flattenedSectionSteps.findIndex(
              step => step.key === activeStepKey
            ) + 1,
          totalSteps: flattenedSectionSteps.length,
          isSubstep,
          totalStepSubsteps: substepsTotal,
          substepIndex,
        },
      },
    });
  }, [activeStep, steps, sectionsConfig]);

  return {
    isContextLoading,
    sectionsConfig,
    successModals: renderModals(account?.id),
    refetchOnboardingStatus,
    refetchAccountStatus,
    firstIncompleteStep,
  };
};

type PostOnboardingContextValue = ReturnType<
  typeof usePostOnboardingContextValue
>;

const PostOnboardingContext = createContext<PostOnboardingContextValue | null>(
  null
);

export const PostOnboardingContextProvider = ({
  contextValue,
  children,
}: React.PropsWithChildren<{
  contextValue: PostOnboardingContextValue;
}>) => {
  return (
    <PostOnboardingContext.Provider value={contextValue}>
      {children}
    </PostOnboardingContext.Provider>
  );
};

export const usePostOnboardingContext = () => {
  const context = useContext(PostOnboardingContext);

  if (!context) {
    throw new Error(
      'usePostOnboardingContext must be used within a PostOnboardingContextProvider'
    );
  }

  return context;
};
