import React, {
  type ComponentProps,
  memo,
  type ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { generatePath } from 'react-router';

import { Affix } from 'antd';
import { useTheme } from 'styled-components';
import useResizeObserver from 'use-resize-observer';

import { useCurrentRoute } from '../../../hooks/useCurrentRoute';
import { useParams } from '../../../hooks/useParams';
import { pageHistoryService } from '../../../services/pageHistory/pageHistory.service';
import { Flex } from '../../atoms/common';
import { Icon } from '../../atoms/Icon';
import { Tooltip } from '../../atoms/Tooltip';
import { Heading, Text } from '../../atoms/Typography';
import {
  LAYOUT_PAGE_HEADER_CONTAINER_ID,
  useLayoutConfig,
} from '../../organisms/Layout';
import { AlertContainer } from '../AlertContainer';
import { useLocation } from '../Link';

import { PageTitle } from './PageTitle/PageTitle';

import * as S from './PageHeader.styles';
import { Breadcrumbs } from './Breadcrumbs';
import { PageHeaderButton } from './Button';
import { PAGE_HEADER_BUTTON_CONTAINER_ID } from './PageHeader.constants';
import { useCustomPageHeader } from './PageHeader.hooks';
import { usePageHeaderStore } from './PageHeader.store';

type Props = {
  title?: string;
  tooltip?: string;
  label?: React.ReactNode;
  labelPosition?: 'right' | 'far-right' | 'left';
  slim?: boolean;
  isDefault?: boolean;
  companyLogo?: ReactNode;
  getContainer?: (() => HTMLElement) | null;
};

const getDefaultContainer = () =>
  document.getElementById(LAYOUT_PAGE_HEADER_CONTAINER_ID) ?? document.body;

const ApplyCustomPageHeader = memo(function ApplyCustomPageHeader() {
  useCustomPageHeader();
  return null;
});

export const PageHeader = ({
  title,
  label,
  labelPosition,
  getContainer = getDefaultContainer,
  isDefault = false,
  ...props
}: Props) => {
  const theme = useTheme();

  const [container, setContainer] = useState<HTMLElement | null>(null);

  const currentRoute = useCurrentRoute();
  const { route, parents } = currentRoute ?? {};
  const layoutConfig = useLayoutConfig();

  const { title: routeTitle, pageHeaderProps } = route ?? {};

  // using standalone vs props coming from routes config
  const slim = props.slim || pageHeaderProps?.slim;
  const companyLogo = props.companyLogo || pageHeaderProps?.companyLogo;

  const location = useLocation();
  const params = useParams();

  const breadcrumbsItems = useMemo(() => {
    const result: ComponentProps<typeof Breadcrumbs>['items'] = [];
    const pageHistory = pageHistoryService.getCurrent(location, currentRoute);
    if (!route?.pageHistory?.isRoot) {
      if (pageHistory?.length) {
        result.push(
          ...pageHistory.map((i, index) => {
            return {
              title: i.title,
              path: i.location.pathname,
              search: i.location.search,
              state: {
                pageHistory: pageHistory.slice(0, index),
              },
            };
          })
        );
      } else if (parents) {
        result.push(
          ...parents.map(i => {
            return {
              title: i.title ?? i.pageHeaderProps?.title,
              path: generatePath(i.path, params),
              state: {
                pageHistory: [],
              },
            };
          })
        );
      }
    }

    return result;
  }, [location, params, parents, route]);

  const defaultTitle: React.ReactNode = useMemo(
    () =>
      typeof routeTitle === 'function'
        ? routeTitle()
        : routeTitle || pageHeaderProps?.title,
    [routeTitle, pageHeaderProps?.title]
  );

  const titleValue = title || defaultTitle;

  const affixButtonRef = useRef<HTMLDivElement>(null);
  const { width: affixButtonWidth } = useResizeObserver({
    ref: affixButtonRef,
  });

  const affixProps = {
    offsetTop: layoutConfig.header.height,
    target: () => document.body,
  };

  const customBg = usePageHeaderStore(store => store.background);
  const customPading = usePageHeaderStore(store => store.padding);

  useEffect(() => {
    if (getContainer) {
      setContainer(getContainer());
    }
  }, [getContainer]);

  const renderTooltip = () => {
    if (!pageHeaderProps?.tooltip) {
      return null;
    }

    return (
      <Tooltip title={pageHeaderProps?.tooltip}>
        <Flex style={{ marginLeft: '4px' }}>
          <Icon
            name="info-circle"
            size="xs"
            color={theme.color.info.typography.primary}
          />
        </Flex>
      </Tooltip>
    );
  };

  const renderTitle = () => {
    if (!titleValue) return null;

    return (
      <S.TitleWrapper
        $offset={affixButtonRef.current ? affixButtonWidth : 0}
        $labelPosition={labelPosition}
      >
        {label && <S.PageHeaderLabelWrapper>{label}</S.PageHeaderLabelWrapper>}
        {slim ? (
          <Flex align="center">
            <Text size="sm" colorVariant={'primary'} as="div">
              {titleValue}
            </Text>
            {renderTooltip()}
          </Flex>
        ) : (
          <Flex align="center">
            <Heading variant="h2">{titleValue}</Heading>
            {renderTooltip()}
          </Flex>
        )}
      </S.TitleWrapper>
    );
  };

  const renderWithBreadcrumbs = () => {
    const bottomRow = (
      <S.BottomRow $bgColor={customBg}>{renderTitle()}</S.BottomRow>
    );
    return (
      <>
        <S.TopRow $bgColor={customBg}>
          <S.TopRowInner>
            {companyLogo}
            <Breadcrumbs
              items={breadcrumbsItems}
              lastLevelOnly={
                route?.pageHeaderProps?.breadcrumbProps?.lastLevelOnly
              }
              lastLevelText={
                route?.pageHeaderProps?.breadcrumbProps?.lastLevelText
              }
            />
          </S.TopRowInner>
          {!slim && (
            <Affix
              offsetTop={layoutConfig.header.height + theme.spacing.sm}
              target={() => document.body}
              style={{ zIndex: theme.zIndex.drawer }}
            >
              <S.ButtonPlaceholder
                id={PAGE_HEADER_BUTTON_CONTAINER_ID}
                ref={affixButtonRef}
              />
            </Affix>
          )}
        </S.TopRow>
        {titleValue &&
          // TODO: affix also if slim (needs special treatment)
          (slim ? bottomRow : <Affix {...affixProps}>{bottomRow}</Affix>)}
      </>
    );
  };

  const renderWithoutBreadcrumbs = () => {
    const content = (
      <S.TopRow $bgColor={customBg}>
        <S.TopRowInner>
          {companyLogo}
          {renderTitle()}
        </S.TopRowInner>
        {!slim && (
          <S.ButtonPlaceholder
            id={PAGE_HEADER_BUTTON_CONTAINER_ID}
            ref={affixButtonRef}
          />
        )}
      </S.TopRow>
    );

    // TODO: affix also if slim (needs special treatment)
    return slim ? content : <Affix {...affixProps}>{content}</Affix>;
  };

  const content =
    breadcrumbsItems?.length &&
    pageHeaderProps?.breadcrumbProps?.render !== false
      ? renderWithBreadcrumbs()
      : renderWithoutBreadcrumbs();

  const pageHeader = (
    <>
      <PageTitle title={title} />
      <S.Container $bgColor={customBg} $padding={customPading}>
        {slim ? <S.SlimContainer>{content}</S.SlimContainer> : content}
      </S.Container>
      {!isDefault && <ApplyCustomPageHeader />}
      <AlertContainer />
    </>
  );

  return container ? createPortal(pageHeader, container) : pageHeader;
};

PageHeader.Button = PageHeaderButton;
