import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { CbLabel } from '@npm/core/ui/components/atoms/Label';
import { type ColumnType } from '@npm/core/ui/components/molecules/Table';
import { CypressDataIds } from '@npm/core/ui/constants';
import { getFullName } from '@npm/core/ui/utils/formatters';
import {
  type AccountsBeneficialOwnerShow,
  CbBackgroundCheckState,
} from '@npm/data-access';

export const getColumns = (
  onAddId: (id: number) => void,
  onRemove: (id: number, name: string) => void,
  isMobile: boolean,
  readOnly: boolean
): ColumnType<AccountsBeneficialOwnerShow>[] => {
  const cols: ColumnType<AccountsBeneficialOwnerShow>[] = [
    {
      title: 'Full Name',
      key: 'full_name',
      render: ({ first, middle, last }) => `${first} ${middle ?? ''} ${last}`,
    },
    {
      title: 'Role',
      key: 'role',
      render: ({ role }) => role?.name,
    },
    {
      title: '',
      key: 'actions',
      width: isMobile ? 100 : undefined,
      render: ({
        government_issued_document_uploaded,
        id,
        first,
        middle,
        last,
      }) => {
        return !readOnly ? (
          <Flex gap={isMobile ? 'sm' : 'md'} justify="flex-end">
            <Button
              data-cy={
                CypressDataIds.PostOnboarding.BeneficialOwnershipAssessment
                  .GovernmentIssuedIdButton
              }
              onClick={() =>
                !government_issued_document_uploaded ? onAddId(id) : undefined
              }
              variant="outline"
              icon={
                <Icon
                  name={
                    government_issued_document_uploaded ? 'check' : 'upload'
                  }
                />
              }
              color={government_issued_document_uploaded ? 'success' : 'info'}
            >
              {!isMobile && 'Government Issued ID'}
            </Button>
            <Button
              onClick={() => onRemove(id, getFullName(first, last, middle))}
              variant="outline"
              color="error"
              icon={<Icon name="trash" />}
            />
          </Flex>
        ) : null;
      },
    },
  ];

  if (readOnly) {
    cols.push({
      title: 'Identity Verification',
      key: 'identity_verification',
      render: owner =>
        CbBackgroundCheckState.getLabel(CbLabel, owner?.identity_verification),
    });
  }

  return cols;
};
