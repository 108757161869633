import React from 'react';

import { useDarkModeContext } from '../../../../context/DarkModeContext';
import { Flex } from '../../../atoms/common';
import { TOP_ROW_MIN_HEIGHT as PAGE_HEADER_HEIGHT } from '../../../molecules/PageHeader/PageHeader.styles';
import { useLayoutConfig } from '../../Layout';

import * as S from './EmptyPageContentLayout.styles';
import { Card } from './Card';

type Props = {
  children: React.ReactNode;
};

export const EmptyPageContentLayout = ({ children }: Props) => {
  const { isDarkMode } = useDarkModeContext();
  const { header } = useLayoutConfig();
  const backgroundTopOffset = header.height + PAGE_HEADER_HEIGHT * 2;

  return (
    <Flex direction={'column'} gap={'md'}>
      <S.Background $isDarkMode={isDarkMode} $offsetTop={backgroundTopOffset} />
      {children}
    </Flex>
  );
};

EmptyPageContentLayout.Card = Card;
