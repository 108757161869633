import React from 'react';

import { type XY } from '@npm/core/ui/components/atoms/ChartTooltip/ChartTooltip.types';
import { getSideTooltipPosition } from '@npm/core/ui/components/atoms/ChartTooltip/ChartTooltip.utils';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { DATE_FORMATS, formatDate } from '@npm/core/ui/utils/formatters';
import { type Chart } from 'chart.js';
import { useTheme } from 'styled-components';

import {
  getBidsColor,
  getOffersColor,
  getTradesColor,
} from '../../../BidOfferHistory/BidOfferHistory.utils';
import { type CompanyOverviewChartSeries } from '../../../CompanyOverview.types';
import { ChartLineIcon } from '../../../components/ChartLineIcon/ChartLineIcon';
import { PointLineIcon } from '../../../components/PointLineIcon';
import { getMutualFundMarksColor } from '../../../MutualFundMarks/MutualFundMarks.utils';
import { getPrimaryRoundsColor } from '../../../PrimaryRounds/PrimaryRounds.utils';
import { getTapeDColor } from '../../../TapeD/TapeD.utils';
import { getValuations409AColor } from '../../../Valuations409A/Valuations409A.utils';
import {
  type CompanyOverviewSummaryChartDataPoint,
  type SummaryChartTooltipData,
} from '../SummaryChart.types';
import { SUMMARY_CHART_TOOLTIP_HTML_ID } from '../SummaryChart.utils';

import * as S from './SummaryChartTooltip.styles';
import { SummaryChartTooltipRow } from './SummaryChartTooltipRow';

export const SUMMARY_CHART_TOOLTIP_PPS_HEIGHT = 308;
export const SUMMARY_CHART_TOOLTIP_VAL_HEIGHT = 272;
export const SUMMARY_CHART_TOOLTIP_WIDTH = 260;

type Props = {
  date: string | undefined;
  series: CompanyOverviewChartSeries;
  chartRef: React.RefObject<
    Chart<'line', CompanyOverviewSummaryChartDataPoint[], string>
  >;
  mouse: XY;
} & SummaryChartTooltipData;

export const SummaryChartTooltip = ({
  date,
  series,
  chartRef,
  mouse,
  tapeDValue,
  bidValue,
  offerValue,
  tradeValue,
  primaryRoundValue,
  valuation409AValue,
  movingAverageValue,
}: Props) => {
  const theme = useTheme();

  const tooltipWidth = SUMMARY_CHART_TOOLTIP_WIDTH;
  const tooltipHeight =
    series === 'PPS'
      ? SUMMARY_CHART_TOOLTIP_PPS_HEIGHT
      : SUMMARY_CHART_TOOLTIP_VAL_HEIGHT;

  if (!chartRef.current) {
    return null;
  }

  const tooltipPosition = getSideTooltipPosition(
    { x: mouse.x, y: mouse.y },
    chartRef.current.chartArea,
    tooltipWidth,
    tooltipHeight,
    20
  );

  return (
    <S.Tooltip
      id={SUMMARY_CHART_TOOLTIP_HTML_ID}
      className="html-chart-tooltip"
      $width={tooltipWidth}
      $height={tooltipHeight}
      $left={tooltipPosition.x}
      $top={tooltipPosition.y}
    >
      <Flex direction={'column'} gap={'sm'}>
        <S.Header>
          <Text size={'sm'} weight={'bold'}>
            Summary ({series === 'PPS' ? 'PPS' : 'Val.'})
          </Text>

          <Text size={'xs'} colorVariant={'secondary'}>
            {formatDate(date, { dateFormat: DATE_FORMATS.DATE })}
          </Text>
        </S.Header>
        <S.Body>
          <SummaryChartTooltipRow
            icon={<ChartLineIcon color={getTapeDColor(theme)} />}
            label={'Tape D'}
            value={tapeDValue}
          />
          <SummaryChartTooltipRow
            icon={<PointLineIcon color={getPrimaryRoundsColor(theme)} />}
            label={'Primary Round'}
            value={primaryRoundValue}
          />
          <SummaryChartTooltipRow
            icon={<Icon name="segment" size="xs" color={getTradesColor()} />}
            label={'Reported Trade Level'}
            value={tradeValue}
          />
          <SummaryChartTooltipRow
            icon={<Icon name="segment" size="xs" color={getBidsColor(theme)} />}
            label={'Bid Level'}
            value={bidValue}
          />
          <SummaryChartTooltipRow
            icon={
              <Icon name="segment" size="xs" color={getOffersColor(theme)} />
            }
            label={'Ask Level'}
            value={offerValue}
          />

          {series === 'PPS' && (
            <SummaryChartTooltipRow
              icon={<PointLineIcon color={getValuations409AColor(theme)} />}
              label={'409A Valuation'}
              value={valuation409AValue}
            />
          )}
          <SummaryChartTooltipRow
            icon={<ChartLineIcon color={getMutualFundMarksColor(theme)} />}
            label={'Mutual Fund Mark'}
            value={movingAverageValue}
          />
        </S.Body>
      </Flex>
    </S.Tooltip>
  );
};
