import { Card as BaseCard } from '@npm/core/ui/components/molecules/Card';
import styled from 'styled-components';

export const PersonShieldIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: ${({ theme }) => theme.borderRadius.md}px;
  background-color: ${({ theme }) => theme.color.general.layout.two};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Card = styled(BaseCard)`
  min-height: 472px;
  .card-content-container {
    align-items: center;
    .card-content {
      flex-direction: row;
      max-width: 462px;
      justify-content: center;
    }
  }
`;
