import { type ForwardRefExoticComponent } from 'react';

import { type IconProps } from '@npm/utils';
import { Button as AntButton, type ButtonProps } from 'antd';
import styled, { css, type DefaultTheme } from 'styled-components';

import { useBreakpoint } from '../../../utils/style';
import {
  BodyTextBoldVariationSettingsMobile,
  SmallTextBoldVariationSettings,
  SmallTextStyles,
} from '../Typography';

import {
  type ButtonColor,
  type ButtonSize,
  type ButtonVariant,
  type IconPosition,
} from './Button.types';

export const BUTTON_HEIGHT_LG = 40;
export const BUTTON_HEIGHT_MD = 32;
export const BUTTON_HEIGHT_SM = 24;

// shifted spread by 2px as we use shadow also for borders
export const BUTTON_ACTIVE_SHADOW = '0px 1px 6px 0px rgba(91, 104, 135, 0.1)';

export const getSize = (
  size: ButtonSize,
  iconOnly: boolean,
  theme: DefaultTheme
) => {
  const sizes = {
    sm: {
      height: `${BUTTON_HEIGHT_SM}px`,
      width: iconOnly ? `${BUTTON_HEIGHT_SM}px` : 'auto',
      padding: iconOnly ? 0 : `0 ${theme.spacing.sm}px`,
    },
    md: {
      height: `${BUTTON_HEIGHT_MD}px`,
      width: iconOnly ? `${BUTTON_HEIGHT_MD}px` : 'auto',
      padding: iconOnly ? 0 : `0 ${theme.spacing.lg / 2}px`,
    },
    lg: {
      height: `${BUTTON_HEIGHT_LG}px`,
      width: iconOnly ? `${BUTTON_HEIGHT_LG}px` : 'auto',
      padding: iconOnly ? 0 : `0 ${theme.spacing.md}px`,
    },
  };
  return sizes[size];
};

export const getVariant = (
  variant: ButtonVariant,
  color: ButtonColor,
  theme: DefaultTheme
) => {
  const defaultCss = css`
    color: ${theme.color.grey_0};
    background-color: ${theme.color[color].backgrounds.primaryDefault};
    border: none;
  `;
  const outlineCss = css`
    color: ${theme.color[color].typography.primary};
    background-color: ${theme.color[color].backgrounds.secondaryDefault};
    border: none;
    box-shadow:
      inset 0 0 0 1px ${theme.color[color].borders.primary},
      0 0 0 0;
  `;
  const textCss = css`
    color: ${theme.color[color].typography.primary};
    background: none;
    border: none;
  `;

  return {
    default: css`
      ${defaultCss};

      &:not(.ant-btn-disabled, .ant-btn-loading) {
        &:hover,
        &:active,
        &:focus {
          background-color: ${theme.color[color].backgrounds.primaryHover};
        }

        body.tab-focus &.button-${color}:focus, &:active {
          box-shadow:
            0 0 0 2px ${theme.color[color].borders.primary},
            ${BUTTON_ACTIVE_SHADOW};
        }
      }

      &.ant-btn-disabled {
        background-color: ${theme.color.general.backgrounds.muted};
        color: ${theme.color.general.typography.tertiary};
      }

      &:is(.ant-btn-loading) {
        &:hover,
        &:active,
        &:focus {
          ${defaultCss};
        }
      }

      & .ant-spin-spinning {
        & > .ant-spin-dot {
          color: ${({ theme }) => theme.color.grey_0};
        }
      }
    `,

    outline: css`
      ${outlineCss};

      &:not(.ant-btn-disabled, .ant-btn-loading, :disabled) {
        &:hover,
        &:active,
        &:focus {
          background-color: ${theme.color[color].backgrounds.secondaryHover};
        }

        body.tab-focus &.button-${color}:focus, &:active {
          box-shadow:
            inset 0 0 0 0,
            0 0 0 2px ${theme.color[color].borders.primary},
            ${BUTTON_ACTIVE_SHADOW};
        }
      }

      &.ant-btn-disabled,
      :disabled {
        color: ${theme.color.general.typography.tertiary};
        background-color: ${theme.color.general.layout.two};
        box-shadow:
          inset 0 0 0 1px ${theme.color.general.borders.secondary},
          0 0 0 0;
      }

      &:is(.ant-btn-loading) {
        &:hover,
        &:active,
        &:focus {
          ${outlineCss};
        }
      }

      & .ant-spin-spinning > .ant-spin-dot {
        color: ${theme.color[color].typography.primary};
      }
    `,

    text: css`
      ${textCss};

      &:not(.ant-btn-disabled, .ant-btn-loading) {
        &:hover,
        &:active,
        &:focus {
          background-color: ${theme.color.general.layout.two};
        }

        body.tab-focus &.button-${color}:focus, &:active {
          box-shadow:
            0 0 0 2px ${theme.color[color].borders.primary},
            ${BUTTON_ACTIVE_SHADOW};
        }
      }

      &.ant-btn-disabled {
        color: ${theme.color.general.typography.tertiary};
        background: none;
        border: none;
      }

      &:is(.ant-btn-loading) {
        &:hover,
        &:active,
        &:focus {
          ${textCss};
        }
      }

      & .ant-spin-spinning > .ant-spin-dot {
        color: ${theme.color[color].typography.primary};
      }
    `,
  }[variant];
};

// [Antd Typings] Ant'd internal types are not exported, need to re-type.
// $buttonColor instead of $color because it was colliding with SmallTextStyles css
export const StyledButton = styled<ForwardRefExoticComponent<ButtonProps>>(
  AntButton
)<{
  $variant: ButtonVariant;
  $buttonColor: ButtonColor;
  $size: ButtonSize;
  $iconPosition: IconPosition;
  $iconOnly: boolean;
  $iconSize: IconProps['size'];
  $hasIcon: boolean;
  $blockOnMobile: boolean;
}>`
  &.ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: ${({ $iconPosition }) =>
      $iconPosition === 'right' ? 'row-reverse' : 'row'};
    gap: ${({ theme }) => theme.spacing.xs}px;
    min-width: ${({ $iconOnly }) => ($iconOnly ? 'auto' : '80px')};
    border-radius: ${({ theme }) => theme.borderRadius.md}px;
    box-shadow: none;
    transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); // shortening antd click transition between states

    ${SmallTextStyles};
    ${SmallTextBoldVariationSettings};

    ${({ theme, $size, $iconOnly }) => getSize($size, $iconOnly, theme)};
    ${({ $variant, $buttonColor, theme }) =>
      getVariant($variant, $buttonColor, theme)};

    ${({
      theme,
      $blockOnMobile,
      $variant,
      $buttonColor,
      $iconOnly,
    }) => useBreakpoint(theme).mobile`
      width: ${$blockOnMobile && !$iconOnly && '100%'};
      font-size: ${theme.typography.size.sm}px;
      line-height: ${theme.typography.height.sm}px;
      ${getVariant($variant, $buttonColor, theme)}};
      ${BodyTextBoldVariationSettingsMobile}; 
    `}
    svg {
      ${({ theme, $iconSize = 'xs' }) =>
        typeof $iconSize === 'number'
          ? `
          width: ${$iconSize}px;
          height: ${$iconSize}px;
        `
          : `
          width: ${theme.icon.size[$iconSize]}px;
          height: ${theme.icon.size[$iconSize]}px;
        `}
    }

    &.ant-btn-block {
      width: 100%;
    }

    &[ant-click-animating-without-extra-node='true']::after {
      display: none; // remove antd click animation
    }

    & > .ant-btn-loading-icon {
      .anticon {
        padding-right: 2px; //fine-tuning
      }
    }
  }
`;
