/* eslint-disable react/no-multi-comp */
import React, { useCallback } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { CypressDataIds } from '@npm/core/ui/constants';
import { downloadBlob } from '@npm/core/ui/utils/apiHelper';
import { useDocumentDownloadLazy } from '@npm/data-access';

import { Header } from '../../auth/user/context/store/role/role.store';
import { useObo } from '../../auth/user/role';

import * as S from './DocumentViewerModal.styles';
import { type Document, type DocumentWithId } from './DocumentViewer.types';
import { WebviewerWrapper } from './WebviewerWrapper';

type Props = {
  document: Document;
  documentMetadata:
    | {
        displayName: string | null | undefined;
        downloadable: boolean | null | undefined;
      }
    | null
    | undefined;
  isOpen: boolean;
  handleCancel: () => void;
  handleDownload?: () => void;
  includeOboHeaders?: boolean;
};

const ModalFooter = ({
  handleCancel,
  document,
  documentMetadata,
  handleDownload,
  includeOboHeaders,
}: Pick<
  Props,
  | 'handleCancel'
  | 'documentMetadata'
  | 'document'
  | 'handleDownload'
  | 'includeOboHeaders'
>) => {
  const { isObo } = useObo();
  const [executeDownload, { isLoading }] = useDocumentDownloadLazy({
    config: {
      axiosConfig: {
        responseType: 'blob',
        ...(isObo &&
          !includeOboHeaders && {
            headers: {
              [Header['OboAccountId']]: undefined,
              [Header['OboUserId']]: undefined,
            },
          }),
      },
    },
  });

  const npmDocumentId = document?.type === 'with-npm-id' ? document.id : null;
  const downloadNpmDocument = useCallback(async () => {
    try {
      const data = await executeDownload({ variables: { id: npmDocumentId } });
      downloadBlob(data, documentMetadata?.displayName);
    } catch (e) {
      console.error(e);
    }
  }, [npmDocumentId, documentMetadata]);

  return (
    <S.FooterWrapper>
      <Button variant={'default'} onClick={handleCancel} blockOnMobile>
        Close
      </Button>
      {(documentMetadata?.downloadable && !!npmDocumentId) || handleDownload ? (
        <Button
          variant={'outline'}
          loading={isLoading}
          blockOnMobile
          onClick={handleDownload ? handleDownload : downloadNpmDocument}
          icon={<Icon name="download" />}
        >
          Download
        </Button>
      ) : null}
    </S.FooterWrapper>
  );
};

export const DocumentViewerModal = ({
  isOpen,
  handleCancel,
  document,
  documentMetadata,
  handleDownload,
  includeOboHeaders = true,
}: Props) => {
  return (
    <S.Modal
      data-cy={CypressDataIds.DocumentPreview.Modal}
      destroyOnClose={true}
      open={isOpen}
      title={documentMetadata?.displayName ?? 'Document preview'}
      onCancel={handleCancel}
      footer={
        <ModalFooter
          handleCancel={handleCancel}
          documentMetadata={documentMetadata}
          document={document}
          handleDownload={handleDownload}
          includeOboHeaders={includeOboHeaders}
        />
      }
    >
      <WebviewerWrapper
        key={(document as DocumentWithId)?.id}
        document={document}
        mode="full"
        handleClose={handleCancel}
        includeOboHeaders={includeOboHeaders}
      />
    </S.Modal>
  );
};
