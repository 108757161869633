import React, { useEffect, useMemo } from 'react';

import { Margin } from '@npm/core/ui/components/atoms/common';
import { Divider } from '@npm/core/ui/components/atoms/Divider';
import {
  FormItem,
  InputFormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { Input } from '@npm/core/ui/components/atoms/Input';
import { Radio, RadioGroup } from '@npm/core/ui/components/atoms/Radio';
import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { Heading } from '@npm/core/ui/components/atoms/Typography/Heading';
import { TwoColumnsModalContent } from '@npm/core/ui/components/molecules/TwoColumnsModalContent';
import { getFullName } from '@npm/core/ui/utils/formatters';
import {
  type AccountIndex,
  CbAccountType,
  useHoldingIndex,
} from '@npm/data-access';
import { AccountPanel } from '@npm/features/account/components/AccountPanel';
import { WatchlistInput } from '@npm/features/account/WatchlistInput';
import { Signature } from '@npm/features/auth/user/components/Signature';
import { type UserStore } from '@npm/features/auth/user/context/store/user/user.store';
import { Form, type FormInstance } from 'antd';

import { OptInEntityColorVariant, OptInIcon, OptInMode } from '../OptIn.enums';
import { type OptInFormValues } from '../OptInEntitySelection/OptInEntitySelection.types';

import { CompaniesTagsInvestor } from './CompaniesTagInvestor/CompaniesTagInvestor';

import * as S from './OptInSignature.styles';
import { Disclaimer } from './Disclaimer';
import { EntityBenefits } from './EntityBenefits';
import { EntitySelect } from './EntitySelect';
import { TransferList } from './TransferList';

interface Props {
  eligibleEntities: AccountIndex;
  form: FormInstance<OptInFormValues>;
  optInMode: OptInMode;
  setIsCancelling: (isCancelling: boolean) => void;
  setOptinMode: (mode: OptInMode) => void;
  user: UserStore['user'];
  isLoadingEntities: boolean;
}

const entityType = CbAccountType.items.OrganizationAccount;
const individualType = CbAccountType.items.PersonAccount;

const BENEFITS_CONFIG: Record<
  string,
  {
    title: string;
    colorVariant: OptInEntityColorVariant;
    icon: OptInIcon;
  }
> = {
  [individualType]: {
    title: 'Individual / Employee',
    colorVariant: OptInEntityColorVariant.INDIVIDUAL,
    icon: OptInIcon.INDIVIDUAL,
  },
  [entityType]: {
    title: 'Institutional Entity',
    colorVariant: OptInEntityColorVariant.INSTITUTION,
    icon: OptInIcon.INSTITUTION,
  },
};

export const OptInSignature = ({
  form,
  user,
  optInMode,
  setIsCancelling,
  setOptinMode,
  eligibleEntities,
  isLoadingEntities,
}: Props) => {
  const accountType = Form.useWatch('accountType', form);
  const selectedEntity = Form.useWatch('selectedEntity', form);
  const watchlist = Form.useWatch('watchlist', form);
  const benefitsProps = BENEFITS_CONFIG[accountType];

  const { isLoading: isHoldingsLoading, data } = useHoldingIndex(
    {
      accountId: selectedEntity,
      page: 1,
      size: 100,
    },
    {
      queryConfig: {
        enabled: !!selectedEntity || accountType === individualType,
      },
      onComplete: data => {
        form.setFieldValue(
          'watchlist',
          data?.holdings?.map(holding => holding.issuer_entity.id.toString())
        );
      },
    }
  );

  const hasEligibleIndividualEntities = eligibleEntities?.accounts?.some(
    account => account.type.code === individualType
  );
  const hasEligibleInstitutionalEntities = eligibleEntities?.accounts?.some(
    account => account.type.code === entityType
  );

  useEffect(() => {
    if (
      accountType === entityType &&
      eligibleEntities?.accounts?.filter(acc => acc.type.code === entityType)
        .length === 1 &&
      !selectedEntity
    ) {
      form.setFieldValue('selectedEntity', eligibleEntities.accounts[0].id);
    }
  }, [eligibleEntities, selectedEntity, form, accountType]);

  useEffect(() => {
    // set the Opt in mode to create when there is no entity to copy
    // (the radio button is hidden in this case)
    if (
      (accountType === entityType && !hasEligibleInstitutionalEntities) ||
      (accountType === individualType && !hasEligibleIndividualEntities)
    ) {
      setOptinMode(OptInMode.CREATE_ACCOUNT);
    } else {
      setOptinMode(OptInMode.COPY_ACCOUNT);
    }
  }, [
    accountType,
    hasEligibleIndividualEntities,
    hasEligibleInstitutionalEntities,
    setOptinMode,
  ]);

  const watchlistHoldings = useMemo(
    () =>
      data?.holdings?.filter(holding =>
        watchlist?.includes(holding.issuer_entity.id.toString())
      ) || [],
    [data, watchlist]
  );

  return (
    <TwoColumnsModalContent
      onCancel={() => setIsCancelling(true)}
      contentLeft={
        <S.Container>
          <Heading variant="h2" marginBottom="md">
            Link your account to our Marketplace
          </Heading>

          {(accountType === individualType && hasEligibleIndividualEntities) ||
            (accountType === entityType && hasEligibleInstitutionalEntities && (
              <RadioGroup
                onChange={e => setOptinMode(e.target.value)}
                value={optInMode}
              >
                <Radio
                  value={OptInMode.COPY_ACCOUNT}
                  label="Select existing account"
                />
                <Radio
                  value={OptInMode.CREATE_ACCOUNT}
                  label="Create new account"
                />
              </RadioGroup>
            ))}

          {accountType === individualType && (
            <AccountPanel
              showTitle={false}
              accountName={getFullName(
                user?.person?.first,
                user?.person?.middle,
                user?.person?.last
              )}
              brokerageFirmName={user?.active_role?.subject.brokerage_firm_name}
              accountExternalId={user?.active_role?.subject.external_id}
            />
          )}

          <Divider marginTop="lg" marginBottom="lg" />

          {accountType === entityType &&
            optInMode === OptInMode.COPY_ACCOUNT && (
              <EntitySelect
                eligibleEntities={eligibleEntities}
                form={form}
                isLoadingEntities={isLoadingEntities}
              />
            )}

          {accountType === entityType &&
            optInMode === OptInMode.CREATE_ACCOUNT && (
              <InputFormItem
                name={'legalName'}
                rules={[
                  VALIDATION_RULES.required('Legal Name'),
                  VALIDATION_RULES.min(5),
                  VALIDATION_RULES.max(120),
                ]}
                validateTrigger={['onChange', 'onSubmit']}
              >
                <Input placeholder="Legal Name" />
              </InputFormItem>
            )}

          {optInMode === OptInMode.COPY_ACCOUNT &&
            (selectedEntity || accountType === individualType) && (
              <TransferList user={user} />
            )}

          {accountType === individualType &&
            (optInMode === OptInMode.CREATE_ACCOUNT ? (
              <FormItem
                name={'watchlist'}
                label={"Which company's stock do you own?"}
                rules={[
                  VALIDATION_RULES.arrayMinLength(
                    1,
                    'Please select at least one company'
                  ),
                ]}
                extra={
                  <Text size={'sm'} colorVariant={'secondary'}>
                    Browse through the pre-IPO companies we work with
                  </Text>
                }
              >
                {isHoldingsLoading ? (
                  <Skeleton.Base paragraph={false} />
                ) : (
                  <WatchlistInput />
                )}
              </FormItem>
            ) : (
              <FormItem name={'watchlist'}>
                <CompaniesTagsInvestor
                  watchlist={watchlistHoldings.map(
                    ({ issuer_entity }) => issuer_entity
                  )}
                />
              </FormItem>
            ))}
          <Margin bottom="md" />

          <Disclaimer />
          <Signature />
        </S.Container>
      }
      contentRight={
        <div>
          <EntityBenefits form={form} {...benefitsProps} />
        </div>
      }
    />
  );
};
