import React from 'react';

import { Checkbox } from '@npm/core/ui/components/atoms/Checkbox';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { CbLabel, Label } from '@npm/core/ui/components/atoms/Label';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { CbHoldingState, type Holding } from '@npm/data-access';

import * as S from '../HoldingMultiSelect.styles';
import { getHoldingCertificateNumber } from '../../../../../holdings';

type Props = {
  holding: Holding;
  isSelected: boolean;
};

export const HoldingOption = ({ holding, isSelected }: Props) => {
  return (
    <Flex align={'center'}>
      <Checkbox checked={isSelected} label={null} />
      <Flex direction={'column'} style={{ flex: 1 }}>
        <Text>{holding.asset?.type?.name}</Text>
        <Flex align={'center'} gap={'xs'}>
          <Label variant={'info'} round>
            {getHoldingCertificateNumber(holding)}
          </Label>
          <S.QuantityLabel>QTY {holding.quantity}</S.QuantityLabel>
          <div style={{ marginLeft: 'auto' }}>
            {CbHoldingState.getLabel(CbLabel, holding.state, true)}
          </div>
        </Flex>
      </Flex>
    </Flex>
  );
};
