// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  AdminPartnerApi,
  EntityIndexErrorCodes,
  EntityIndexErrorTypes,
  EntityUpdateErrorCodes,
  ListActivePartnersErrorCodes,
  ListActivePartnersErrorTypes,
  ActivateAndAssignErrorCodes,
  ListVerifiedTradesErrorCodes,
  ListVerifiedTradesErrorTypes,
  ListCapTableVersionsErrorCodes,
  ListCapTableVersionsErrorTypes,
  ExportCsvCapTableVersionErrorCodes,
  ExportCsvCapTableVersionErrorTypes,
  S3FileDownloadErrorCodes,
  S3FileDownloadErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new AdminPartnerApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type EntityIndexMethod = AdminPartnerApi['entityIndex'];
export type EntityIndexResponseType = MethodResult<EntityIndexMethod>;

export const useEntityIndex = (
  variables: Parameters<EntityIndexMethod>[0],
  config?: UseQueryConfig<
    EntityIndexMethod,
    EntityIndexErrorTypes
  >
) => {
  return useQuery<
    EntityIndexMethod,
    EntityIndexErrorTypes
  >(
    (...args) => api.entityIndex(...args),
    EntityIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EntityIndex'],
        ...apiQueryConfig.EntityIndex,
        ...config?.queryConfig
      },
    },
    'EntityIndex',
  );
};

export const useEntityIndexInfinite = (
  variables: Parameters<EntityIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    EntityIndexMethod,
    EntityIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    EntityIndexMethod,
    EntityIndexErrorTypes
  >(
    (...args) => api.entityIndex(...args),
    EntityIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EntityIndex', 'EntityIndexInfinite'],
        ...apiQueryConfig.EntityIndex,
        ...config?.queryConfig,
      },
    },
    'EntityIndex',
  );
};

export const useEntityIndexLazy = (baseOptions?: {
  variables?: Parameters<EntityIndexMethod>[0],
  config?: UseQueryConfig<
    EntityIndexMethod,
    EntityIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    EntityIndexMethod,
    EntityIndexErrorTypes
  >(
    (...args) => api.entityIndex(...args),
    EntityIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['EntityIndex'],
        ...apiQueryConfig.EntityIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'EntityIndex',
  );
};


export type EntityUpdateMethod = AdminPartnerApi['entityUpdate'];

export const useEntityUpdate = () => {
  return useMutation<EntityUpdateMethod>(
    (...args) => api.entityUpdate(...args),
    EntityUpdateErrorCodes,
    'EntityUpdate',
    '/api/admin/partner/{entity}/{id}',
    'patch'
  );
};

export type ListActivePartnersMethod = AdminPartnerApi['listActivePartners'];
export type ListActivePartnersResponseType = MethodResult<ListActivePartnersMethod>;

export const useListActivePartners = (
  variables: Parameters<ListActivePartnersMethod>[0],
  config?: UseQueryConfig<
    ListActivePartnersMethod,
    ListActivePartnersErrorTypes
  >
) => {
  return useQuery<
    ListActivePartnersMethod,
    ListActivePartnersErrorTypes
  >(
    (...args) => api.listActivePartners(...args),
    ListActivePartnersErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['ListActivePartners'],
        ...apiQueryConfig.ListActivePartners,
        ...config?.queryConfig
      },
    },
    'ListActivePartners',
  );
};

export const useListActivePartnersInfinite = (
  variables: Parameters<ListActivePartnersMethod>[0],
  config?: UseInfiniteQueryConfig<
    ListActivePartnersMethod,
    ListActivePartnersErrorTypes
  >
) => {
  return useInfiniteQuery<
    ListActivePartnersMethod,
    ListActivePartnersErrorTypes
  >(
    (...args) => api.listActivePartners(...args),
    ListActivePartnersErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['ListActivePartners', 'ListActivePartnersInfinite'],
        ...apiQueryConfig.ListActivePartners,
        ...config?.queryConfig,
      },
    },
    'ListActivePartners',
  );
};

export const useListActivePartnersLazy = (baseOptions?: {
  variables?: Parameters<ListActivePartnersMethod>[0],
  config?: UseQueryConfig<
    ListActivePartnersMethod,
    ListActivePartnersErrorTypes
  >
}) => {
  return useLazyQuery<
    ListActivePartnersMethod,
    ListActivePartnersErrorTypes
  >(
    (...args) => api.listActivePartners(...args),
    ListActivePartnersErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['ListActivePartners'],
        ...apiQueryConfig.ListActivePartners,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'ListActivePartners',
  );
};


export type ActivateAndAssignMethod = AdminPartnerApi['activateAndAssign'];

export const useActivateAndAssign = () => {
  return useMutation<ActivateAndAssignMethod>(
    (...args) => api.activateAndAssign(...args),
    ActivateAndAssignErrorCodes,
    'ActivateAndAssign',
    '/api/admin/partner/activate_and_assign',
    'patch'
  );
};

export type ListVerifiedTradesMethod = AdminPartnerApi['listVerifiedTrades'];
export type ListVerifiedTradesResponseType = MethodResult<ListVerifiedTradesMethod>;

export const useListVerifiedTrades = (
  variables: Parameters<ListVerifiedTradesMethod>[0],
  config?: UseQueryConfig<
    ListVerifiedTradesMethod,
    ListVerifiedTradesErrorTypes
  >
) => {
  return useQuery<
    ListVerifiedTradesMethod,
    ListVerifiedTradesErrorTypes
  >(
    (...args) => api.listVerifiedTrades(...args),
    ListVerifiedTradesErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['ListVerifiedTrades'],
        ...apiQueryConfig.ListVerifiedTrades,
        ...config?.queryConfig
      },
    },
    'ListVerifiedTrades',
  );
};

export const useListVerifiedTradesInfinite = (
  variables: Parameters<ListVerifiedTradesMethod>[0],
  config?: UseInfiniteQueryConfig<
    ListVerifiedTradesMethod,
    ListVerifiedTradesErrorTypes
  >
) => {
  return useInfiniteQuery<
    ListVerifiedTradesMethod,
    ListVerifiedTradesErrorTypes
  >(
    (...args) => api.listVerifiedTrades(...args),
    ListVerifiedTradesErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['ListVerifiedTrades', 'ListVerifiedTradesInfinite'],
        ...apiQueryConfig.ListVerifiedTrades,
        ...config?.queryConfig,
      },
    },
    'ListVerifiedTrades',
  );
};

export const useListVerifiedTradesLazy = (baseOptions?: {
  variables?: Parameters<ListVerifiedTradesMethod>[0],
  config?: UseQueryConfig<
    ListVerifiedTradesMethod,
    ListVerifiedTradesErrorTypes
  >
}) => {
  return useLazyQuery<
    ListVerifiedTradesMethod,
    ListVerifiedTradesErrorTypes
  >(
    (...args) => api.listVerifiedTrades(...args),
    ListVerifiedTradesErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['ListVerifiedTrades'],
        ...apiQueryConfig.ListVerifiedTrades,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'ListVerifiedTrades',
  );
};


export type ListCapTableVersionsMethod = AdminPartnerApi['listCapTableVersions'];
export type ListCapTableVersionsResponseType = MethodResult<ListCapTableVersionsMethod>;

export const useListCapTableVersions = (
  variables: Parameters<ListCapTableVersionsMethod>[0],
  config?: UseQueryConfig<
    ListCapTableVersionsMethod,
    ListCapTableVersionsErrorTypes
  >
) => {
  return useQuery<
    ListCapTableVersionsMethod,
    ListCapTableVersionsErrorTypes
  >(
    (...args) => api.listCapTableVersions(...args),
    ListCapTableVersionsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['ListCapTableVersions'],
        ...apiQueryConfig.ListCapTableVersions,
        ...config?.queryConfig
      },
    },
    'ListCapTableVersions',
  );
};

export const useListCapTableVersionsInfinite = (
  variables: Parameters<ListCapTableVersionsMethod>[0],
  config?: UseInfiniteQueryConfig<
    ListCapTableVersionsMethod,
    ListCapTableVersionsErrorTypes
  >
) => {
  return useInfiniteQuery<
    ListCapTableVersionsMethod,
    ListCapTableVersionsErrorTypes
  >(
    (...args) => api.listCapTableVersions(...args),
    ListCapTableVersionsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['ListCapTableVersions', 'ListCapTableVersionsInfinite'],
        ...apiQueryConfig.ListCapTableVersions,
        ...config?.queryConfig,
      },
    },
    'ListCapTableVersions',
  );
};

export const useListCapTableVersionsLazy = (baseOptions?: {
  variables?: Parameters<ListCapTableVersionsMethod>[0],
  config?: UseQueryConfig<
    ListCapTableVersionsMethod,
    ListCapTableVersionsErrorTypes
  >
}) => {
  return useLazyQuery<
    ListCapTableVersionsMethod,
    ListCapTableVersionsErrorTypes
  >(
    (...args) => api.listCapTableVersions(...args),
    ListCapTableVersionsErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['ListCapTableVersions'],
        ...apiQueryConfig.ListCapTableVersions,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'ListCapTableVersions',
  );
};


export type ExportCsvCapTableVersionMethod = AdminPartnerApi['exportCsvCapTableVersion'];
export type ExportCsvCapTableVersionResponseType = MethodResult<ExportCsvCapTableVersionMethod>;

export const useExportCsvCapTableVersion = (
  variables: Parameters<ExportCsvCapTableVersionMethod>[0],
  config?: UseQueryConfig<
    ExportCsvCapTableVersionMethod,
    ExportCsvCapTableVersionErrorTypes
  >
) => {
  return useQuery<
    ExportCsvCapTableVersionMethod,
    ExportCsvCapTableVersionErrorTypes
  >(
    (...args) => api.exportCsvCapTableVersion(...args),
    ExportCsvCapTableVersionErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['ExportCsvCapTableVersion'],
        ...apiQueryConfig.ExportCsvCapTableVersion,
        ...config?.queryConfig
      },
    },
    'ExportCsvCapTableVersion',
  );
};

export const useExportCsvCapTableVersionInfinite = (
  variables: Parameters<ExportCsvCapTableVersionMethod>[0],
  config?: UseInfiniteQueryConfig<
    ExportCsvCapTableVersionMethod,
    ExportCsvCapTableVersionErrorTypes
  >
) => {
  return useInfiniteQuery<
    ExportCsvCapTableVersionMethod,
    ExportCsvCapTableVersionErrorTypes
  >(
    (...args) => api.exportCsvCapTableVersion(...args),
    ExportCsvCapTableVersionErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['ExportCsvCapTableVersion', 'ExportCsvCapTableVersionInfinite'],
        ...apiQueryConfig.ExportCsvCapTableVersion,
        ...config?.queryConfig,
      },
    },
    'ExportCsvCapTableVersion',
  );
};

export const useExportCsvCapTableVersionLazy = (baseOptions?: {
  variables?: Parameters<ExportCsvCapTableVersionMethod>[0],
  config?: UseQueryConfig<
    ExportCsvCapTableVersionMethod,
    ExportCsvCapTableVersionErrorTypes
  >
}) => {
  return useLazyQuery<
    ExportCsvCapTableVersionMethod,
    ExportCsvCapTableVersionErrorTypes
  >(
    (...args) => api.exportCsvCapTableVersion(...args),
    ExportCsvCapTableVersionErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['ExportCsvCapTableVersion'],
        ...apiQueryConfig.ExportCsvCapTableVersion,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'ExportCsvCapTableVersion',
  );
};


export type S3FileDownloadMethod = AdminPartnerApi['s3FileDownload'];
export type S3FileDownloadResponseType = MethodResult<S3FileDownloadMethod>;

export const useS3FileDownload = (
  variables: Parameters<S3FileDownloadMethod>[0],
  config?: UseQueryConfig<
    S3FileDownloadMethod,
    S3FileDownloadErrorTypes
  >
) => {
  return useQuery<
    S3FileDownloadMethod,
    S3FileDownloadErrorTypes
  >(
    (...args) => api.s3FileDownload(...args),
    S3FileDownloadErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['S3FileDownload'],
        ...apiQueryConfig.S3FileDownload,
        ...config?.queryConfig
      },
    },
    'S3FileDownload',
  );
};

export const useS3FileDownloadInfinite = (
  variables: Parameters<S3FileDownloadMethod>[0],
  config?: UseInfiniteQueryConfig<
    S3FileDownloadMethod,
    S3FileDownloadErrorTypes
  >
) => {
  return useInfiniteQuery<
    S3FileDownloadMethod,
    S3FileDownloadErrorTypes
  >(
    (...args) => api.s3FileDownload(...args),
    S3FileDownloadErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['S3FileDownload', 'S3FileDownloadInfinite'],
        ...apiQueryConfig.S3FileDownload,
        ...config?.queryConfig,
      },
    },
    'S3FileDownload',
  );
};

export const useS3FileDownloadLazy = (baseOptions?: {
  variables?: Parameters<S3FileDownloadMethod>[0],
  config?: UseQueryConfig<
    S3FileDownloadMethod,
    S3FileDownloadErrorTypes
  >
}) => {
  return useLazyQuery<
    S3FileDownloadMethod,
    S3FileDownloadErrorTypes
  >(
    (...args) => api.s3FileDownload(...args),
    S3FileDownloadErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['S3FileDownload'],
        ...apiQueryConfig.S3FileDownload,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'S3FileDownload',
  );
};

