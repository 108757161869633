import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { type Holding } from '@npm/data-access';

import { getHoldingCertificateNumber } from '../../../../../holdings';

import * as S from './HoldingTag.styles';

type Props = {
  holding: Holding;
  onClose: () => void;
};

export const HoldingTag = ({ holding, onClose }: Props) => {
  return (
    <S.Tag>
      {getHoldingCertificateNumber(holding)}
      <S.IconWrapper onClick={onClose}>
        <Icon name={'x-close'} size={'sm'} />
      </S.IconWrapper>
    </S.Tag>
  );
};
