import React, { useState } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { ButtonCard } from '@npm/core/ui/components/atoms/ButtonCard';
import { Flex, InfoIconWrap } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { ProgressBar } from '@npm/core/ui/components/atoms/ProgressBar';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { Heading } from '@npm/core/ui/components/atoms/Typography';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { InstitutionalBuyerAssets } from '@npm/data-access';
import { Form, Radio } from 'antd';
import { useTheme } from 'styled-components';

import { SignatureFormContent } from '../../components/SignatureModal';

import { useQualifiedInstitutionalBuyerQuestionnaireForm } from './QualifiedInstitutionalBuyerQuestionnaireForm.hooks';
import { QualifiedInstitutionalBuyerQuestionnaireFormContent } from './QualifiedInstitutionalBuyerQuestionnaireFormContent';

const QUALIFIED_INSTITUTIONAL_BUYER_FORM_ID =
  'qualified-institutional-buyer-form';

enum ModalSteps {
  ASSETS = 'institutional_buyer_assets_question',
  QUESTION = 'institutional_buyer_question',
  SIGNATURE = 'signature',
}
const institutionalBuyerModalSteps: ModalSteps[] = [
  ModalSteps.ASSETS,
  ModalSteps.QUESTION,
  ModalSteps.SIGNATURE,
];

export type Props = {
  accreditationId: number;
  accountId: number;
  isOpen: boolean;
  closeModal: () => void;
};
export const QualifiedInstitutionalBuyerModal: React.FC<Props> = ({
  accreditationId,
  accountId,
  isOpen,
  closeModal,
}) => {
  const [activeStep, setActiveStep] = useState(ModalSteps.ASSETS);

  const theme = useTheme();
  const { isMobile } = useBreakpoints();

  const { form, handleSubmit, data, isUpdating } =
    useQualifiedInstitutionalBuyerQuestionnaireForm(
      accreditationId,
      accountId,
      () => {
        closeModal();
        Notification.success({
          message: 'You have updated your Sophistication Level',
        });
      }
    );

  const qualifiedInstitutionalBuyer = Form.useWatch(
    'qualified_institutional_buyer',
    form
  );

  return (
    <Modal
      size="md"
      isFullScreen={isMobile}
      fullScreenProps={{
        isFooterFixed: true,
        noHeader: false,
        onClose: closeModal,
        titleHeadingProps: {
          style: { marginBottom: 0 },
        },
        icon: <Icon name="x-close" size="md" />,
      }}
      title="Qualified Institutional Buyer Questionnaire"
      open={isOpen}
      onCancel={closeModal}
      footer={
        <Flex
          key={activeStep}
          justify="space-between"
          direction={isMobile ? 'column-reverse' : 'row'}
          gap={isMobile ? 'sm' : 'md'}
          style={isMobile ? { padding: theme.spacing.md } : undefined}
        >
          {activeStep === ModalSteps.ASSETS ? (
            <Button
              variant="text"
              icon={<Icon name="x-close" />}
              onClick={closeModal}
            >
              Back to Accreditation
            </Button>
          ) : activeStep === ModalSteps.SIGNATURE ? (
            <>
              <Button
                variant="text"
                icon={<Icon name="chevron-left" />}
                onClick={() => setActiveStep(ModalSteps.QUESTION)}
              >
                Previous Question
              </Button>
              <Button
                form={QUALIFIED_INSTITUTIONAL_BUYER_FORM_ID}
                htmlType="submit"
                loading={isUpdating}
              >
                Sign off Accreditation Statement
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="text"
                icon={<Icon name="chevron-left" />}
                onClick={() => setActiveStep(ModalSteps.ASSETS)}
              >
                Previous Question
              </Button>
              <Button
                onClick={() => setActiveStep(ModalSteps.SIGNATURE)}
                disabled={!qualifiedInstitutionalBuyer}
              >
                Submit & Sign electronically
              </Button>
            </>
          )}
        </Flex>
      }
    >
      <ProgressBar
        title={
          activeStep === ModalSteps.SIGNATURE
            ? 'Electronic Signature'
            : 'Describe your organization'
        }
        currentStep={institutionalBuyerModalSteps.indexOf(activeStep) + 1}
        totalSteps={institutionalBuyerModalSteps.length}
      />
      <Form
        form={form}
        id={QUALIFIED_INSTITUTIONAL_BUYER_FORM_ID}
        onFinish={handleSubmit}
      >
        {activeStep === ModalSteps.ASSETS && (
          <Form.Item name={'qualified_institutional_buyer_assets'}>
            <Radio.Group>
              <Flex direction="column" gap="sm">
                <Flex gap="sm">
                  <Heading variant="h3">
                    Are you acting for your own account or the accounts of other
                    qualified institutional buyers (“QIB”), that in the
                    aggregate owns and invests on a discretionary basis:
                  </Heading>
                  <Tooltip title="The purpose of this questionnaire is to obtain information relating to whether you are a “Qualified Institutional Buyer” as defined in section (a) of Rule 144A of the Securities Act of 1933 (the “Act”), as amended.">
                    <InfoIconWrap>
                      <Icon name="info-circle" />
                    </InfoIconWrap>
                  </Tooltip>
                </Flex>
                <ButtonCard
                  key={InstitutionalBuyerAssets.AT_LEAST_100_MILLION}
                  variant={'radio'}
                  label={
                    'At least $100 million in eligible securities of issuers that are not affiliated with the entity'
                  }
                  value={InstitutionalBuyerAssets.AT_LEAST_100_MILLION}
                  onClick={() => setActiveStep(ModalSteps.QUESTION)}
                />
                <ButtonCard
                  key={InstitutionalBuyerAssets.LESS_THAN_100_MILLION}
                  variant={'radio'}
                  label={
                    'Less than $100 million in eligible securities of issuers that are not affiliated with the entity'
                  }
                  value={InstitutionalBuyerAssets.LESS_THAN_100_MILLION}
                  onClick={() => setActiveStep(ModalSteps.QUESTION)}
                />
              </Flex>
            </Radio.Group>
          </Form.Item>
        )}
        {activeStep === ModalSteps.QUESTION && (
          <QualifiedInstitutionalBuyerQuestionnaireFormContent
            questionnaireOptions={
              data[form.getFieldValue('qualified_institutional_buyer_assets')]
            }
          />
        )}
        {activeStep === ModalSteps.SIGNATURE && (
          <SignatureFormContent variant={'entity'} />
        )}
      </Form>
    </Modal>
  );
};
