import { Select } from '@npm/core/ui/components/atoms/Select';
import { Text as BaseText } from '@npm/core/ui/components/atoms/Typography';
import styled from 'styled-components';

export const SelectOption = styled(Select.Option)`
  line-height: ${({ theme }) => theme.typography.height.sm}px;
  margin-bottom: ${({ theme }) => theme.spacing.xs}px;
  && .ant-select-item-option-content {
    font-size: ${({ theme }) => theme.typography.size.sm}px;
  }
`;

export const Label = styled.label`
  margin-bottom: ${({ theme }) => theme.spacing.xs}px;
`;

export const Text = styled(BaseText)`
  margin-top: ${({ theme }) => theme.spacing.xs}px;
`;

export const Nobr = styled.span`
  white-space: nowrap;
`;

export const TitleContainer = styled.div`
  margin: ${({ theme }) => theme.spacing.xl}px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
