import { Label } from '@npm/core/ui/components/atoms/Label';
import {
  CbEventRole,
  CbWorkstationType,
  type CodebookItem,
  type CodebookOrString,
} from '@npm/data-access';

import { useCurrentWorkstation } from '../../auth/user/role';

import { ProgramCardFields } from '.';

export const CardLabel = ({
  role,
  hideFields,
}: {
  role?: CodebookOrString;
  hideFields?: string[];
}) => {
  const workstation = useCurrentWorkstation();
  const isIssWs = workstation?.type?.code === CbWorkstationType.items.issuer;

  if (hideFields?.includes(ProgramCardFields.ROLE) || !role?.name || isIssWs)
    return null;

  return (
    <Label round variant={CbEventRole.getCbLabel(role as CodebookItem)}>
      Role: {role?.name}
    </Label>
  );
};
