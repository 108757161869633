/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



  /**
 * 
 * @export
 * @interface FeatureFlag
 */
export interface FeatureFlag {
    /**
     * 
     * @type {string}
     * @memberof FeatureFlag
     */
    'name': FeatureFlagNameEnum;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureFlag
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof FeatureFlag
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureFlag
     */
    'technical_owner'?: string;
}

export const FeatureFlagNameEnum = {
    SkipEmailSending: 'SKIP_EMAIL_SENDING',
    CompanyOverviewVnext: 'COMPANY_OVERVIEW_VNEXT',
    FinancialAdvisors: 'FINANCIAL_ADVISORS',
    OctlLedger: 'OCTL_LEDGER',
    FeatureFlagForAutomatedTests: 'FEATURE_FLAG_FOR_AUTOMATED_TESTS',
    BroDesignatedMatch: 'BRO_DESIGNATED_MATCH',
    Notifications: 'NOTIFICATIONS',
    Persona: 'PERSONA',
    FlowScreener: 'FLOW_SCREENER'
} as const;

export type FeatureFlagNameEnum = typeof FeatureFlagNameEnum[keyof typeof FeatureFlagNameEnum];


