import { Checkbox } from '@npm/core/ui/components/atoms/Checkbox';
import {
  FormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { CbAccountType } from '@npm/data-access';
import { Form } from 'antd';
import { type CheckboxChangeEvent } from 'antd/lib/checkbox';

import { type OptInFormValues } from '../../OptInEntitySelection/OptInEntitySelection.types';

const entityType = CbAccountType.items.OrganizationAccount;
const individualType = CbAccountType.items.PersonAccount;

export const Disclaimer = () => {
  const form = Form.useFormInstance<OptInFormValues>();
  const { accountType } = form.getFieldsValue(['accountType']);

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    form.setFieldsValue({ disclaimerCheckbox: e.target.checked });
  };

  return (
    <FormItem
      name={'disclaimerCheckbox'}
      rules={[
        VALIDATION_RULES.disclaimerAccepted(
          'You need to agree to link with an existing NPM account'
        ),
      ]}
      validateTrigger={['onSubmit']}
      valuePropName="checked"
    >
      <Checkbox
        label="By linking, I agree to create a SecondMarket trading account under NPM Securities, LLC using the same onboarding details and holdings as your existing account. SecondMarket is neither a company-sponsored nor a company-approved marketplace and there is no guarantee there will be a buyer for your shares."
        onChange={handleCheckboxChange}
      />
    </FormItem>
  );
};
