import React, { useEffect, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';

import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { useHistory } from '@npm/core/ui/components/molecules/Link';
import { useDarkModeContext } from '@npm/core/ui/context/DarkModeContext';
import { useCurrentRoute } from '@npm/core/ui/hooks/useCurrentRoute';
import { getFullName } from '@npm/core/ui/utils/formatters';
import {
  CbSubscriptionPlan,
  getApiErrorCode,
  type UserUITheme,
  useUserShowCurrentLazy,
} from '@npm/data-access';
import { DatadogService } from '@npm/utils';
import { useOktaAuth } from '@okta/okta-react';

import { useUserContextStore } from '../../auth/user/context';
import { InitialLoadingPart, useInitialLoadingStore } from '../initialLoading';

const isErrorPath = (location: string) => {
  const regex = /^\/([4-5]\d{2})/g;
  return regex.test(location);
};

export const withUser = <T,>(Component: React.ComponentType<T>) => {
  return (hocProps: T) => {
    const history = useHistory();
    const handleError = useErrorHandler();
    const currentRoute = useCurrentRoute();
    const { authState, oktaAuth } = useOktaAuth();
    const user = useUserContextStore(store => store.user);
    const setUser = useUserContextStore(store => store.setUser);
    const { setTheme, getTheme } = useDarkModeContext();
    const [apiThemeChecked, setApiThemeChecked] = useState(false);

    const finishLoadingPartOfApp = useInitialLoadingStore(
      store => store.finishLoadingPart
    );

    const [loadUser] = useUserShowCurrentLazy({
      config: {
        queryConfig: {
          retry: 3,
        },
        onError: e => {
          // ignoring 441 because it's handled in initTOSHandling
          if (getApiErrorCode(e) !== 441) {
            console.log(e);
            Notification.error({
              message: 'User can not be authenticated',
            });
            handleError(e);
          }
          finishLoadingPartOfApp(InitialLoadingPart.User);
        },
        onComplete: user => {
          setUser(authState, user);
          finishLoadingPartOfApp(InitialLoadingPart.User);

          try {
            DatadogService.setUser({
              id: user.id.toString(),
              isPremium:
                user.subscription_plan?.code ===
                  CbSubscriptionPlan.items.trial ||
                user.subscription_plan?.code ===
                  CbSubscriptionPlan.items.premium,
              email: user.person?.email,
              fullName: getFullName(
                user.person?.first,
                user.person?.last,
                user.person?.middle
              ),
            });
          } catch (err) {
            console.error(err);
          }
        },
      },
    });

    useEffect(() => {
      if (!!user && !apiThemeChecked) {
        setApiThemeChecked(true);
        const apiTheme = user?.settings?.theme?.code as UserUITheme;
        if (!!apiTheme && getTheme() !== apiTheme) {
          setTheme(apiTheme);
        }
      }
    }, [user]);

    useEffect(() => {
      (async () => {
        if (!authState) {
          setUser(authState, null);
          DatadogService.setUser(null);
          return;
        }

        if (
          isErrorPath(history.location.pathname) ||
          currentRoute?.route?.needLogin === false
        ) {
          finishLoadingPartOfApp(InitialLoadingPart.User);
          return;
        }

        if (!authState.isAuthenticated) {
          oktaAuth.signInWithRedirect({
            originalUri: window.location.href,
          });
          return;
        }

        if (!user && currentRoute?.route?.needUser !== false) {
          loadUser();
        }
      })();
    }, [
      authState,
      user,
      currentRoute?.route?.needUser,
      currentRoute?.route?.needLogin,
    ]);

    if (
      !user &&
      !isErrorPath(history.location.pathname) &&
      currentRoute?.route?.needUser !== false &&
      currentRoute?.route?.needLogin !== false
    ) {
      return null;
    }

    return <Component {...hocProps} />;
  };
};
