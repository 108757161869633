// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  ProofOfOwnershipApi,
  ProofOfOwnershipAdminDownloadErrorCodes,
  ProofOfOwnershipAdminDownloadErrorTypes,
  ProofOfOwnershipDownloadErrorCodes,
  ProofOfOwnershipDownloadErrorTypes,
  ProofOfOwnershipCreateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new ProofOfOwnershipApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type ProofOfOwnershipAdminDownloadMethod = ProofOfOwnershipApi['proofOfOwnershipAdminDownload'];
export type ProofOfOwnershipAdminDownloadResponseType = MethodResult<ProofOfOwnershipAdminDownloadMethod>;

export const useProofOfOwnershipAdminDownload = (
  variables: Parameters<ProofOfOwnershipAdminDownloadMethod>[0],
  config?: UseQueryConfig<
    ProofOfOwnershipAdminDownloadMethod,
    ProofOfOwnershipAdminDownloadErrorTypes
  >
) => {
  return useQuery<
    ProofOfOwnershipAdminDownloadMethod,
    ProofOfOwnershipAdminDownloadErrorTypes
  >(
    (...args) => api.proofOfOwnershipAdminDownload(...args),
    ProofOfOwnershipAdminDownloadErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['ProofOfOwnershipAdminDownload'],
        ...apiQueryConfig.ProofOfOwnershipAdminDownload,
        ...config?.queryConfig
      },
    },
    'ProofOfOwnershipAdminDownload',
  );
};

export const useProofOfOwnershipAdminDownloadInfinite = (
  variables: Parameters<ProofOfOwnershipAdminDownloadMethod>[0],
  config?: UseInfiniteQueryConfig<
    ProofOfOwnershipAdminDownloadMethod,
    ProofOfOwnershipAdminDownloadErrorTypes
  >
) => {
  return useInfiniteQuery<
    ProofOfOwnershipAdminDownloadMethod,
    ProofOfOwnershipAdminDownloadErrorTypes
  >(
    (...args) => api.proofOfOwnershipAdminDownload(...args),
    ProofOfOwnershipAdminDownloadErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['ProofOfOwnershipAdminDownload', 'ProofOfOwnershipAdminDownloadInfinite'],
        ...apiQueryConfig.ProofOfOwnershipAdminDownload,
        ...config?.queryConfig,
      },
    },
    'ProofOfOwnershipAdminDownload',
  );
};

export const useProofOfOwnershipAdminDownloadLazy = (baseOptions?: {
  variables?: Parameters<ProofOfOwnershipAdminDownloadMethod>[0],
  config?: UseQueryConfig<
    ProofOfOwnershipAdminDownloadMethod,
    ProofOfOwnershipAdminDownloadErrorTypes
  >
}) => {
  return useLazyQuery<
    ProofOfOwnershipAdminDownloadMethod,
    ProofOfOwnershipAdminDownloadErrorTypes
  >(
    (...args) => api.proofOfOwnershipAdminDownload(...args),
    ProofOfOwnershipAdminDownloadErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['ProofOfOwnershipAdminDownload'],
        ...apiQueryConfig.ProofOfOwnershipAdminDownload,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'ProofOfOwnershipAdminDownload',
  );
};


export type ProofOfOwnershipDownloadMethod = ProofOfOwnershipApi['proofOfOwnershipDownload'];
export type ProofOfOwnershipDownloadResponseType = MethodResult<ProofOfOwnershipDownloadMethod>;

export const useProofOfOwnershipDownload = (
  variables: Parameters<ProofOfOwnershipDownloadMethod>[0],
  config?: UseQueryConfig<
    ProofOfOwnershipDownloadMethod,
    ProofOfOwnershipDownloadErrorTypes
  >
) => {
  return useQuery<
    ProofOfOwnershipDownloadMethod,
    ProofOfOwnershipDownloadErrorTypes
  >(
    (...args) => api.proofOfOwnershipDownload(...args),
    ProofOfOwnershipDownloadErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['ProofOfOwnershipDownload'],
        ...apiQueryConfig.ProofOfOwnershipDownload,
        ...config?.queryConfig
      },
    },
    'ProofOfOwnershipDownload',
  );
};

export const useProofOfOwnershipDownloadInfinite = (
  variables: Parameters<ProofOfOwnershipDownloadMethod>[0],
  config?: UseInfiniteQueryConfig<
    ProofOfOwnershipDownloadMethod,
    ProofOfOwnershipDownloadErrorTypes
  >
) => {
  return useInfiniteQuery<
    ProofOfOwnershipDownloadMethod,
    ProofOfOwnershipDownloadErrorTypes
  >(
    (...args) => api.proofOfOwnershipDownload(...args),
    ProofOfOwnershipDownloadErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['ProofOfOwnershipDownload', 'ProofOfOwnershipDownloadInfinite'],
        ...apiQueryConfig.ProofOfOwnershipDownload,
        ...config?.queryConfig,
      },
    },
    'ProofOfOwnershipDownload',
  );
};

export const useProofOfOwnershipDownloadLazy = (baseOptions?: {
  variables?: Parameters<ProofOfOwnershipDownloadMethod>[0],
  config?: UseQueryConfig<
    ProofOfOwnershipDownloadMethod,
    ProofOfOwnershipDownloadErrorTypes
  >
}) => {
  return useLazyQuery<
    ProofOfOwnershipDownloadMethod,
    ProofOfOwnershipDownloadErrorTypes
  >(
    (...args) => api.proofOfOwnershipDownload(...args),
    ProofOfOwnershipDownloadErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['ProofOfOwnershipDownload'],
        ...apiQueryConfig.ProofOfOwnershipDownload,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'ProofOfOwnershipDownload',
  );
};


export type ProofOfOwnershipCreateMethod = ProofOfOwnershipApi['proofOfOwnershipCreate'];

export const useProofOfOwnershipCreate = () => {
  return useMutation<ProofOfOwnershipCreateMethod>(
    (...args) => api.proofOfOwnershipCreate(...args),
    ProofOfOwnershipCreateErrorCodes,
    'ProofOfOwnershipCreate',
    '/api/investor/proof_of_ownerships',
    'post'
  );
};
