import React from 'react';

import { CypressDataIds } from '@npm/core/ui/constants';
import { Form, type FormInstance } from 'antd';

import * as S from './index';
import { SignatureFormContent } from './SignatureFormContent';
import {
  SIGNATURE_MODAL_MAX_HEIGHT,
  SIGNATURE_MODAL_WIDTH,
} from './SignatureModal.constants';

const SIGNATURE_MODAL_FORM_ID = 'signature-form';

type Props = {
  variant: 'entity' | 'individual';
  form: FormInstance;
  isOpen: boolean;
  handleSubmit: () => void;
  handleCancel: () => void;
  isLoading: boolean;
};

export const SignatureModal = ({
  variant,
  form,
  isOpen,
  handleSubmit,
  handleCancel,
  isLoading,
}: Props) => {
  return (
    <S.Modal
      destroyOnClose={true}
      data-cy="sign-modal"
      $maxHeight={SIGNATURE_MODAL_MAX_HEIGHT}
      width={SIGNATURE_MODAL_WIDTH}
      open={isOpen}
      title="Electronic Signature"
      footer={
        <S.FooterWrapper>
          <S.AcceptButton
            form={SIGNATURE_MODAL_FORM_ID}
            htmlType="submit"
            data-cy={
              CypressDataIds.Onboarding.Accreditation.SignAgreementButton
            }
            disabled={isLoading}
            loading={isLoading}
            blockOnMobile
          >
            Sign off Accreditation Statement
          </S.AcceptButton>
          <S.BackArrowBtn
            onClick={handleCancel}
            disabled={isLoading}
            blockOnMobile
          >
            Review information
          </S.BackArrowBtn>
        </S.FooterWrapper>
      }
      onCancel={handleCancel}
    >
      <Form form={form} id={SIGNATURE_MODAL_FORM_ID} onFinish={handleSubmit}>
        <SignatureFormContent variant={variant} />
      </Form>
    </S.Modal>
  );
};
