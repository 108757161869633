import { getFullName } from '@npm/core/ui/utils/formatters';
import { CbRoleType, type Codebook } from '@npm/data-access';
import {
  type Person,
  type User,
  type UserRoleAggregate,
} from '@npm/data-access';
import { type AuthState } from '@okta/okta-auth-js';

import { type StoreCreateArgs } from '../user-context.store';

export interface UserStore {
  user?: User;
  state?: AuthState;
  isNewUser: boolean;
  getFullName: () => string;
  setUser: (state: AuthState, data?: User) => void;
  setPerson: (data?: Person) => void;
  updateUserActiveRole: (role: UserRoleAggregate) => void;
  setDealSyncAccessStatus: (dealSyncAccessStatus: Codebook) => void;
}

export const createUserStore: (...a: StoreCreateArgs) => UserStore = (
  set,
  get
) => ({
  user: null,
  state: null,
  isNewUser: false,
  getFullName: () => {
    const user = get().user;
    if (!user?.person) {
      return null;
    }
    const { first, middle, last } = user.person;
    return getFullName(first, last, middle);
  },
  setPerson: data => {
    set({
      user: {
        ...get().user,
        person: data,
      },
    });
  },
  setUser: (authState, data) =>
    set({
      user: data,
      state: authState,
      ...(!!data && {
        isNewUser:
          data?.active_role?.role_name?.code === CbRoleType.items.NEW_USER,
      }),
    }),
  updateUserActiveRole: (role: UserRoleAggregate) =>
    set(store => ({
      ...store,
      user: { ...store.user, active_role: role, active_role_id: role.id },
      isNewUser: role.role_name?.code === CbRoleType.items.NEW_USER,
    })),
  setDealSyncAccessStatus: (dealSyncAccessStatus: Codebook) =>
    set(store => ({
      ...store,
      user: {
        ...store.user,
        deal_sync_access: dealSyncAccessStatus,
      },
    })),
});
