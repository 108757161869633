import React, { type ComponentProps } from 'react';

import { Margin } from '@npm/core/ui/components/atoms/common';
import { type Table } from '@npm/core/ui/components/molecules/Table';
import { useAccountShow } from '@npm/data-access';

import * as S from '../../../OboModal.styles';
import {
  type NegotiationAccounts,
  type NegotiationActionFlags,
} from '../../../OboModal.types';
import { type SelectNegotiationAccountListProps } from '../SelectNegotiationAccount.types';

import { getColumns } from './SelectNegotiationAccountTable.columns';

type Props = {
  accounts: NegotiationAccounts;
  actionFlags: NegotiationActionFlags;
} & SelectNegotiationAccountListProps &
  ComponentProps<typeof Table>;

export const SelectNegotiationAccountTable = ({
  handleSelect,
  selectedAccount,
  accounts,
  actionFlags,
  goToRepresentativeStep,
}: Props) => {
  const investor = accounts.investorAccountId;
  const stakeholder = accounts.stakeholderAccountId;
  const needToChooseInvestorOrStakeholder = !!investor && !!stakeholder;

  const { data: investorAccount, isFetching: isInvestorAccountFetching } =
    useAccountShow(
      {
        id: investor?.toString(),
      },
      {
        queryConfig: {
          enabled: !!investor,
        },
      }
    );

  const { data: stakeholderAccount, isFetching: isStakeholderAccountFetching } =
    useAccountShow(
      {
        id: stakeholder?.toString(),
      },
      {
        queryConfig: {
          enabled: !!stakeholder,
        },
      }
    );

  const showAction =
    needToChooseInvestorOrStakeholder &&
    (actionFlags.userCanActAsInvestor || actionFlags.userCanActAsStakeholder);

  return (
    <>
      {investor && (
        <Margin bottom="lg">
          <S.NegotiationAccountsTable
            // show only one loading spinner
            loading={isInvestorAccountFetching || isStakeholderAccountFetching}
            dataSource={investorAccount ? [investorAccount] : []}
            columns={getColumns(
              selectedAccount,
              investorAccount?.representative,
              needToChooseInvestorOrStakeholder,
              true,
              actionFlags,
              goToRepresentativeStep,
              showAction
            )}
            rowSelection={{
              type: 'radio',
              selectedRowKeys: [selectedAccount?.id],
              onChange: (_, a) =>
                handleSelect({
                  account: a[0],
                  representative: a[0]?.representative,
                }),
            }}
          />
        </Margin>
      )}
      {stakeholder &&
        (!needToChooseInvestorOrStakeholder ||
          !isStakeholderAccountFetching) && (
          <S.NegotiationAccountsTable
            loading={isStakeholderAccountFetching}
            dataSource={stakeholderAccount ? [stakeholderAccount] : []}
            columns={getColumns(
              selectedAccount,
              stakeholderAccount?.representative,
              needToChooseInvestorOrStakeholder,
              false,
              actionFlags,
              goToRepresentativeStep,
              showAction
            )}
            rowSelection={{
              type: 'radio',
              selectedRowKeys: [selectedAccount?.id],
              onChange: (_, a) =>
                handleSelect({
                  account: a[0],
                  representative: stakeholderAccount?.representative,
                }),
            }}
          />
        )}
    </>
  );
};
