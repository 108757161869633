import React, { useState } from 'react';

import { Form } from '@npm/core/ui/components/atoms/Form';
import { Heading } from '@npm/core/ui/components/atoms/Typography';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import {
  useConfirmOnLeave,
  useLocation,
} from '@npm/core/ui/components/molecules/Link';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { DiscardModal } from '@npm/core/ui/components/organisms/DiscardModal';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { handleValidationError } from '@npm/core/ui/utils/form';
import {
  type AccountForBrokerage,
  CbAccountType,
  CbRoleType,
  useAccountRepresentativeIndexLazy,
  useUserRoleCreate,
} from '@npm/data-access';
import { type ModalProps } from 'antd/lib/modal';
import { omit } from 'lodash';

import { NewOrganizationAccountForm } from '../../../../../../account/CreateNewAccount/Form';
import { useCreateOrganizationAccount } from '../../../../../../account/CreateNewAccount/NewOrganizationAccountDrawer.hooks';
import { setObo } from '../../../../role/context/userRole.helpers';
import { useCurrentRole } from '../../../../role/hooks/useCurrentRole';
import { type OboDefinition } from '../../../../role/userRole.types';

import * as S from './CreateNewAccountModal.styles';
import { AddRepresentative } from './AddRepresentative';
import {
  type AccountFormFields,
  type RepresentativeFormFields,
} from './CreateNewAccount.types';
import { FooterButtons } from './FooterButtons';

type Props = {
  onSuccess?: () => void;
  setOboOverride?: (obo: OboDefinition) => void;
} & ModalProps;

export const CreateNewAccountModal = ({
  open,
  onCancel,
  onSuccess,
  setOboOverride,
}: Props) => {
  const { isMobile } = useBreakpoints();
  const { pathname } = useLocation();
  const { withShowApiErrorMiddleware, clearAlerts } = useAlerts();

  const [accountForm] = Form.useForm<AccountFormFields>();
  const isAnonymous = Form.useWatch('anonymous', accountForm);
  const [representativeForm] = Form.useForm<RepresentativeFormFields>();
  const [isLoading, setIsLoading] = useState(false);
  const [createdAccount, setCreatedAccount] = useState<AccountForBrokerage>();

  const role = useCurrentRole();

  const { execute: executeAccount } = useCreateOrganizationAccount(
    role?.subject?.id,
    false
  );
  const { execute: executeUserRole } = useUserRoleCreate();
  const [fetchRepresentative] = useAccountRepresentativeIndexLazy();

  const { setHasUnsavedChanges, onCloseAttempt, discardModalProps } =
    useConfirmOnLeave();

  const handleCloseConfirm = () => {
    representativeForm.resetFields();
    accountForm.resetFields();
    setHasUnsavedChanges(false);
    onCancel(null);
  };

  const handleSubmit = async () => {
    let accountValues: AccountFormFields;
    let account = createdAccount;
    let representativeValues: RepresentativeFormFields;

    setIsLoading(true);
    clearAlerts();

    try {
      accountValues = await accountForm.validateFields();
      representativeValues = await representativeForm.validateFields();
    } catch (err) {
      handleValidationError(accountForm, err);
      handleValidationError(representativeForm, err);
      setIsLoading(false);
      return;
    }

    if (!account) {
      try {
        const res =
          await withShowApiErrorMiddleware(executeAccount)(accountValues);
        account = res;
        setCreatedAccount(res);
      } catch (err) {
        console.error(err);
        setIsLoading(false);
        return;
      }
    }

    try {
      await withShowApiErrorMiddleware(executeUserRole)({
        userRoleCreateRequestContract: {
          account_id: account.id,
          role_type:
            account.type.code === CbAccountType.items.Anonymous
              ? CbRoleType.items.ADMIN_AND_SIGNER
              : representativeValues.role,
          new_user: omit(representativeValues, 'role'),
        },
      });

      setHasUnsavedChanges(false); // Unblock navigation

      const res = await fetchRepresentative({
        variables: {
          accountId: account.id,
        },
      });

      if (setOboOverride) {
        setOboOverride({ account, representative: res.representatives?.[0] });
      } else {
        setObo(
          {
            account,
            representative: res.representatives?.[0],
          },
          {
            redirectTo: !pathname.includes('second-market')
              ? '/investor-workstation/account/detail'
              : null,
          }
        );
      }

      onSuccess?.();
      handleCloseConfirm();
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Modal
        open={open}
        isFullScreen={isMobile}
        onCancel={() => onCloseAttempt(handleCloseConfirm)}
        footer={
          <FooterButtons
            onCancel={() => onCloseAttempt(handleCloseConfirm)}
            onNext={handleSubmit}
            isLoading={isLoading}
          />
        }
        fullScreenProps={{ footerHeight: 120 }}
        width={625}
        destroyOnClose
      >
        <Heading variant="h3" marginBottom="lg">
          Create New Account
          {role?.subject?.name && ` for ${role?.subject?.name}`}
        </Heading>
        <S.ContentWrapper>
          <NewOrganizationAccountForm
            form={accountForm}
            includeRepresentativeFields={false}
            onValuesChange={() => setHasUnsavedChanges(true)}
          />
          <AddRepresentative
            form={representativeForm}
            isAnonymousAccount={isAnonymous}
            onValuesChange={() => setHasUnsavedChanges(true)}
          />
        </S.ContentWrapper>
      </Modal>
      <DiscardModal {...discardModalProps} />
    </>
  );
};
