import {
  type AccountShowAggregate,
  type BrokerageFirmAggregate,
  CbAccountType,
  CbAccreditationState,
  CbRoleType,
  CbVisibility,
  type VisibilityCode,
} from '@npm/data-access';

export const isAccountGuestRole = (
  selectedAccount: AccountShowAggregate,
  userEmail: string
) => {
  const currentUser = selectedAccount?.users?.find(u => u.email === userEmail);

  return !!currentUser?.roles?.find(
    r =>
      r.code === CbRoleType.items.ACCOUNT_GUEST ||
      r.code === CbRoleType.items.GUEST_AND_SIGNER
  );
};

export const isAccountNotAccredited = (
  selectedAccount: AccountShowAggregate
) => {
  const isAnonymous =
    selectedAccount?.type?.code === CbAccountType.items.Anonymous;

  if (!selectedAccount || isAnonymous) return false;

  const isAccreditedAccount =
    selectedAccount?.accreditation_state?.code ===
    CbAccreditationState.items.passed;

  return !isAccreditedAccount;
};

export const canSelectATSPool = (
  visibility: VisibilityCode,
  brokerageFirm: Pick<BrokerageFirmAggregate, 'access_ats_i'>
) => {
  return (
    visibility !== CbVisibility.items.internal && !!brokerageFirm?.access_ats_i
  );
};
