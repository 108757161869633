// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  MatchActivityApi,
  MatchActivitiesIndexErrorCodes,
  MatchActivitiesIndexErrorTypes,
  MatchActivityRofrWaivesCreateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new MatchActivityApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type MatchActivitiesIndexMethod = MatchActivityApi['matchActivitiesIndex'];
export type MatchActivitiesIndexResponseType = MethodResult<MatchActivitiesIndexMethod>;

export const useMatchActivitiesIndex = (
  variables: Parameters<MatchActivitiesIndexMethod>[0],
  config?: UseQueryConfig<
    MatchActivitiesIndexMethod,
    MatchActivitiesIndexErrorTypes
  >
) => {
  return useQuery<
    MatchActivitiesIndexMethod,
    MatchActivitiesIndexErrorTypes
  >(
    (...args) => api.matchActivitiesIndex(...args),
    MatchActivitiesIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MatchActivitiesIndex'],
        ...apiQueryConfig.MatchActivitiesIndex,
        ...config?.queryConfig
      },
    },
    'MatchActivitiesIndex',
  );
};

export const useMatchActivitiesIndexInfinite = (
  variables: Parameters<MatchActivitiesIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    MatchActivitiesIndexMethod,
    MatchActivitiesIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    MatchActivitiesIndexMethod,
    MatchActivitiesIndexErrorTypes
  >(
    (...args) => api.matchActivitiesIndex(...args),
    MatchActivitiesIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MatchActivitiesIndex', 'MatchActivitiesIndexInfinite'],
        ...apiQueryConfig.MatchActivitiesIndex,
        ...config?.queryConfig,
      },
    },
    'MatchActivitiesIndex',
  );
};

export const useMatchActivitiesIndexLazy = (baseOptions?: {
  variables?: Parameters<MatchActivitiesIndexMethod>[0],
  config?: UseQueryConfig<
    MatchActivitiesIndexMethod,
    MatchActivitiesIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    MatchActivitiesIndexMethod,
    MatchActivitiesIndexErrorTypes
  >(
    (...args) => api.matchActivitiesIndex(...args),
    MatchActivitiesIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['MatchActivitiesIndex'],
        ...apiQueryConfig.MatchActivitiesIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'MatchActivitiesIndex',
  );
};


export type MatchActivityRofrWaivesCreateMethod = MatchActivityApi['matchActivityRofrWaivesCreate'];

export const useMatchActivityRofrWaivesCreate = () => {
  return useMutation<MatchActivityRofrWaivesCreateMethod>(
    (...args) => api.matchActivityRofrWaivesCreate(...args),
    MatchActivityRofrWaivesCreateErrorCodes,
    'MatchActivityRofrWaivesCreate',
    '/api/match-activity-rofr-waives',
    'post'
  );
};
