import { Drawer as BaseDrawer } from 'antd';
import styled, { css } from 'styled-components';

import { getFocusBorderStyles, useBreakpoint } from '../../../utils/style';
import { type useLayoutConfig } from '../Layout';

export const DRAWER_HEADER_HEIGHT = 64;
const ARROW_BACK_WIDTH = 32;
export const FLOATING_BUTTON_HEIGHT = 88;
export const FLOATING_BUTTON_HEIGHT_MOBILE = 72;

const fullHeightDrawerCSS = css`
  top: 0;
  margin-top: 0;
  height: calc(var(--vh, 1vh) * 100);
`;

export const Drawer = styled(BaseDrawer)<{
  $layoutConfig: ReturnType<typeof useLayoutConfig>;
  $isFullHeight: boolean;
}>`
  position: fixed;
  ${({ $layoutConfig }) =>
    `
      top: ${$layoutConfig.header.height}px;
      height: calc(100% - ${$layoutConfig.header.height}px + ${$layoutConfig.header.margin}px);
  `}
  z-index: ${({ theme }) => theme.zIndex.drawer};

  ${({ $isFullHeight }) => $isFullHeight && fullHeightDrawerCSS}

  & .ant-drawer-mask {
    background: transparent;
  }

  & .ant-drawer-content {
    background: ${({ theme }) => theme.color.general.layout.one};
  }

  & .ant-drawer-content-wrapper {
    & .ant-drawer-header {
      display: none;
    }

    & .ant-drawer-body {
      padding: 0;
      overflow-x: hidden;
    }

    & .ant-drawer-footer {
      padding: 0;
    }
  }
`;

export const Wrapper = styled.div<{ $footerHeight?: number }>`
  display: flex;
  flex-flow: column;
  min-height: 100%;
  background-color: ${({ theme }) => theme.color.general.layout.one};

  ${({ theme, $footerHeight }) => useBreakpoint(theme).mobile`
    min-height: calc(100% - ${
      $footerHeight ?? FLOATING_BUTTON_HEIGHT_MOBILE + theme.spacing.sm
    }px);
  `}
`;

export const Content = styled.div<{
  $bottomPadding?: number;
}>`
  padding-top: ${({ theme }) => DRAWER_HEADER_HEIGHT + theme.spacing.md}px;
  padding-bottom: ${({ $bottomPadding, theme }) =>
    ($bottomPadding ?? FLOATING_BUTTON_HEIGHT) + theme.spacing.md}px;
  padding-right: ${({ theme }) => theme.spacing.lg}px;
  padding-left: ${({ theme }) => theme.spacing.lg}px;
  background-color: ${({ theme }) => theme.color.general.layout.one};

  ${({ theme, $bottomPadding }) => useBreakpoint(theme).mobile`
    padding: ${theme.spacing.md}px;
    padding-bottom: ${($bottomPadding ?? 0) + theme.spacing.md}px;
  `}
`;

export const HeaderWrap = styled.div`
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.fixed};
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: ${DRAWER_HEADER_HEIGHT}px;
  border-bottom: 1px solid ${({ theme }) => theme.color.general.borders.primary};
  background-color: ${({ theme }) => theme.color.general.layout.one};
  padding: 0 ${({ theme }) => theme.spacing.md}px;

  ${({ theme }) => useBreakpoint(theme).mobile`
    padding: 0 ${theme.spacing.md}px;
    position: unset;
    min-height: ${DRAWER_HEADER_HEIGHT}px;
  `}
`;

export const HeaderButton = styled.button`
  width: ${ARROW_BACK_WIDTH}px;
  height: ${ARROW_BACK_WIDTH}px;
  line-height: 0;
  border-radius: ${({ theme }) => theme.borderRadius.sm}px;
  color: ${({ theme }) => theme.color.general.typography.primary};

  body.tab-focus ${getFocusBorderStyles()};
`;

export const FooterWrapper = styled.div<{
  $hasPadding?: boolean;
  $height?: number;
  $isFixed?: boolean;
}>`
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.fixed};
  bottom: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.color.general.layout.one};
  border-top: 1px solid ${({ theme }) => theme.color.general.borders.primary};

  ${({ $height, $isFixed, theme }) =>
    $height
      ? `height: ${$height + 1}px;`
      : $isFixed
        ? `height: unset;`
        : useBreakpoint(theme).mobile`position: unset;`}

  ${({ $hasPadding = true, theme }) =>
    $hasPadding &&
    css`
      padding: ${theme.spacing.lg}px;
      ${useBreakpoint(theme).mobile`
        padding: ${theme.spacing.md}px;
      `}
    `}
`;

export const DrawerSectionBackgroundContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.color.general.borders.primary};
  background-color: ${({ theme }) => theme.color.general.layout.two};
  border-radius: ${({ theme }) => theme.borderRadius.md}px;
  padding: ${({ theme }) => theme.spacing.sm}px;
  margin-bottom: ${({ theme }) => theme.spacing.sm}px;
`;
