import React from 'react';

import { useTheme } from 'styled-components';

import * as S from '../WizardLayout.styles';
import { useBreakpoints } from '../../../../../hooks/useBreakpoints';
import { Icon } from '../../../../atoms/Icon';
import { Heading } from '../../../../atoms/Typography';
import { useHistory } from '../../../../molecules/Link';
import { useWizardStore } from '../../Wizard.store';
import { WizardStoreActionTypes } from '../../Wizard.types';
import { updateHistoryState } from '../../Wizard.utils';

type Props = {
  title: React.ReactNode;
  exitRoute: string;
  titleExtra?: React.ReactNode;
  body?: React.ReactNode;
  handleCloseFormAttempt?: (callback?: () => void) => void;
};

export const Header = ({
  title,
  exitRoute,
  titleExtra,
  handleCloseFormAttempt,
}: Props) => {
  const history = useHistory();
  const theme = useTheme();
  const { isMobile, isTablet } = useBreakpoints();
  const isMobileView = isMobile || isTablet;

  const { isStepperMobileOpen, dispatch } = useWizardStore();

  const toggleStepperMobileOpen = () => {
    updateHistoryState('push', { isStepperMobileOpen: !isStepperMobileOpen });
    dispatch({
      type: WizardStoreActionTypes.TOGGLE_STEPPER_MOBILE_OPEN,
    });
  };

  return (
    <S.Header $withMargin={isMobileView && isStepperMobileOpen}>
      {!isMobileView || !isStepperMobileOpen ? (
        <S.ExitButton
          variant="text"
          color="info"
          icon={<Icon name="chevron-left" size="xs" />}
          onClick={() => history.push(exitRoute)}
        >
          {!isMobileView && 'Leave'}
        </S.ExitButton>
      ) : (
        <div>&nbsp;</div>
      )}
      <S.TitleWrapper>
        {!isMobile && (
          <Heading variant="h3" as="h1">
            {title}
          </Heading>
        )}
        {titleExtra}
      </S.TitleWrapper>
      {isMobileView && (
        <S.StepsToggleButton
          variant="text"
          icon={
            <Icon
              name={isStepperMobileOpen ? 'x-close' : 'list'}
              size="xs"
              color={theme.color.general.typography.primary}
            />
          }
          onClick={() => handleCloseFormAttempt?.(toggleStepperMobileOpen)}
        />
      )}
    </S.Header>
  );
};
