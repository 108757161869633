import { useCallback, useEffect, useRef, useState } from 'react';
import { type LinkProps } from 'react-router-dom';

import create from 'zustand';

import { useHistory } from './useHistory';

interface HistoryStore {
  isBlocked: boolean;
  setIsBlocked: (open: boolean) => void;
}

export const useHistoryStore = create<HistoryStore>(set => ({
  isBlocked: false,
  setIsBlocked: isBlocked => set({ isBlocked }),
}));

export const useConfirmOnLeave = () => {
  const history = useHistory();
  const unblockHistory = useRef<() => void | undefined>();
  const navigationDestination = useRef<LinkProps['to'] | undefined>();
  const closeCallback = useRef<() => void>();

  const [hasUnsavedChanges, _setHasUnsavedChanges] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  // This flag can be accessed globally, but may be overwritten if blocking
  // two or more forms at once e.g. in Submission Wizard.
  // Please rely on `hasUnsavedChanges` whenever possible.
  const setIsNavigationBlocked = useHistoryStore(store => store.setIsBlocked);

  const handleWindowUnload = useCallback(event => {
    event.preventDefault();
    event.returnValue = ''; // Legacy
  }, []);

  const setHasUnsavedChanges = useCallback(
    (value: boolean) => {
      if (hasUnsavedChanges === value) return;

      if (value) {
        // React Router navigation can be intercepted within the app and
        // trigger a custom confirmation dialog
        unblockHistory.current = history.block(target => {
          navigationDestination.current = target;
          setIsConfirmModalOpen(true);
          return false;
        });

        // Browser-level actions, such as closing a tab, closing the browser window,
        // and manually changing the URL, can only be intercepted using the default
        // confirmation dialog
        window.addEventListener('beforeunload', handleWindowUnload);
      } else {
        unblockHistory.current?.();
        window.removeEventListener('beforeunload', handleWindowUnload);
      }

      _setHasUnsavedChanges(value);
      setIsNavigationBlocked(value);
    },
    [
      hasUnsavedChanges,
      _setHasUnsavedChanges,
      history,
      setIsNavigationBlocked,
      handleWindowUnload,
      setIsConfirmModalOpen,
    ]
  );

  useEffect(() => {
    // Unblock if unmounted
    return () => {
      setIsNavigationBlocked(false);
      unblockHistory.current?.();
      window.removeEventListener('beforeunload', handleWindowUnload);
    };
  }, []);

  const handleCancel = () => setIsConfirmModalOpen(false);

  const handleConfirm = () => {
    setHasUnsavedChanges(false);
    setIsConfirmModalOpen(false);

    closeCallback.current?.();
    if (navigationDestination.current) {
      history.push(navigationDestination.current);
    }
  };

  const handleCloseAttempt = (callback?: () => void) => {
    closeCallback.current = callback;
    if (hasUnsavedChanges) {
      setIsConfirmModalOpen(true);
    } else {
      handleConfirm();
    }
  };

  return {
    hasUnsavedChanges,
    setHasUnsavedChanges,
    onCloseAttempt: handleCloseAttempt,
    discardModalProps: {
      open: isConfirmModalOpen,
      onCancel: handleCancel,
      onOk: handleConfirm,
    },
  };
};
