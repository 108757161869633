// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  FilingsApi,
  CompanyFilingsErrorCodes,
  CompanyFilingsErrorTypes,
  FilingsShowErrorCodes,
  FilingsShowErrorTypes,
  FilingCreateErrorCodes,
  InternalFilingsIndexErrorCodes,
  InternalFilingsIndexErrorTypes,
  InternalFilingsCreateErrorCodes,
  InternalFilingsShowErrorCodes,
  InternalFilingsShowErrorTypes,
  InternalUpdateFilingErrorCodes,
  InternalDeleteFilingErrorCodes,
  InternalFilingsLlmCoiErrorCodes,
  InternalFilingsLlmCoiErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new FilingsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type CompanyFilingsMethod = FilingsApi['companyFilings'];
export type CompanyFilingsResponseType = MethodResult<CompanyFilingsMethod>;

export const useCompanyFilings = (
  variables: Parameters<CompanyFilingsMethod>[0],
  config?: UseQueryConfig<
    CompanyFilingsMethod,
    CompanyFilingsErrorTypes
  >
) => {
  return useQuery<
    CompanyFilingsMethod,
    CompanyFilingsErrorTypes
  >(
    (...args) => api.companyFilings(...args),
    CompanyFilingsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompanyFilings'],
        ...apiQueryConfig.CompanyFilings,
        ...config?.queryConfig
      },
    },
    'CompanyFilings',
  );
};

export const useCompanyFilingsInfinite = (
  variables: Parameters<CompanyFilingsMethod>[0],
  config?: UseInfiniteQueryConfig<
    CompanyFilingsMethod,
    CompanyFilingsErrorTypes
  >
) => {
  return useInfiniteQuery<
    CompanyFilingsMethod,
    CompanyFilingsErrorTypes
  >(
    (...args) => api.companyFilings(...args),
    CompanyFilingsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompanyFilings', 'CompanyFilingsInfinite'],
        ...apiQueryConfig.CompanyFilings,
        ...config?.queryConfig,
      },
    },
    'CompanyFilings',
  );
};

export const useCompanyFilingsLazy = (baseOptions?: {
  variables?: Parameters<CompanyFilingsMethod>[0],
  config?: UseQueryConfig<
    CompanyFilingsMethod,
    CompanyFilingsErrorTypes
  >
}) => {
  return useLazyQuery<
    CompanyFilingsMethod,
    CompanyFilingsErrorTypes
  >(
    (...args) => api.companyFilings(...args),
    CompanyFilingsErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['CompanyFilings'],
        ...apiQueryConfig.CompanyFilings,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'CompanyFilings',
  );
};


export type FilingsShowMethod = FilingsApi['filingsShow'];
export type FilingsShowResponseType = MethodResult<FilingsShowMethod>;

export const useFilingsShow = (
  variables: Parameters<FilingsShowMethod>[0],
  config?: UseQueryConfig<
    FilingsShowMethod,
    FilingsShowErrorTypes
  >
) => {
  return useQuery<
    FilingsShowMethod,
    FilingsShowErrorTypes
  >(
    (...args) => api.filingsShow(...args),
    FilingsShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['FilingsShow'],
        ...apiQueryConfig.FilingsShow,
        ...config?.queryConfig
      },
    },
    'FilingsShow',
  );
};

export const useFilingsShowInfinite = (
  variables: Parameters<FilingsShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    FilingsShowMethod,
    FilingsShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    FilingsShowMethod,
    FilingsShowErrorTypes
  >(
    (...args) => api.filingsShow(...args),
    FilingsShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['FilingsShow', 'FilingsShowInfinite'],
        ...apiQueryConfig.FilingsShow,
        ...config?.queryConfig,
      },
    },
    'FilingsShow',
  );
};

export const useFilingsShowLazy = (baseOptions?: {
  variables?: Parameters<FilingsShowMethod>[0],
  config?: UseQueryConfig<
    FilingsShowMethod,
    FilingsShowErrorTypes
  >
}) => {
  return useLazyQuery<
    FilingsShowMethod,
    FilingsShowErrorTypes
  >(
    (...args) => api.filingsShow(...args),
    FilingsShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['FilingsShow'],
        ...apiQueryConfig.FilingsShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'FilingsShow',
  );
};


export type FilingCreateMethod = FilingsApi['filingCreate'];

export const useFilingCreate = () => {
  return useMutation<FilingCreateMethod>(
    (...args) => api.filingCreate(...args),
    FilingCreateErrorCodes,
    'FilingCreate',
    '/api/filings',
    'post'
  );
};

export type InternalFilingsIndexMethod = FilingsApi['internalFilingsIndex'];
export type InternalFilingsIndexResponseType = MethodResult<InternalFilingsIndexMethod>;

export const useInternalFilingsIndex = (
  variables: Parameters<InternalFilingsIndexMethod>[0],
  config?: UseQueryConfig<
    InternalFilingsIndexMethod,
    InternalFilingsIndexErrorTypes
  >
) => {
  return useQuery<
    InternalFilingsIndexMethod,
    InternalFilingsIndexErrorTypes
  >(
    (...args) => api.internalFilingsIndex(...args),
    InternalFilingsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalFilingsIndex'],
        ...apiQueryConfig.InternalFilingsIndex,
        ...config?.queryConfig
      },
    },
    'InternalFilingsIndex',
  );
};

export const useInternalFilingsIndexInfinite = (
  variables: Parameters<InternalFilingsIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalFilingsIndexMethod,
    InternalFilingsIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalFilingsIndexMethod,
    InternalFilingsIndexErrorTypes
  >(
    (...args) => api.internalFilingsIndex(...args),
    InternalFilingsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalFilingsIndex', 'InternalFilingsIndexInfinite'],
        ...apiQueryConfig.InternalFilingsIndex,
        ...config?.queryConfig,
      },
    },
    'InternalFilingsIndex',
  );
};

export const useInternalFilingsIndexLazy = (baseOptions?: {
  variables?: Parameters<InternalFilingsIndexMethod>[0],
  config?: UseQueryConfig<
    InternalFilingsIndexMethod,
    InternalFilingsIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalFilingsIndexMethod,
    InternalFilingsIndexErrorTypes
  >(
    (...args) => api.internalFilingsIndex(...args),
    InternalFilingsIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalFilingsIndex'],
        ...apiQueryConfig.InternalFilingsIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalFilingsIndex',
  );
};


export type InternalFilingsCreateMethod = FilingsApi['internalFilingsCreate'];

export const useInternalFilingsCreate = () => {
  return useMutation<InternalFilingsCreateMethod>(
    (...args) => api.internalFilingsCreate(...args),
    InternalFilingsCreateErrorCodes,
    'InternalFilingsCreate',
    '/api/internal/companies/{company_id}/filings',
    'post'
  );
};

export type InternalFilingsShowMethod = FilingsApi['internalFilingsShow'];
export type InternalFilingsShowResponseType = MethodResult<InternalFilingsShowMethod>;

export const useInternalFilingsShow = (
  variables: Parameters<InternalFilingsShowMethod>[0],
  config?: UseQueryConfig<
    InternalFilingsShowMethod,
    InternalFilingsShowErrorTypes
  >
) => {
  return useQuery<
    InternalFilingsShowMethod,
    InternalFilingsShowErrorTypes
  >(
    (...args) => api.internalFilingsShow(...args),
    InternalFilingsShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalFilingsShow'],
        ...apiQueryConfig.InternalFilingsShow,
        ...config?.queryConfig
      },
    },
    'InternalFilingsShow',
  );
};

export const useInternalFilingsShowInfinite = (
  variables: Parameters<InternalFilingsShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalFilingsShowMethod,
    InternalFilingsShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalFilingsShowMethod,
    InternalFilingsShowErrorTypes
  >(
    (...args) => api.internalFilingsShow(...args),
    InternalFilingsShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalFilingsShow', 'InternalFilingsShowInfinite'],
        ...apiQueryConfig.InternalFilingsShow,
        ...config?.queryConfig,
      },
    },
    'InternalFilingsShow',
  );
};

export const useInternalFilingsShowLazy = (baseOptions?: {
  variables?: Parameters<InternalFilingsShowMethod>[0],
  config?: UseQueryConfig<
    InternalFilingsShowMethod,
    InternalFilingsShowErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalFilingsShowMethod,
    InternalFilingsShowErrorTypes
  >(
    (...args) => api.internalFilingsShow(...args),
    InternalFilingsShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalFilingsShow'],
        ...apiQueryConfig.InternalFilingsShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalFilingsShow',
  );
};


export type InternalUpdateFilingMethod = FilingsApi['internalUpdateFiling'];

export const useInternalUpdateFiling = () => {
  return useMutation<InternalUpdateFilingMethod>(
    (...args) => api.internalUpdateFiling(...args),
    InternalUpdateFilingErrorCodes,
    'InternalUpdateFiling',
    '/api/internal/filings/{id}',
    'put'
  );
};

export type InternalDeleteFilingMethod = FilingsApi['internalDeleteFiling'];

export const useInternalDeleteFiling = () => {
  return useMutation<InternalDeleteFilingMethod>(
    (...args) => api.internalDeleteFiling(...args),
    InternalDeleteFilingErrorCodes,
    'InternalDeleteFiling',
    '/api/internal/filings/{id}',
    'delete'
  );
};

export type InternalFilingsLlmCoiMethod = FilingsApi['internalFilingsLlmCoi'];
export type InternalFilingsLlmCoiResponseType = MethodResult<InternalFilingsLlmCoiMethod>;

export const useInternalFilingsLlmCoi = (
  variables: Parameters<InternalFilingsLlmCoiMethod>[0],
  config?: UseQueryConfig<
    InternalFilingsLlmCoiMethod,
    InternalFilingsLlmCoiErrorTypes
  >
) => {
  return useQuery<
    InternalFilingsLlmCoiMethod,
    InternalFilingsLlmCoiErrorTypes
  >(
    (...args) => api.internalFilingsLlmCoi(...args),
    InternalFilingsLlmCoiErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalFilingsLlmCoi'],
        ...apiQueryConfig.InternalFilingsLlmCoi,
        ...config?.queryConfig
      },
    },
    'InternalFilingsLlmCoi',
  );
};

export const useInternalFilingsLlmCoiInfinite = (
  variables: Parameters<InternalFilingsLlmCoiMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalFilingsLlmCoiMethod,
    InternalFilingsLlmCoiErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalFilingsLlmCoiMethod,
    InternalFilingsLlmCoiErrorTypes
  >(
    (...args) => api.internalFilingsLlmCoi(...args),
    InternalFilingsLlmCoiErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalFilingsLlmCoi', 'InternalFilingsLlmCoiInfinite'],
        ...apiQueryConfig.InternalFilingsLlmCoi,
        ...config?.queryConfig,
      },
    },
    'InternalFilingsLlmCoi',
  );
};

export const useInternalFilingsLlmCoiLazy = (baseOptions?: {
  variables?: Parameters<InternalFilingsLlmCoiMethod>[0],
  config?: UseQueryConfig<
    InternalFilingsLlmCoiMethod,
    InternalFilingsLlmCoiErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalFilingsLlmCoiMethod,
    InternalFilingsLlmCoiErrorTypes
  >(
    (...args) => api.internalFilingsLlmCoi(...args),
    InternalFilingsLlmCoiErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalFilingsLlmCoi'],
        ...apiQueryConfig.InternalFilingsLlmCoi,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalFilingsLlmCoi',
  );
};

