import { centerFlex, useBreakpoint } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

export const IconWrapper = styled.div`
  ${centerFlex}
  flex-shrink: 0;
  background: ${({ theme }) => theme.color.general.layout.two};
  border-radius: ${({ theme }) => theme.borderRadius.lg}px;
  width: 80px;
  height: 80px;
  margin-right: ${({ theme }) => theme.spacing.lg}px;

  svg {
    color: ${({ theme }) => theme.color.success.typography.primary};
  }

  ${({ theme }) => useBreakpoint(theme).mobile`
      margin-right: ${theme.spacing.sm}px;
      width: 64px;
      height: 64px;
  `}
`;
