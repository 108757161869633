import React from 'react';

import { getAdditionalProps } from '../typography.utils';

import * as S from './Text.styles';
import { Price } from './Price';
import { Quantity } from './Quantity';
import { type Props } from './Text.types';

export const Text = ({
  children,
  size = 'default',
  weight = 'default',
  as,
  marginBottom,
  color,
  colorVariant,
  onClick,
  href,
  target,
  buttonType,
  className,
  style,
  blur,
  valueLength,
  lineClamp,
  textWrap,
  shrinkLongText = false,
  align,
  hyperlinkColor,
  ...rest
}: Props) => {
  return (
    <S.Text
      as={as}
      className={className}
      style={style}
      $size={size}
      $weight={weight}
      $marginBottom={marginBottom}
      $color={color || 'general'}
      $blur={blur}
      $valueLength={shrinkLongText ? valueLength : undefined}
      $colorVariant={
        colorVariant || (size === 'default' ? 'primary' : 'secondary')
      }
      $textWrap={textWrap}
      $textAlign={align}
      $lineClamp={lineClamp}
      $hyperlinkColor={hyperlinkColor}
      {...getAdditionalProps({ onClick, href, target, buttonType })}
      {...rest}
    >
      {children}
    </S.Text>
  );
};

Text.Price = Price;
Text.Quantity = Quantity;
