import { type ComponentProps, useCallback, useMemo, useState } from 'react';

import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { type ReadOnly } from '@npm/core/ui/components/molecules/ReadOnly';
import { PAGE_SIZE_HARD_LIMIT } from '@npm/core/ui/components/molecules/Table';
import { handleValidationError } from '@npm/core/ui/utils/form';
import { Codebooks } from '@npm/data-access';
import {
  type Account,
  type AccountsBuyerSurveyCreateRequestContract,
  type BuyerSurvey,
  type BuyerSurveyCodebookItem,
  type BuyerSurveyListResponseType,
  type BuyerSurveyQuestionCodebookItem,
  unpackCodebooks,
  useBuyerSurveyCreate,
  useBuyerSurveyList,
  useCodebook,
  useSectorIndex,
} from '@npm/data-access';
import { type FormInstance } from 'antd';

import { prepareFormConfig } from './BuyerSurvey.utils';

export const useBuyerSurveyForm = (
  form: FormInstance | null,
  {
    accountId,
    onSubmitSuccess,
    onLoadSuccess,
  }: {
    accountId: Account['id'];
    onSubmitSuccess?: () => void;
    onLoadSuccess?: (data: BuyerSurvey) => void;
  }
) => {
  const [formHasErrored, setFormHasErrored] = useState(false);
  const { withShowApiErrorMiddleware } = useAlerts();

  const { execute: createBuyerSurvey, isLoading: isCreating } =
    useBuyerSurveyCreate();

  const { data: buyerSurverData } = useCodebook({
    type: Codebooks.BUYER_SURVEY,
  });

  const { data: buyerSurverQuestionData } = useCodebook({
    type: Codebooks.BUYER_SURVEY_QUESTION,
  });

  const buyerSurverCodebooks = buyerSurverData?.codebooks ?? [];
  const buyerSurverQuestionCodebooks = buyerSurverQuestionData?.codebooks ?? [];

  const { data: sectorsData, isLoading: sectorsLoading } = useSectorIndex({
    page: 1,
    size: PAGE_SIZE_HARD_LIMIT,
  });

  const {
    data: surveyData,
    isLoading: surveyLoading,
    refetch,
  } = useBuyerSurveyList(
    {
      accountId,
    },
    { onComplete: onLoadSuccess }
  );

  const data = useMemo<BuyerSurveyListResponseType | undefined>(() => {
    if (!surveyData || !buyerSurverQuestionCodebooks.length) {
      return undefined;
    }
    return unpackCodebooks<BuyerSurveyListResponseType>(
      surveyData,
      buyerSurverQuestionCodebooks
        .map(c => c.code as keyof BuyerSurveyListResponseType)
        .filter(i => i !== 'sectors')
    );
  }, [surveyData, buyerSurverQuestionCodebooks]);

  const getFormConfig = useCallback(
    () =>
      prepareFormConfig(
        buyerSurverQuestionCodebooks as BuyerSurveyQuestionCodebookItem[],
        buyerSurverCodebooks as BuyerSurveyCodebookItem[],
        sectorsData?.sectors,
        sectorsLoading
      ),
    [
      buyerSurverQuestionCodebooks,
      buyerSurverCodebooks,
      sectorsData?.sectors,
      sectorsLoading,
    ]
  );

  const getReadOnlyData = useCallback((): Pick<
    ComponentProps<typeof ReadOnly>,
    'items'
  >['items'] => {
    if (!surveyData || !buyerSurverQuestionCodebooks.length) {
      return [];
    }

    const questions =
      buyerSurverQuestionCodebooks as BuyerSurveyQuestionCodebookItem[];

    return questions.reduce((result, { code, name }) => {
      let value: string;

      if (Array.isArray(surveyData[code])) {
        if (code === 'sectors') {
          value = surveyData[code].join(', ');
          // array of codebook objects
        } else {
          value = surveyData[code].map(cb => cb?.name).join(', ');
        }
        // codebook object
      } else if (surveyData[code] && typeof surveyData[code] === 'object') {
        value = surveyData[code].name;
      }

      if (value) {
        result.push({
          label: name,
          value,
        });
      }

      return result;
    }, []);
  }, [buyerSurverQuestionCodebooks, surveyData]);

  const onSubmit = useCallback(async () => {
    let values: AccountsBuyerSurveyCreateRequestContract;
    try {
      values = await form.validateFields();
    } catch (e) {
      setFormHasErrored(true);
      return handleValidationError(form, e);
    }

    try {
      await withShowApiErrorMiddleware(createBuyerSurvey)({
        accountId,
        accountsBuyerSurveyCreateRequestContract: values,
      });
      onSubmitSuccess?.();
      refetch();
    } catch (error) {
      console.error(error);
    }
  }, [accountId, createBuyerSurvey, form, onSubmitSuccess]);

  return {
    onSubmit,
    isSubmitting: isCreating,
    getFormConfig,
    getReadOnlyData,
    data,
    isLoading: surveyLoading,
    refetch,
    formHasErrored,
  };
};
