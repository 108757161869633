import React, { type ComponentProps } from 'react';

import { NoDataArea } from '@npm/core/ui/components/atoms/NoDataArea';
import { Card } from '@npm/core/ui/components/molecules/Card';
import { type Modal } from '@npm/core/ui/components/molecules/Modal';
import { type Table } from '@npm/core/ui/components/molecules/Table';
import { CypressDataIds } from '@npm/core/ui/constants';
import {
  type CodebookItem,
  type DocumentSimple,
  type EventAggregate,
  type Holding,
  type SubmissionItemsInner,
} from '@npm/data-access';
import { type ColorGroup } from 'styled-components';

import { type StatusChangeButtons } from '../../components';

import * as S from './HoldingsTable.styles';
import { useHoldingColumns } from './HoldingsTable.columns';

type Props = {
  holdings?: Holding[];
  isLoading?: boolean;
  totalRecords?: number;
  title?: string;
  selectedHoldingId?: number;
  assetType?: CodebookItem;
  isFilterApplied?: boolean;
  config?: {
    showAccount?: boolean;
    accountWithoutLink?: boolean;
    showIssuance?: boolean;
    showVerifiedOwnership?: boolean;
    showProofOfOwnership?: boolean;
    showAssetType?: boolean;
    showPreVerified?: boolean;
    showStatus?: boolean;
    showRemainingQuantity?: boolean;
    showTimestamps?: boolean;
    event?: EventAggregate;
    orders?: SubmissionItemsInner[];
    onEditClick?: (id: number) => void;
    onDeleteClick?: (holding: Holding) => void;
    onStatusChangeButtonClick?: ComponentProps<
      typeof StatusChangeButtons
    >['onClick'];
    onOfferClick?: (holding: Holding) => void;
    onEnterIOIClick?: (holding: Holding) => void;
    getHoldingStateTooltip?: (holding: Holding) => string | null;
    preferProofOverRegisteredName?: boolean;
  };
  onDocumentClick?: (doc: DocumentSimple) => void;
  openModal?: (props?: ComponentProps<typeof Modal>) => void;
  noDataText?: string;
  noDataIconColor?: ColorGroup;
} & Pick<
  ComponentProps<typeof Table>,
  'pagination' | 'onChange' | 'extraRowContent' | 'outlined'
>;

export const HoldingsTable = ({
  holdings = [],
  selectedHoldingId,
  isLoading,
  pagination,
  onChange,
  totalRecords,
  assetType,
  isFilterApplied,
  title = assetType?.name,
  onDocumentClick,
  openModal,
  config,
  noDataText,
  noDataIconColor,
  outlined,
  ...restProps
}: Props) => {
  const columns = useHoldingColumns({
    onDocumentClick,
    openModal,
    config: {
      showAssetType: !assetType,
      ...config,
    },
  });

  return (
    <Card
      key={assetType?.code}
      noContentPadding
      outlined={outlined}
      {...(!!title && {
        header: {
          title: totalRecords == null ? title : `${title} (${totalRecords})`,
        },
      })}
    >
      <S.Table
        outlined={false}
        columns={columns}
        selectedRow={record => record.id === selectedHoldingId}
        loading={isLoading}
        onChange={onChange}
        pagination={pagination}
        expandable={{
          showExpandColumn: true,
        }}
        dataSource={holdings}
        noDataArea={
          <NoDataArea
            title={
              isFilterApplied
                ? "We couldn't find any results with your search criteria, please, try with different attributes."
                : noDataText || 'No Holdings found.'
            }
            iconColor={noDataIconColor}
          />
        }
        data-cy={CypressDataIds.Holdings.Table.Name}
        {...restProps}
      />
    </Card>
  );
};
