import { ButtonCard } from '@npm/core/ui/components/atoms/ButtonCard';
import { InputFormItem } from '@npm/core/ui/components/atoms/FormItem';
import { Select } from '@npm/core/ui/components/atoms/Select';
import { useBreakpoint } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

export const StyledFormItem = styled(InputFormItem)<{
  $showRadioGroup: boolean;
  $showItemsInRow: boolean;
}>`
  max-width: 400px;
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;

  ${({ theme }) => useBreakpoint(theme).mobile`
    margin-bottom: ${theme.spacing.md}px;
  `}

  .ant-form-item-control-input-content {
    display: flex;
  }

  .ant-radio {
    top: 0;
  }

  ${({ theme, $showRadioGroup, $showItemsInRow }) =>
    `
    && label {
       margin-bottom: ${
         $showRadioGroup ? theme.spacing.md : theme.spacing.xs
       }px;
     }

    .ant-radio-group {
      display: ${$showItemsInRow ? 'flex' : 'inline-block'};

      label {
        margin-right: ${$showItemsInRow ? theme.spacing.md : 0}px;
      }
    }
  `}
`;

export const StyledButtonCard = styled(ButtonCard)`
  flex: 1 0 50%;
`;

export const StyledSelect = styled(Select)`
  max-width: 100%;
`;
