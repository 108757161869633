import { type Wizard } from '@npm/core/ui/components/organisms/Wizard';
import { CbOnboardingStatus, type OnboardingStatus } from '@npm/data-access';

import { type PostOnboardingContextType } from '../PostOnboarding.types';

export const getParentItemProps = (
  onboardingStatus: OnboardingStatus,
  step: Wizard.StepDefinition<PostOnboardingContextType>
) => {
  const substeps = step.substeps || [];
  const substepCodes = substeps.map(s => onboardingStatus[s.key]?.code);
  const hasSubsteps = substeps.length > 0;

  const totalSubsteps = hasSubsteps ? substepCodes.length : 0;
  const totalSubstepsCompleted = hasSubsteps
    ? substepCodes.filter(code => code === CbOnboardingStatus.items.completed)
        .length
    : 0;

  let state: keyof (typeof CbOnboardingStatus)['items'] =
    onboardingStatus[step.key]?.code || CbOnboardingStatus.items.incomplete;

  if (hasSubsteps) {
    if (substepCodes.some(code => code === CbOnboardingStatus.items.warning)) {
      state = CbOnboardingStatus.items.warning;
    } else if (
      substepCodes.every(code => code === CbOnboardingStatus.items.completed)
    ) {
      state = CbOnboardingStatus.items.completed;
    }
  }

  return { state, totalSubsteps, totalSubstepsCompleted };
};
