import { Card as BaseCard } from '@npm/core/ui/components/molecules/Card';
import { CbBackgroundCheckState } from '@npm/data-access';
import styled from 'styled-components';

export const PersonShieldIconWrapper = styled.div<{
  $variant:
    | typeof CbBackgroundCheckState.items.failed
    | typeof CbBackgroundCheckState.items.marked_for_review;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme, $variant }) =>
    $variant === CbBackgroundCheckState.items.failed
      ? theme.color.error.typography.primary
      : theme.color.warning.typography.primary};
`;

export const Card = styled(BaseCard)`
  min-height: 472px;
  max-width: 100%;
  .card-content-container {
    align-items: center;
    .card-content {
      flex-direction: row;
      max-width: 462px;
      justify-content: center;
    }
  }
`;
